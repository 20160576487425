import { Button, Box, Modal, Stack, styled, Link } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import CloseIcon from "@mui/icons-material/Close"
import { Supplier } from "../../../graphqGenaretedTypes"
import { createSearchParams, useNavigate, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
export type CardPopUpProps = {
  open: boolean
  setOpen: (state: boolean) => void
  supplier?: Supplier
}

const FullListPopUp = ({ open, supplier, setOpen }: CardPopUpProps) => {
  const handleClose = () => {
    setOpen(false)
  }
  const { t, ready } = useTranslation("fullListBikeShop")
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const params = useParams()
  const navigate = useNavigate()
  const quotePageLink = `/organisations/${params.organisationId}/employee/benefits/cycle_to_work/enter_bike_quote`
  const bikeshopQuotePageLink = `/organisations/${params.organisationId}/employee/benefits/bike_shop/enter_bike_quote`
  const handleClick = (supplier: string | undefined | null) => {
    if (supplier) {
      navigate({
        pathname:
          params.schemeType === "cycle_to_work"
            ? quotePageLink
            : bikeshopQuotePageLink,
        search: `${createSearchParams({ supplier })}`,
      })
    }
  }

  if (!ready) {
    return null
  }
  return (
    <Modal
      open={open}
      sx={{ borderRadius: "0.9375rem" }}
      onClose={() => setOpen(false)}
      role="dialog"
      aria-labelledby="dialog_label"
      aria-modal="true"
    >
      <PopupBox
        sx={{
          width: isDesktop ? "53rem" : "80%",
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        <Box>
          <h3 id="dialog_label">
            <span className="screen-reader-only">
              {t("fullListBikeShop.opened")}
            </span>
            {supplier?.name}
            <span className="screen-reader-only">
              {" "}
              {t("fullListBikeShop.popUp")}
            </span>
          </h3>
          <DescriptionBox>
            <p style={{ marginBottom: "5px" }}>
              {supplier?.formattedAddressString}
            </p>
            <p style={{ marginTop: "5px", marginBottom: "10px" }}>
              {supplier?.tel}
            </p>
          </DescriptionBox>
          <WebsiteLink>
            <Link
              href={supplier?.supplierWebsite}
              component={"a"}
              style={{ color: "#872074", textDecoration: "none" }}
              rel="noopener"
            >
              {supplier?.supplierWebsite}
            </Link>
          </WebsiteLink>

          <Stack
            direction={isDesktop ? "row-reverse" : "column-reverse"}
            justifyContent="center"
            alignItems="center"
            spacing={3}
            sx={{
              marginTop: "2.75rem",
            }}
          >
            <Button
              onClick={() => {
                handleClick(supplier?.id)
              }}
              sx={{ width: isDesktop ? "17.5rem" : "100%" }}
              variant="gradient"
            >
              {t("fullListBikeShop.orderWithQuote")}
              <span className="screen-reader-only">
                ({t("fullListBikeShop.openInNewTab")})
              </span>
            </Button>
            <Button
              variant="gray"
              sx={{ width: isDesktop ? "17.5rem" : "100%" }}
              onClick={handleClose}
            >
              {t("fullListBikeShop.findShop")}
            </Button>
          </Stack>
        </Box>
        <Button
          onClick={() => setOpen(false)}
          sx={{ alignSelf: "end" }}
          title={t("fullListBikeShop.closePopup")}
        >
          <CloseIcon />
          <div className="screen-reader-only">Close</div>
        </Button>
      </PopupBox>
    </Modal>
  )
}
export default FullListPopUp

const PopupBox = styled(Box)`
  position: absolute;
  outline: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: white;
  z-index: 9999;
  overflow: auto;
  border-radius: 16px;
  padding: 1.25rem;
  text-align: center;
  display: flex;
  flex-direction: column;
`

const DescriptionBox = styled(Box)`
  max-width: 30rem;
  font-size: medium;
  margin: auto;
  justify-content: center;
  color: ${({ theme }) => theme.palette.primary.main};
`
const WebsiteLink = styled(Box)`
  font-size: 16px;
  font-weight: bold;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.md}px) {
    font-size: 24px;
    font-weight: bold;
  }
`
