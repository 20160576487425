import { useSearchParams } from "react-router-dom"
import updatedSearchParams from "../../utils/updatedSearchParams"
import { useEffect, useState } from "react"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { AnimatePresence, motion } from "framer-motion"
import classNames from "classnames"
import { css, styled, useMediaQuery, useTheme } from "@mui/material"
import { Typography, ListItem, List, Button } from "@mui/material"
import ClickAwayListener from "@mui/material/ClickAwayListener"

export type dropdownProps = {
  label?: string
  paramName: string
  selected: string
  dropdownWidth?: string
  dropdownOptions: { title: string; value: string; selected?: boolean }[]
}

function Dropdown({
  label,
  paramName,
  selected,
  dropdownWidth = "7.9rem",
  dropdownOptions,
}: dropdownProps) {
  const [expanded, setExpanded] = useState(false)
  const [selectedOption, setSelectedOption] = useState(selected)
  const selectedTitle = dropdownOptions
    .filter((item) => item?.value == selectedOption)
    .map((op) => op.title)[0]
  const [searchParams, setSearchParams] = useSearchParams()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))

  const applyDropdownFilters = (value: string) => {
    setExpanded(!expanded)
    setSelectedOption(value)
    setSearchParams(
      updatedSearchParams(searchParams, [
        { paramName: paramName, value: value },
      ])
    )
  }

  const handleClick =
    (value: string) => (event: React.KeyboardEvent | React.MouseEvent) => {
      event.preventDefault()
      applyDropdownFilters(value)
    }

  const onKeyUp = (value: string) => (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault()
      applyDropdownFilters(value)
    }
  }

  const listContents = (
    <List className="filterDropdown-ul">
      {dropdownOptions
        .filter((item) => item?.value != selectedOption)
        .map((item, index) => {
          {
            return (
              <ListItem
                key={index}
                onClick={handleClick(item.value)}
                onKeyUp={onKeyUp(item.value)}
                className="filterDropdown_links"
                aria-label={item.title}
                tabIndex={0}
              >
                {item.title}
              </ListItem>
            )
          }
        })}
    </List>
  )

  useEffect(() => {
    setSelectedOption(selected)
  }, [selected])

  return (
    <div
      className={`filterDropdownContainer filterDropdownContainer${paramName}`}
    >
      <div className="filterDropdownBox">
        <DropDownLabel variant="body1">{label}</DropDownLabel>
        <ClickAwayListener onClickAway={() => setExpanded(false)}>
          <div className="filterDropdown" style={{ width: dropdownWidth }}>
            <DropDownButton onClick={() => setExpanded(!expanded)}>
              <div
                className="filterDropdown-box__title"
                onClick={() => setExpanded(!expanded)}
              >
                <DropDownTitle variant="body1">{selectedTitle}</DropDownTitle>
                <ArrowDropDownIcon
                  className={classNames("filterDropdown-box__arrow", {
                    "filterDropdown-box__arrow--expanded": expanded,
                  })}
                  style={{
                    height: isDesktop ? "0.79em" : "1em",
                    width: isDesktop ? "0.79em" : "1em",
                    transitionDuration: "0.3s",
                    transitionProperty: "transform",
                  }}
                />
              </div>
            </DropDownButton>
            <AnimatePresence>
              {expanded && (
                <motion.div
                  className="filterDropdown-box__content"
                  initial={{ height: 0 }}
                  animate={{ height: "auto" }}
                  exit={{ height: 0 }}
                  transition={{
                    type: "spring",
                    bounce: 0,
                    duration: 0.3,
                  }}
                >
                  {listContents}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </ClickAwayListener>
      </div>
    </div>
  )
}

export default Dropdown

const DropDownButton = styled(Button)`
  ${({ theme }) => css`
    width: 100%;
    padding: 0 0.75rem;
    text-transform: capitalize;
    min-width: 3.0625rem;
    color: ${theme.palette.primary.main};
    height: 2.464rem;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      height: 1.5rem;
    }
  `}
`
const DropDownLabel = styled(Typography)`
  font-size: 0.75rem;
  margin-right: 0.625rem;
  margin-top: 0.125rem;
`

const DropDownTitle = styled(Typography)`
  font-size: 0.75rem;
  margin: 0;
`
