import { useMediaQuery, useTheme } from "@mui/material"
import { PortalMegaMenuItem } from "../../layout/formatMegaMenuData"
import MegaMenuItem from "./Item"
import { useState } from "react"
import {
  HealthAndWellbeing as HealthAndWellbeingType,
  FamilyCare as FamilyCareType,
} from "../../../graphqGenaretedTypes"
export type MegaMenuProps = {
  items: PortalMegaMenuItem[]
  healthAndWellbeing: HealthAndWellbeingType
  familyCareItem: FamilyCareType
}

function MegaMenu({
  items,
  healthAndWellbeing,
  familyCareItem,
}: MegaMenuProps) {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const [activeItem, setActiveItem] = useState(-1)

  const setActive = (state: boolean, index: number) => {
    if (state === true) {
      setActiveItem(index)
    } else {
      setActiveItem(-1)
    }
  }

  return (
    <>
      {isDesktop && (
        <div className="mega-menu">
          {items.map((item: PortalMegaMenuItem, index) => {
            return (
              <MegaMenuItem
                active={activeItem === index}
                setActive={(state: boolean) => setActive(state, index)}
                key={item.label}
                link={item.link}
                storeLink={item.storeLink || item.link}
                name={item.label}
                categories={item.departments}
                imageUrl={item.image}
                extraOptions={item.extraOptions}
                external={item.external}
                healthAndWellbeing={healthAndWellbeing}
                familyCareItem={familyCareItem}
              />
            )
          })}
        </div>
      )}
    </>
  )
}

export default MegaMenu
