import { Link } from "../../../../graphqGenaretedTypes"
import { MobileWhiteBox, OneProposition } from "../../shared/SharedStyles"
import useIsMobileView from "../../../../utils/useIsMobileView"
import ExpandableList from "../ExpandableList"
import Theme from "../../../../theme/mui-theme"
import { Header } from "./Header"
import BlueBox from "./BlueBox"

const LifestyleSavings = ({ categories }: { categories: Link[] }) => {
  const isMobile = useIsMobileView()

  if (isMobile) {
    return (
      <MobileWhiteBox>
        <Header />
        <BlueBox />
        <ExpandableList
          links={categories}
          moreButtonColor={Theme.palette.secondary.main}
        />
      </MobileWhiteBox>
    )
  }
  return (
    <div>
      <OneProposition>
        <Header />
        <BlueBox />
      </OneProposition>
      {!isMobile && (
        <ExpandableList
          links={categories}
          moreButtonColor={Theme.palette.secondary.main}
        />
      )}
    </div>
  )
}

export default LifestyleSavings
