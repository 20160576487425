import { styled, Box, Grid, Typography, css } from "@mui/material"
import { ExploreButton } from "../../../lifestyle-savings/shared-styles"
import { useParams } from "react-router-dom"
import Helmet from "../cycle-to-work-hardcoded-component/assets/Helmet.png"
import { WaveSvgCtw } from "../../../shared/wave-svg/WaveSvgCtw"
import { gtmCycleToWorkBenefitWorksNavigationClick } from "../../../shared/gtm-events/CustomGtmEvents"
import { useTranslation } from "react-i18next"

const Eligibility = ({ bikeDepartmentId }: { bikeDepartmentId?: number }) => {
  const params = useParams()
  const event_action = "eligiblity"
  const { t, ready } = useTranslation("cycleToWork")

  if (!ready) {
    return null
  }

  return (
    <MainBox>
      <WaveBackgroundContainer>
        <WaveSvgCtw />
        <ContentContainer>
          <MainGrid container>
            <ContentGrid item xs={12} md={5}>
              <TextContainer>
                <TextTitle>
                  {t("landing.eligibilitySection.mainHeader")}
                </TextTitle>
                <ParagraphText>
                  {t("landing.eligibilitySection.mainPara")}
                </ParagraphText>

                <ButtonContainer>
                  <Button
                    href={`/organisations/${params.organisationId}/employee/benefits/${params.schemeType}/departments/${bikeDepartmentId}`}
                    onClick={() => {
                      gtmCycleToWorkBenefitWorksNavigationClick(event_action)
                    }}
                  >
                    {t("landing.eligibilitySection.button")}
                  </Button>
                </ButtonContainer>
              </TextContainer>
            </ContentGrid>
            <ImageGrid item xs={12} md={7}>
              <ImageContainer
                src={Helmet}
                alt={t("landing.eligibilitySection.cycle")}
              />
            </ImageGrid>
          </MainGrid>
        </ContentContainer>
      </WaveBackgroundContainer>
    </MainBox>
  )
}
export default Eligibility
export const MainBox = styled(Box)`
  ${({ theme }) => css`
    width: 100%;
    margin-top: 3rem;
    color: ${theme.palette.white.main};
    height: 466px;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      height: 576px;
      margin-top: 0rem;
    }
  `}
`
export const WaveBackgroundContainer = styled(Box)`
  ${({ theme }) => css`
    width: 100%;
    position: relative;
    margin-top: -16rem;
    padding-top: 9rem;
    z-index: 5;
    display: flex;
    justify-content: center;
    justify-items: center;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      margin-top: -6.4rem;
      padding-top: 1rem;
    }
  `}
`
export const ContentContainer = styled(Box)`
  ${({ theme }) => css`
    max-width: 1043px;
    width: 100%;
    height: 342px;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      height: 576px;
    }
  `}
`
export const ImageGrid = styled(Grid)`
  ${({ theme }) => css`
    order: 2;
    display: flex;
    justify-content: end;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      text-align: center;
      order: 1;
      justify-content: center;
      margin-top: 46.21px;
    }
  `}
`

export const MainGrid = styled(Grid)`
  ${({ theme }) => css`
    max-width: 1043px;
    height: 342px;
    width: 100%;
    padding: 0.4rem;

    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      padding: 0.3rem;
    }
  `}
`
export const ImageContainer = styled("img")`
  ${({ theme }) => css`
    max-width: 570px;
    width: 100%;
    height: 342px;
    color: white;
    border-radius: 16px;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      max-width: 326px;
      width: 100%;
      height: 196px;
    }
  `}
`
export const TextContainer = styled(Grid)`
  ${({ theme }) => css`
    max-width: 413px;
    width: 100%;
    height: 342px;
    color: white;
    display: flex;
    justify-content: center;
    opacity: 1;
    flex-direction: column;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      display: flex;
      justify-content: center;
      padding: 0.6rem;
      height: 241px;
    }
  `}
`
export const TextTitle = styled(Typography)`
  ${({ theme }) => css`
    font-size: 24px;
    color: ${theme.palette.primary.main};
    font-weight: bold;
    margin-bottom: 15px;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      padding-top: 32px;
    }
  `}
`
export const ParagraphText = styled(Typography)`
  ${({ theme }) => css`
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: ${theme.palette.primary.main};

    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      font-size: 14px;
    }
  `}
`
export const Button = styled(ExploreButton)`
  ${({ theme }) => css`
    && {
      display: flex;
      background: transparent
        linear-gradient(90deg, var(--tertiary) 0%, var(--primary) 100%) 0% 0%
        no-repeat padding-box;
      background: transparent
        linear-gradient(90deg, #92237f 0%, ${theme.palette.primary.main} 100%)
        0% 0% no-repeat padding-box;
      color: ${theme.palette.white.main};
      margin: 0rem;
      max-width: 246px;
      width: 100%;
      height: 50px;
      @media screen and (max-width: ${theme.breakpoints.values.md}px) {
        max-width: 220px;
        width: 100%;
        text-align: center;
      }
      &:hover {
        opacity: 0.75;
      }
    }
  `}
`
export const ButtonContainer = styled("div")`
  ${({ theme }) => css`
    margin-top: 40px;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      display: flex;
      justify-content: center;
      margin-top: 36px;
      margin-bottom: 15px;
    }
  `}
`
export const ContentGrid = styled(Grid)`
  ${({ theme }) => css`
    order: 1;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      display: flex;
      align-items: center;
      justify-content: center;
      justify-items: center;
      text-align: center;
      order: 2;
      margin-top: 32px;
    }
  `}
`
