import ScrollToTop from "../shared/ScrollToTop"
import BannerCarouselGrid from "../shared/banner-carousel/BannerCarouselGrid"
import { useStoreHomeData, StoreHomeContext } from "./StoreHomeContext"
import RoundelGrid from "../shared/RoundelGrid"
import Loader from "../shared/Loader"
import { useContext, useEffect } from "react"
import { StoreLayoutContext } from "../layout/store/StoreLayoutContext"
import BottomBanner from "./BottomBanner"
import CycleToWorkButtonGrid from "./cycle-to-work/CycleToWorkButtonGrid"
import { useCycleToWorkButtonsData } from "./cycle-to-work/useCycleToWorkButtonsData"
import { useParams } from "react-router-dom"
import ButtonSection from "./cycle-to-work/cycle-to-work-hardcoded-component/ButtonSection"
import Eligibility from "./cycle-to-work/cycle-to-work-hardcoded-component/Eligibility"
import FourSimpleSteps from "./cycle-to-work/cycle-to-work-hardcoded-component/FourSimpleSteps"
import HowBenefitWorks from "./cycle-to-work/cycle-to-work-hardcoded-component/HowBenefitWorks"
import HomePageRoundels from "../cycle-to-work/HomePageRoundels"
import PopularBikes from "../cycle-to-work/PopularBikes"
import { Box, styled, css } from "@mui/material"
import BikeShopHardCodeComponent from "./cycle-to-work/BikeShopHardCodeComponent"
import {
  gtmOnPageland,
  gtmPlacedStore,
} from "../shared/gtm-events/CustomGtmEvents"
import { useTranslation } from "react-i18next"
import { checkProductImpressions } from "../shared/gtm-events/CustomGtmEvents"
import DecathlonSection from "./cycle-to-work/cycle-to-work-hardcoded-component/DecathlonSection"

export const StoreHome = function () {
  const { data } = useStoreHomeData()
  const { supplierDesktopBanner, supplierMobileBanner } =
    useContext(StoreLayoutContext)

  const params = useParams()
  const isCycleToWork = params.schemeType === "cycle_to_work"
  const isBikeShop = params.schemeType === "bike_shop"
  const store = params.schemeType
  const { t, ready } = useTranslation("homeAndElectronics")

  useEffect(() => {
    if (isCycleToWork) {
      window.addEventListener("scroll", scrollHandler)
      return () => window.removeEventListener("scroll", scrollHandler)
    }
  })
  useEffect(() => {
    if (data.loading) {
      gtmPlacedStore(store)
      gtmOnPageland(store)
    }
  }, [data.loading, store])

  const scrollHandler = () => {
    checkProductImpressions()
  }
  const schemeDepartmentValue = useCycleToWorkButtonsData(
    params.schemeType,
    data.bikeDepartmentId
  )
  return (
    <StoreHomeContext.Provider value={data}>
      {data.loading || !ready ? (
        <Loader />
      ) : (
        <>
          <div
            className={
              isCycleToWork || isBikeShop ? "" : "global-main-container--gray"
            }
          >
            <BannerCarouselGrid banners={data.banners} />
            {!isCycleToWork && !isBikeShop && (
              <>
                <RoundelGrid
                  gtmActionName="navigation_featured_categoreis"
                  roundels={data.featuredCategories}
                  background="gray"
                  title={t("landing.trending")}
                />
                <RoundelGrid
                  gtmActionName="navigation_departments"
                  roundels={data.allDepartments}
                  background="white"
                  title={t("landing.allDepartments")}
                />
              </>
            )}

            {isCycleToWork ? (
              <>
                <CycleToWorkButtonGrid
                  data={schemeDepartmentValue}
                  isCycleToWork={isCycleToWork}
                />
                <HomePageRoundels
                  roundels={data.featuredCategories}
                  isCycleToWork={isCycleToWork}
                />
                <PopularBikesContainer>
                  <PopularBikes
                    popularBikes={data.popularBikes}
                    isCycleToWork={isCycleToWork}
                  />
                </PopularBikesContainer>
                <FourSimpleSteps />
                <HowBenefitWorks bikeDepartmentId={data.bikeDepartmentId} />
                <Eligibility bikeDepartmentId={data.bikeDepartmentId} />
                <DecathlonSection bikeDepartmentId={data.bikeDepartmentId} />
                <ButtonSection store={store}>
                  <CycleToWorkButtonGrid
                    data={schemeDepartmentValue}
                    store={store}
                  />
                </ButtonSection>
              </>
            ) : (
              ""
            )}
            {isBikeShop ? (
              <>
                <CycleToWorkButtonGrid data={schemeDepartmentValue} />
                <BikeShopHardCodeComponent
                  bikeDepartmentId={data.bikeDepartmentId}
                />
                <PopularBikesContainer>
                  <PopularBikes
                    popularBikes={data.popularBikes}
                    isBikeShop={isBikeShop}
                  />
                </PopularBikesContainer>
                <FourSimpleSteps />
                <HowBenefitWorks
                  bikeDepartmentId={data.bikeDepartmentId}
                  isBikeShop={isBikeShop}
                />
                <ButtonSection>
                  <CycleToWorkButtonGrid data={schemeDepartmentValue} />
                </ButtonSection>
              </>
            ) : (
              ""
            )}
            <ScrollToTop showBelow={500} />
          </div>
          {(supplierMobileBanner || supplierDesktopBanner) && (
            <BottomBanner
              mobileBannerUrl={supplierMobileBanner}
              desktopBannerUrl={supplierDesktopBanner}
            />
          )}
        </>
      )}
    </StoreHomeContext.Provider>
  )
}

const PopularBikesContainer = styled(Box)(
  ({ theme }) => css`
    padding-top: 1.3125rem;
    padding-bottom: 1.3125rem;
    background-color: ${theme.palette.grey[200]};
  `
)

// Comment out in case needed in the future
// const WaveBackgroundContainer = styled(Box)`
//   ${({ theme }) => css`
//     width: 100%;
//     position: relative;
//     z-index: 5;
//     margin-top: -9.5rem;

//     padding-top: 9rem;
//     padding-bottom: 1.3125rem;
//     @media screen and (max-width: ${theme.breakpoints.values.md}px) {
//       margin-top: -3.5rem;
//       padding-top: 3rem;
//     }
//   `}
// `
