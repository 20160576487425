import { createContext } from "react"
import { useParams } from "react-router"
import { RoundelProps } from "../shared/Roundel"
import {
  useGetPortalHomepageDataQuery,
  Noticeboard as NoticeboardType,
  PortalBanner as BannerType,
  PayrollPayBenefit as PayrollPayBenefitType,
  HealthAndWellbeing as HealthAndWellbeingType,
  Link as LinkType,
  Portal,
  FamilyCare as familyCareType,
} from "../../graphqGenaretedTypes"
import { useTranslation } from "react-i18next"

export type PortalHomepageContextData = {
  noticeboards: NoticeboardType[]
  banner: BannerType
  c2wRoundels: RoundelProps[]
  heRoundels: RoundelProps[]
  lssCategories: LinkType[]
  areas: string[]
  payrollPayBenefits: PayrollPayBenefitType[]
  healthAndWellbeing: HealthAndWellbeingType
  hi5Url?: string
  loading: boolean
  gymUrl?: string
  hi5SsoUrl?: Portal[]
  familyCare?: familyCareType
}

const defaultBanner = {
  desktopUrl: undefined,
  link: undefined,
  logos: [],
  mobileUrl: undefined,
  text: undefined,
  welcomeText: undefined,
}

const defaultHealthAndWellbeing: HealthAndWellbeingType = {
  level: "",
  usefulLinks: [],
}

const defaultFamilyCare: familyCareType = {
  usefulLinks: [],
}

const defaultValue: PortalHomepageContextData = {
  banner: defaultBanner,
  noticeboards: [],
  c2wRoundels: [],
  heRoundels: [],
  lssCategories: [],
  areas: [],
  payrollPayBenefits: [],
  healthAndWellbeing: defaultHealthAndWellbeing,
  loading: true,
  gymUrl: undefined,
  familyCare: defaultFamilyCare,
}

let state = JSON.parse(JSON.stringify(defaultValue))

export const PortalHomepageContext = createContext(defaultValue)

export const usePortalHomepageData: () => {
  data: PortalHomepageContextData
} = function () {
  const params = useParams()
  const { i18n } = useTranslation()
  const data = useGetPortalHomepageDataQuery({
    variables: {
      // TODO - handle empty params gracefully
      organisationId: params.organisationId ?? "",
      locale: i18n.language,
    },
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  })

  if (!data.loading) {
    const banner =
      data.data?.employeeOrganisation?.portal?.banner || defaultBanner
    const noticeboards =
      data.data?.employeeOrganisation?.portal?.noticeboards || []
    const c2wRoundels =
      data.data?.employeeOrganisation?.portal?.c2wRoundels || []
    const heRoundels = data.data?.employeeOrganisation?.portal?.heRoundels || []
    const lssCategories =
      data.data?.employeeOrganisation?.portal?.discountCategories || []
    const areas = data.data?.employeeOrganisation?.portal?.areas || []
    const payrollPayBenefits =
      data.data?.employeeOrganisation?.portal?.payrollPayBenefits || []
    const healthAndWellbeing =
      data.data?.employeeOrganisation?.healthAndWellbeing ||
      defaultHealthAndWellbeing
    const hi5Url = data.data?.employeeOrganisation?.portal?.hi5Url
    const hi5SsoUrl = data.data?.employeeOrganisation?.portal?.hi5SsoUrl
    const loading = data.loading
    const gymUrl = data.data?.employeeOrganisation?.portal?.gymUrl
    const familyCare = data.data?.employeeOrganisation?.familyCare

    state = {
      ...defaultValue,
      banner,
      noticeboards,
      c2wRoundels,
      heRoundels,
      lssCategories,
      areas,
      payrollPayBenefits,
      healthAndWellbeing,
      hi5Url,
      hi5SsoUrl,
      loading,
      gymUrl,
      familyCare,
    }
  } else {
    state = {
      ...state,
      loading: true,
    }
  }

  return {
    data: state,
  }
}
