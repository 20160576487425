import {
  PortalMenuItem as PortalMenuItemType,
  LsCategory as LsCategoryType,
} from "../../graphqGenaretedTypes"

export type DepartmentType = {
  label: string
  link: string
  storeLink?: string
  categories?:
    | {
        label: string
        link: string
      }[]
    | null
}

export type PortalMegaMenuItem = {
  label: string
  link: string
  storeLink: string
  image?: string | null
  extraOptions?: {
    label: string
    link: string
  }[]
  departments?: DepartmentType[] | null
  external: boolean
}

const createLsItem = (categories: LsCategoryType[], organisationId: string) => {
  return {
    label: "lifestyleSavings",
    link: `/organisations/${organisationId}/employee/lifestyle_savings/landing`,
    storeLink: `/organisations/${organisationId}/employee/lifestyle_savings/landing`,
    departments: categories.reduce((acc: DepartmentType[], category) => {
      return acc.concat([
        {
          label: category.name,
          link: category.link,
          categories: category.subcategories
            .filter((subcategory) => subcategory.name !== "View All")
            .map((subcategory) => ({
              label: subcategory.name,
              link: subcategory.link,
            })),
        },
      ])
    }, []),
    external: false,
  }
}

export default function formatMegaMenuData(
  portalMenuItems: PortalMenuItemType[],
  lsCategories: LsCategoryType[],
  lsActive: boolean,
  organisationId: string
) {
  const formatedItems: PortalMegaMenuItem[] = portalMenuItems.map(
    (item: PortalMenuItemType) => ({
      label: item.label,
      link: item.link,
      storeLink: item.storeLink,
      image: item.image,
      extraOptions: item.extraOptions?.map((option) => ({
        link: option.link,
        label: option.label,
      })),
      external: [
        "carBenefit",
        "vivupHighfive",
        "healthAndWellbeing",
        "exploreYourBenefits",
      ].includes(item.label),
      departments: item.departments?.map((department) => ({
        label: department.name,
        link: department.link,
        storeLink: department.storeLink,
        categories: department.categories.map((category) => ({
          label: category.name,
          link: category.storeLink,
        })),
      })),
    })
  )

  if (lsActive === false) {
    return formatedItems
  }

  const lsItem = createLsItem(lsCategories, organisationId)

  let firstGroupIndex = 0
  if (formatedItems.length > 0) {
    while (
      ["homeAndElectronics", "cycleToWork", "carBenefit"].includes(
        formatedItems[firstGroupIndex].label
      )
    ) {
      firstGroupIndex++
    }
  }
  formatedItems.splice(firstGroupIndex, 0, lsItem)

  return formatedItems
}
