import Footer from "./footer/Footer"
import Header from "./header/Header"
import { GlobalLayoutContext, useGlobalLayoutData } from "./GlobalLayoutContext"
import MegaMenu from "../shared/global-mega-menu/MegaMenu"
import { Outlet } from "react-router"
import BurgerMenu from "../shared/nav/BurgerMenu"
import { StepMenuProps } from "../shared/step-menu/StepMenu"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"

export type globalHeaderData = {
  loading?: boolean
}

export const GlobalLayout = function ({
  setShouldShowCookiePopup,
}: {
  setShouldShowCookiePopup: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const { t, ready } = useTranslation("layout")

  const contextValue = useGlobalLayoutData()
  const stepMenuProps: StepMenuProps = {
    title: t("menu.yourBenefits"),
    itemHierarchy: contextValue.burgerMenuItems,
    levels: 3,
  }

  useEffect(() => {
    const url = location.href
    let subdomain = url.slice(url.indexOf("//") + 2, url.indexOf("."))
    if (["vivup", "www"].includes(subdomain) || url.indexOf(".") === -1) {
      subdomain = "Vivup"
    }

    document.title = `${subdomain}: Portal Homepage`
  }, [])

  if (!ready) {
    return null
  }
  return (
    <GlobalLayoutContext.Provider value={contextValue}>
      <>
        <Header
          logo={contextValue.logo}
          organisationName={contextValue.organisationName}
          condensedLogo={contextValue.condensedLogo}
          currentUserId={contextValue.currentUserId}
          showBasket={false}
          showNoticeboard={contextValue.noticeboards}
          backendUser={contextValue.backendUser}
          reporter={contextValue.reporter}
          isOrganisationSettingUser={contextValue.isOrganisationSettingUser}
          employeeOrganisations={contextValue.employeeOrganisations}
          MegaMenu={
            <MegaMenu
              items={contextValue.megaMenuItems}
              healthAndWellbeing={contextValue.healthAndWellbeing}
              familyCareItem={contextValue.familyCareItem}
            />
          }
          BurgerMenu={<BurgerMenu stepMenuProps={stepMenuProps} />}
          availableLanguages={contextValue.availableLanguages}
          defaultLanguage={contextValue.defaultLanguage}
        />
        <div className="global-main-container" id="main-content">
          <Outlet />
        </div>
        <Footer
          privacyNoticeUrl={contextValue.privacyNoticeUrl}
          organisationPrivacyNotice={contextValue.organisationPrivacyNotice}
          setShouldShowCookiePopup={setShouldShowCookiePopup}
        />
      </>
    </GlobalLayoutContext.Provider>
  )
}
