import { KeyboardEvent } from "react"
import classNames from "classnames"

import MegaMenuTab from "./Tab"
import { promotionalItem } from "../../../utils/specialPromoHelper"
import { DepartmentType } from "../../layout/formatMegaMenuData"
import { Link } from "@mui/material"
import { HealthAndWellbeing as HealthAndWellbeingType } from "../../../graphqGenaretedTypes"
import MegaMenuHealthAndWellbeingTab from "./HealthAndWellbeingTab"
import FamilyCareMegaMenu from "./FamilyCareMegaMenu"
import { FamilyCareItem } from "../../layout/GlobalLayoutContext"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

export type MegaMenuItemProps = {
  active: boolean
  setActive: (state: boolean) => void
  name: string
  featured?: boolean
  link: string
  storeLink: string
  categories?: DepartmentType[] | null
  imageUrl?: string
  extraOptions?: {
    label: string
    link: string
  }[]
  external?: boolean
  healthAndWellbeing?: HealthAndWellbeingType
  familyCareItem: FamilyCareItem
}

function MegaMenuItem({
  active,
  setActive,
  name,
  link,
  storeLink,
  featured = false,
  categories,
  imageUrl,
  extraOptions,
  external = false,
  healthAndWellbeing,
  familyCareItem,
}: MegaMenuItemProps) {
  const params = useParams()
  const { t, ready } = useTranslation("layout")
  const actionKey = (e: KeyboardEvent) => {
    if (e.key == "ArrowDown") {
      e.preventDefault()
      setActive(true)
    } else if (e.key == "ArrowUp") {
      e.preventDefault()
      setActive(false)
    }
  }

  if (!ready) {
    return null
  }

  return (
    <>
      <Link
        component={
          name === "familyCare"
            ? !external
              ? "a"
              : Link
            : external
            ? "a"
            : Link
        }
        href={
          name === "familyCare"
            ? `/organisations/${params.organisationId}/employee/benefits/family_care`
            : link
        }
        target={name === "Vivup highfive" ? "_blank" : undefined}
        className={classNames("mega-menu-item", {
          "mega-menu-item--active": active,
          "mega-menu-item--featured": featured,
          "mega-menu-item--promo": promotionalItem(name),
        })}
        onMouseEnter={() => setActive(true)}
        onMouseLeave={() => setActive(false)}
        onKeyDown={actionKey}
      >
        {t("menu." + name)}
      </Link>
      {categories && !promotionalItem(name) && (
        <MegaMenuTab
          name={name}
          link={storeLink}
          categories={categories}
          imageUrl={imageUrl}
          active={active}
          setActive={setActive}
          extraOptions={extraOptions}
        />
      )}
      {healthAndWellbeing?.usefulLinks && name == "healthAndWellbeing" && (
        <MegaMenuHealthAndWellbeingTab
          categories={healthAndWellbeing.usefulLinks}
          level={healthAndWellbeing.level}
          active={active}
          setActive={setActive}
          link={healthAndWellbeing.link}
          eapNumber={healthAndWellbeing.eapNumber}
        />
      )}
      {familyCareItem?.usefulLinks && name == "familyCare" && (
        <FamilyCareMegaMenu
          categories={familyCareItem.usefulLinks}
          active={active}
          setActive={setActive}
          link={link}
        />
      )}
    </>
  )
}

export default MegaMenuItem
