import { useState } from "react"
import { Link, useMediaQuery, useTheme } from "@mui/material"
import { Button } from "@mui/material"

import StepMenu from "../step-menu/StepMenu"
import DropdownMenu from "../dropdown-menu/DropdownMenu"
import { ItemType as DropdownItemType } from "../dropdown-menu/Item"
import Drawer from "../drawer/Drawer"
import { ItemHierarchyType } from "../step-menu/StepMenu"

export type NavItemProps = {
  name: string
  title: string
  icon: JSX.Element
  link?: string
  listItemHierarchy?: ItemHierarchyType[]
  size: "small" | "medium"
  mobileLabel?: boolean
  rightLabel?: string
  levels?: number
  triggerGtmEvent?: boolean
  changeUiLanguage?: (newLanguage: "en-GB" | "cy") => void
  tooltip?: string
  disabled?: boolean
  external?: boolean
}

export default function NavItem({
  name,
  title,
  icon,
  link,
  listItemHierarchy,
  mobileLabel,
  rightLabel,
  size,
  levels = 1,
  triggerGtmEvent,
  changeUiLanguage,
  tooltip,
  disabled,
  external = false,
}: NavItemProps) {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const [open, setOpen] = useState(false)

  const listItems: DropdownItemType[] =
    listItemHierarchy?.map((item) => ({
      label: item.name || "",
      link: item.link,
      children:
        item.children &&
        item.children.map((child) => ({
          label: child.name,
          link: child.link,
        })),
    })) || []

  return (
    <div className="nav-item">
      {listItemHierarchy ? (
        <>
          {isDesktop ? (
            <DropdownMenu
              label={
                <div
                  className="nav-item__desktop-label"
                  data-text={name + "icons"}
                >
                  {icon}
                  {name}
                </div>
              }
              listItems={listItems}
              size={size}
              triggerGtmEvent={triggerGtmEvent}
              changeUiLanguage={changeUiLanguage}
              tooltip={tooltip}
              disabled={disabled}
            />
          ) : (
            <Drawer
              title={title}
              label={
                <div className="nav-item__mobile-label">
                  {icon}
                  {mobileLabel && name}
                </div>
              }
              open={open}
              setOpen={setOpen}
              disabled={disabled}
              tooltip={tooltip}
              content={
                <StepMenu
                  title={name}
                  itemHierarchy={listItemHierarchy}
                  levels={levels}
                  setOpen={setOpen}
                  changeUiLanguage={changeUiLanguage}
                />
              }
            />
          )}
        </>
      ) : (
        <Button
          id="basic-button"
          href={link}
          title={title}
          LinkComponent={external ? "a" : Link}
        >
          <div
            className={
              isDesktop || rightLabel
                ? "nav-item__desktop-label"
                : "nav-item__mobile-label"
            }
          >
            {icon}
            {(isDesktop || mobileLabel) && name}
            {!isDesktop && rightLabel}
          </div>
        </Button>
      )}
    </div>
  )
}
