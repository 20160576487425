import { Box, styled, Typography, css } from "@mui/material"

export const BannerText = ({ title }: { title: string }) => {
  return (
    <BannerTextContainer>
      <StyledBannerText variant="h2">{title}</StyledBannerText>
    </BannerTextContainer>
  )
}
const BannerTextContainer = styled(Box)`
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      height: 95px;
    }
  `}
`
const StyledBannerText = styled(Typography)`
  font-size: 1.5rem;
  line-height: 1.875rem;
  color: white;
  text-align: center;

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 2rem;
      line-height: 2.375rem;
    }
  `}
`
