import { FamilyCare as FamilyCareType } from "../../../../graphqGenaretedTypes"
import { colourBox } from "../../shared/SharedStyles"
import useIsMobileView from "../../../../utils/useIsMobileView"
import { Box, Typography, css, styled } from "@mui/material"
import ExpandableList from "../ExpandableList"
import { Header } from "./Header"
import familyCareLogo from "./assets/family-care-logo.png"
import { Button } from "./Button"
import childCareLogo from "./assets/child-care.png"
import elderCareLogo from "./assets/eldercare-support.png"
import petCareLogo from "./assets/petcare-support.png"
import i18n from "../../../../i18n"

const getLogosData = () => [
  {
    label: i18n.t("portalHomepage:familyCare.services.childcare"),
    img: childCareLogo,
  },
  {
    label: i18n.t("portalHomepage:familyCare.services.eldercare"),
    img: elderCareLogo,
  },
  {
    label: i18n.t("portalHomepage:familyCare.services.petcare"),
    img: petCareLogo,
  },
]

export function Content({ data }: { data: FamilyCareType | undefined }) {
  const isMobile = useIsMobileView()
  const usefulLinks = data?.usefulLinks

  return (
    <>
      <Header />
      <PaddingOuterGrid>
        <PaddingContentGrid>
          <FamilyCareLogo src={familyCareLogo} alt="family care logo" />
          <FamilyCareLogos>
            {usefulLinks &&
              usefulLinks.map(({ label }) => {
                const logosData = getLogosData()
                const logo = logosData.find((data) => data.label == label)
                if (logo) {
                  return (
                    <SupportType key={`family-care-${label}`}>
                      <Icon src={logo.img} alt={label} />
                      <IconText>{label}</IconText>
                    </SupportType>
                  )
                }
              })}
          </FamilyCareLogos>
          {isMobile && <Button />}
        </PaddingContentGrid>
      </PaddingOuterGrid>
      {isMobile && usefulLinks && (
        <ExpandableList links={usefulLinks} moreButtonColor="#6A6BA8" />
      )}
    </>
  )
}
const PaddingOuterGrid = styled(colourBox)`
  ${({ theme }) => css`
    background: rgba(112, 113, 173, 0.1);
    display: grid;
    place-items: center;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      place-items: unset;
    }
  `}
`
const PaddingContentGrid = styled(Box)`
  ${({ theme }) => css`
    display: grid;
    grid-auto-rows: min-content;
    width: 100%;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      padding-top: 54px; // to align with Heading on the left box
    }
  `}
`
const FamilyCareLogo = styled("img")`
  ${({ theme }) => css`
    display: block;
    margin: 0 auto;
    margin-bottom: 18px;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      margin-bottom: 34px;
    }
  `}
`
const FamilyCareLogos = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 26px;
`
const SupportType = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin: 0 8px;

    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      margin: 0 16px;
    }
  `}
`
const Icon = styled("img")`
  ${({ theme }) => css`
    height: 54.26px;
    align-self: center;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      height: 67.46px;
    }
  `}
`
const IconText = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    font-size: 12px;
    line-height: 12px; // different to figma account for small viewport
    text-align: center;
    margin-top: 8px;
    font-weight: bold;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 14px;
      line-height: 14px;
    }
  `}
`
