import { useState } from "react"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { AnimatePresence, motion } from "framer-motion"
import classNames from "classnames"
import Button from "@mui/material/Button"
import useIsMobileView from "../../utils/useIsMobileView"
export type ExpandableBoxProps = {
  title: string
  content: JSX.Element
  className?: string
  expand?: boolean
  showArrow?: boolean
  expandable?: boolean
}

function ExpandableBox({
  title,
  content,
  className = "filter-box",
  expand,
  showArrow,
  expandable = true,
}: ExpandableBoxProps) {
  const [expanded, setExpanded] = useState(expand)
  const isMobile = useIsMobileView()

  const boxContents = (
    <AnimatePresence>
      {expanded && (
        <motion.div
          className="expandable-box__content"
          initial={{ height: 0 }}
          animate={{ height: "auto" }}
          exit={{ height: 0 }}
          transition={{
            type: "spring",
            bounce: 0,
            duration: 0.3,
          }}
        >
          {content}
        </motion.div>
      )}
    </AnimatePresence>
  )

  return (
    <div className={classNames(className, "expandable-box")}>
      <div
        className={classNames("expandable-box__title", {
          "expandable-box__no_pointer": !(expandable || isMobile),
        })}
        onClick={() => (expandable || isMobile) && setExpanded(!expanded)}
      >
        {expandable || isMobile ? (
          <Button sx={{ padding: 0 }}>
            <h4>{title}</h4>
          </Button>
        ) : (
          <h4>{title}</h4>
        )}
        {showArrow === true ? (
          <ArrowDropDownIcon
            className={classNames("expandable-box__arrow", {
              "expandable-box__arrow--expanded": expanded,
            })}
            sx={{
              height: "1.5rem",
              width: "1.5rem",
              transitionDuration: "0.3s",
              transitionProperty: "transform",
            }}
          />
        ) : (
          <div
            className={classNames("expandable-box__arrow", {
              "expandable-box__arrow--expanded": expanded,
            })}
            style={{
              height: "1.5rem",
              width: "1.5rem",
              transitionDuration: "0.3s",
              transitionProperty: "transform",
            }}
          />
        )}
      </div>
      {boxContents}
    </div>
  )
}

export default ExpandableBox
