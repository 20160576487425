import { Box, styled, Typography } from "@mui/material"

const noteOne = "1 As shown on your Payslip"
const noteTwo =
  "2 Note that the figures are a guide only and are dependant on the individual’s personal tax situation and membership of NHS Pension Scheme if applicable."
const disclaimer =
  "*Based on our standard scheme through salary sacrifice arrangement. End of agreement fee may apply at term end. Savings are estimated and calculated for illustrative purposes only. Your actual savings may vary dependant on your tax band and the VAT rate applicable."

const BikeQuoteFootnote = () => {
  return (
    <Container>
      <Text>{noteOne}</Text>
      <Text>{noteTwo}</Text>
      <Text>{disclaimer}</Text>
    </Container>
  )
}

export default BikeQuoteFootnote

const Container = styled(Box)`
  width: 96%;
  max-width: 495px;
  margin: 0 auto;
  margin-top: 24px;
  margin-bottom: 21px;
`

const Text = styled(Typography)`
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: ${({ theme }) => theme.palette.primary.main};
  margin-bottom: 0.125rem;
`
