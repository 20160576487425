/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/*
 *
 * GTM (Google Tag Manager) Events Tracking JavaScript Component
 *
 * This javascript library contains all the GTM Events
 *
 *
 */

window.gtmCurrentUser = window.gtmCurrentUser || {}
window.dataLayer = window.dataLayer || []

// GTM Event to track when user successfully registered the Account
export const accountRegistration = () => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "account-registration",
    },
  }
  return dataLayer.push(gtm_event)
}

// GTM Event to track when user successfully logged-in into his/her account
export const loginSuccess = () => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "login-success",
    },
  }
  return dataLayer.push(gtm_event)
}

// GTM Event to track when user access the page after successful login
export const homePageView = () => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      "page-name": "Home",
      "page-type": "Home",
    },
  }
  return dataLayer.push(gtm_event)
}

export const storeName = (store) => {
  if (store === undefined || store === null || store.trim() === "") {
    return undefined
  }

  const lowercaseString = store.toLowerCase()
  const underscoredString = lowercaseString.replace(/[-\s]/g, "_")
  return underscoredString
}

// GTM Event to track when user reaches a page which contains products listed
export const productImpressions = (obj) => {
  const products = []

  obj["products"].map((product: Array) => {
    products.push({
      name: product["name"],
      id: product["id"],
      dimension14: product["dimension14"],
      price: product["price"],
      brand: product["brand"],
      category: product["category"],
      variant: product["variant"],
      list: product["list"],
      position: product["position"],
      department: product["department"],
    })
  })

  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "product-impressions",
      store: storeName(obj["store"]),
      supplier: obj["supplier"],
      ecommerce: {
        currencyCode: "GBP",
        impressions: products,
      },
    },
  }
  return dataLayer.push(gtm_event)
}

// GTM Event to track when user clicks on the products
export const productClick = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "product-click",
      store: storeName(obj["store"]),
      supplier: obj["supplier"],
      ecommerce: {
        click: {
          actionField: { list: obj["list"] },
          products: [
            {
              name: obj["name"],
              id: obj["id"],
              dimension14: obj["dimension14"],
              price: obj["price"],
              brand: obj["brand"],
              category: obj["category"],
              variant: obj["variant"],
              department: obj["department"],
            },
          ],
        },
      },
    },
  }
  return dataLayer.push(gtm_event)
}
export const storeFilter = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "filters",
      filter_type: obj["filterType"],
      selectedOption: obj["selectedOption"], // selected option value
      store: storeName(obj["store"]),
      supplier: undefined,
    },
  }
  return dataLayer.push(gtm_event)
}
export const navigationRoundleClick = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "navigation_click",
      event_action: obj["event_action"],
      event_label: obj["event_label"], // selected option value
      store: storeName(obj["store"]),
      supplier: undefined,
    },
  }
  return dataLayer.push(gtm_event)
}
export const navigationClick = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "navigation_click",
      mega_menu: obj["menu"],
      mega_menu_tab_category: obj["category"],
      mega_menu_tab_subcategory: obj["subcategory"],
      store: storeName(obj["store"]),
      supplier: undefined,
    },
  }
  return dataLayer.push(gtm_event)
}

export const affordabilityCalculator = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "affordability_calculator",
      monthlyAffordability: obj["monthlyAffordability"],
      monthly_affordability: obj["monthly_affordability"],
      store: storeName(obj["store"]),
      supplier: undefined,
    },
  }
  return dataLayer.push(gtm_event)
}

// GTM Event to track user accesses the product page
export const productView = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "product-view",
      store: storeName(obj["store"]),
      supplier: obj["supplier"],
      ecommerce: {
        detail: {
          actionField: { list: obj["list"] },
          products: [
            {
              name: obj["name"],
              id: obj["id"],
              dimension14: obj["dimension14"],
              price: obj["price"],
              brand: obj["brand"],
              category: obj["category"],
              variant: obj["variant"],
              department: obj["department"],
            },
          ],
        },
      },
    },
  }
  return dataLayer.push(gtm_event)
}

// GTM Event to track when user adds a product to the cart
export const addToCart = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "add-to-cart",
      store: storeName(obj["store"]),
      supplier: obj["supplier"],
      ecommerce: {
        currencyCode: "GBP",
        add: {
          products: [
            {
              name: obj["name"],
              id: obj["id"].toString(),
              dimension14: obj["dimension14"],
              price: obj["price"],
              brand: obj["brand"],
              category: obj["category"],
              variant: obj["variant"],
              quantity: obj["quantity"],
              department: obj["department"],
            },
          ],
        },
      },
    },
  }
  return dataLayer.push(gtm_event)
}

// GTM Event to track when user removes a product from the basket
export const removeFromCart = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "remove-from-cart",
      store: storeName(obj["store"]),
      supplier: obj["supplier"],
      ecommerce: {
        remove: {
          products: [
            {
              name: obj["name"],
              id: obj["id"],
              dimension14: obj["dimension14"],
              price: obj["price"],
              brand: obj["brand"],
              category: obj["category"],
              variant: obj["variant"],
              quantity: obj["quantity"],
              department: obj["department"],
            },
          ],
        },
      },
    },
  }
  return dataLayer.push(gtm_event)
}

// GTM Event to track when user accesses the checkout step page
export const checkout = (obj) => {
  const products = []

  obj["products"].map((product: Array) => {
    products.push({
      name: product["name"],
      id: product["id"],
      dimension14: product["dimension14"],
      price: product["price"],
      brand: product["brand"],
      category: product["category"],
      variant: product["variant"],
      quantity: product["quantity"],
      department: product["department"],
    })
  })

  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "checkout",
      store: storeName(obj["store"]),
      supplier: obj["supplier"],
      ecommerce: {
        checkout: {
          actionField: { step: obj["step"], option: obj["optionName"] },
          products: products,
        },
      },
    },
  }
  return dataLayer.push(gtm_event)
}

// GTM Event to track when user reaches to purchase page
export const ecommSuccess = (obj) => {
  const products = []

  obj["products"].map((product: Array) => {
    products.push({
      name: product["name"],
      id: product["id"],
      dimension14: product["dimension14"],
      price: product["price"],
      brand: product["brand"],
      category: product["category"],
      variant: product["variant"],
      quantity: product["quantity"],
      department: product["department"],
    })
  })

  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "ecomm-success",
      store: storeName(obj["store"]),
      ecommerce: {
        purchase: {
          actionField: {
            id: obj["transaction-id"],
            affiliation: obj["affiliation"],
            revenue: obj["revenue"],
            tax: obj["tax"],
            shipping: obj["shipping"],
          },
          products: products,
        },
      },
      supplier: obj["supplier"],
      discount: obj["discount"],
    },
  }
  return dataLayer.push(gtm_event)
}

// GTM Event to track when user creates the dashboard
export const dashboardCreated = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "dashboard-created",
      "dashboard-type": obj["dashboard-type"],
    },
  }
  return dataLayer.push(gtm_event)
}

// GTM Event to track event when user inputs search term (Store Name/Product Name)
export const search = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "view_search_results",
      event_action: obj["search-term"],
      store: storeName(obj.store),
      supplier: undefined,
    },
  }
  return dataLayer.push(gtm_event)
}

// GTM Event to track event when user clicks on search suggestion
export const popularSuggestion = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "popular-suggestion",
      "search-term": obj["search-term"],
      store: storeName(obj.store),
      supplier: undefined,
    },
  }
  return dataLayer.push(gtm_event)
}

// GTM Event to track event when user submits a problem with an order
export const submittedProblemWithOrder = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "submitted-problem-with-order",
      "problem-type": obj["problem-type"],
    },
  }
  return dataLayer.push(gtm_event)
}

// GTM Event to track event when user viewes an internal message
export const viewedMessages = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "viewed-messages",
      "message-selected": obj["message-selected"],
    },
  }
  return dataLayer.push(gtm_event)
}

// GTM Event to track when user selects (checked box) product category
export const productCategorySelected = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "product-category-selected",
      "product-category": obj["product-category"],
    },
  }
  return dataLayer.push(gtm_event)
}

// GTM Event to track when user creates a noticeboard
export const noticeboardCreated = () => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "noticeboard-created",
    },
  }
  return dataLayer.push(gtm_event)
}

// GTM Event to track when user creates a custom page
export const customPageCreated = () => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "custom-page-created",
    },
  }
  return dataLayer.push(gtm_event)
}

export const homePageNoticeboardBanner = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "home-page-noticeboard-banner",
      "noticeboard-banner-name": obj["noticeboard-banner-name"],
    },
  }
  return dataLayer.push(gtm_event)
}

export const payrollPay = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "payroll-pay",
      "payroll-pay-category": obj["payroll-pay-category"],
    },
  }
  return dataLayer.push(gtm_event)
}

export const payrollPayOption = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "payroll-pay-option",
      "payroll-pay-category": obj["payroll-pay-category"],
      "payroll-pay-option": obj["payroll-pay-option"],
    },
  }
  return dataLayer.push(gtm_event)
}

export const helpAndSupportWhiteBox = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "help-and-support-white-box",
      "help-and-support-white-box": obj["help-and-support-white-box"],
    },
  }
  return dataLayer.push(gtm_event)
}

export const helpAndSupport = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "help-and-support",
      "help-and-support-category": obj["help-and-support-category"],
    },
  }
  return dataLayer.push(gtm_event)
}

export const lifestyleSaving = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "lifestyle-saving",
      "lifestyle-saving-category": obj["lifestyle-saving-category"],
      store: storeName("lifestyle_savings"),
      supplier: undefined,
    },
  }
  return dataLayer.push(gtm_event)
}

export const lifestyleSavingDiscount = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "lifestyle-saving-discount",
      "lifestyle-saving-category": obj["lifestyle-saving-category"],
      "discount-page": obj["discount-page"],
      store: storeName("lifestyle_savings"),
      supplier: undefined,
    },
  }
  return dataLayer.push(gtm_event)
}

export const helpAndSupportOption = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "help-and-support-option",
      "help-and-support-category": obj["help-and-support-category"],
      "help-and-support-option": obj["help-and-support-option"],
    },
  }
  return dataLayer.push(gtm_event)
}

export const topNoticeboardOption = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "home-page-noticeboard-banner",
      "noticeboard-banner-name": obj["top-noticeboard-category"],
      "noticeboard-option": obj["top-noticeboard-option"],
    },
  }
  return dataLayer.push(gtm_event)
}

export const bottomNoticeboardOption = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "home-page-noticeboard-banner",
      "noticeboard-banner-name": obj["bottom-noticeboard-category"],
      "noticeboard-option": obj["bottom-noticeboard-option"],
    },
  }
  return dataLayer.push(gtm_event)
}
//Lifestyle-Saving gtm events

export const lifestylePillClick = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "lifestyle_savings_lp_pill_cat_click",
      lifestyle_savings_cat: obj["lifestyle_savings_cat"],
      lifestyle_savings_page: "Landing Page",
      position: obj.position,
      store: storeName("lifestyle_savings"),
      supplier: undefined,
    },
  }
  return dataLayer.push(gtm_event)
}
export const lifestyleTopNavClick = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "lifestyle_savings_top_nav",
      lifestyle_savings_cat: obj["lifestyle_savings_cat"],
      lifestyle_savings_page: obj["lifestyle_savings_page"], //Landing Page, Products Page, Local Benefits, etc
      top_nav_type_selected: obj["top_nav_type_selected"], //All Categories, Savings Types, etc
      top_nav_option_selected: obj["top_nav_option_selected"],
      store: storeName("lifestyle_savings"),
      supplier: undefined,
    },
  }
  return dataLayer.push(gtm_event)
}
export const lifestyleFeatureTileClick = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "lifestyle_savings_lp_feature_tiles_click",
      lifestyle_savings_cat: obj["lifestyle_savings_cat"],
      lifestyle_savings_page: "Landing Page",
      position: obj.position,
      store: storeName("lifestyle_savings"),
      supplier: undefined,
    },
  }
  return dataLayer.push(gtm_event)
}
export const lifestyleExploreAllDiscountsClick = () => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "lifestyle_savings_lp_explore_all_click",
      lifestyle_savings_page: "Landing Page",
      store: storeName("lifestyle_savings"),
      supplier: undefined,
    },
  }
  return dataLayer.push(gtm_event)
}
export const lifestyleDiscountCodeCopyClick = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "lifestyle_savings_discount_code_copy",
      lifestyle_savings_cat: obj.lifestyle_savings_cat,
      lifestyle_savings_page: obj.lifestyle_savings_page,
      position: obj.position,
      brand_name: obj.brand_name,
      brand_id: obj.brand_id,
      discount_amount: obj.discount_amount,
      saving_type: obj.saving_type,
      discount_code: obj.discount_code,
      store: storeName("lifestyle_savings"),
      supplier: undefined,
    },
  }

  return dataLayer.push(gtm_event)
}
export const lifestylePopupButtonClick = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "lifestyle_savings_discount_action",
      lifestyle_savings_cat: obj.lifestyle_savings_cat,
      lifestyle_savings_page: obj.lifestyle_savings_page,
      position: obj.position,
      brand_name: obj.brand_name,
      brand_id: obj.brand_id,
      discount_amount: obj.discount_amount,
      saving_type: obj.saving_type,
      option_selected: obj.option_selected,
      store: storeName("lifestyle_savings"),
      supplier: undefined,
    },
  }

  return dataLayer.push(gtm_event)
}
export const lifestyleCouroselClick = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: obj.event,
      lifestyle_savings_cat: obj.lifestyle_savings_cat,
      lifestyle_savings_page: "Landing Page",
      position: obj.position,
      brand_name: obj.brand_name,
      brand_id: obj.brand_id,
      discount_amount: obj.discount_amount,
      saving_type: obj.saving_type,
      carousel_type: obj.carousel_type,
      store: storeName("lifestyle_savings"),
      supplier: undefined,
    },
  }

  return dataLayer.push(gtm_event)
}
export const lifestyleSavingTileClick = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "lifestyle_savings_tile_click",
      lifestyle_savings_cat: obj.lifestyle_savings_cat,
      lifestyle_savings_page: obj.lifestyle_savings_page,
      position: obj.position,
      brand_name: obj.brand_name,
      brand_id: obj.brand_id,
      discount_amount: obj.discount_amount,
      saving_type: obj.saving_type,
      store: storeName("lifestyle_savings"),
      supplier: undefined,
    },
  }

  return dataLayer.push(gtm_event)
}

export const lifestylePillsOnLanding = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "lifestyle_savings_lp_pill_cat_display",
      lifestyle_savings_cat: obj.lifestyle_savings_cat,
      lifestyle_savings_page: "Landing Page",
      position: obj.position,
      store: storeName("lifestyle_savings"),
      supplier: undefined,
    },
  }

  return dataLayer.push(gtm_event)
}

export const lsProductPageLoad = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "lifestyle_savings_product_page_load",
      lifestyle_savings_page: obj.pageType, //Landing Page, Products Page, Local Benefits, etc
      lifestyle_savings_filter: obj.categoryFilters,
      trending_filter: obj.trendingFilters,
      saving_type_filter: obj.savingTypeFilters,
      store: storeName("lifestyle_savings"),
      supplier: undefined,
    },
  }

  return dataLayer.push(gtm_event)
}

export const lifestyleSavingsFilterClick = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "lifestyle_savings_filter",
      lifestyle_savings_page: obj.pageType, //Landing Page, Products Page, Local Benefits, etc
      lifestyle_savings_filter: obj.categoryFilters,
      trending_filter: obj.trendingFilters,
      saving_type_filter: obj.savingTypeFilters,
      store: storeName("lifestyle_savings"),
      supplier: undefined,
    },
  }

  return dataLayer.push(gtm_event)
}

export const lifestyleSavingsSearch = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "lifestyle_savings_search",
      lifestyle_savings_page: obj.pageType, //Landing Page, Products Page, Local Benefits, etc
      lifestyle_savings_filter: obj.categoryFilters,
      trending_filter: obj.trendingFilters,
      saving_type_filter: obj.savingTypeFilters,
      search_term: obj.searchTerm,
      search_results: obj.searchResults,
      store: storeName("lifestyle_savings"),
      supplier: undefined,
    },
  }

  return dataLayer.push(gtm_event)
}

export const lifestyleSavingsLpVideoPlay = () => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "lifestyle_savings_lp_video_play",
      lifestyle_savings_page: "Landing Page",
      store: storeName("lifestyle_savings"),
      supplier: undefined,
    },
  }
  return dataLayer.push(gtm_event)
}

export const CycleToWorkCalculateSavings = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "cycle_to_work_calculate_savings",
      store: storeName("Cycle_to_Work"),
      supplier: undefined,
      spend_amount: obj.spend_amount,
      annual_salary: obj.annual_salary,
      payment_term: obj.payment_term,
    },
  }
  return dataLayer.push(gtm_event)
}

export const CycleToWorkBenefitNavigationClick = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "cycle_to_work_nav_click",
      store: storeName("Cycle_to_Work"),
      supplier: undefined,
      event_action: "benefits Navigation",
      category: "cycle_to_work",
      event_label: obj.event_label,
    },
  }
  return dataLayer.push(gtm_event)
}

export const CycleToWorkBenefitWorksNavigationClick = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "cycle_to_work_nav_click",
      store: storeName("Cycle_to_Work"),
      supplier: undefined,
      event_action: obj.event_action,
      category: "cycle_to_work",
      event_label: "Browse Bikes online",
    },
  }
  return dataLayer.push(gtm_event)
}
export const CycleToWorkBenefitDepartmentNavigationClick = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "cycle_to_work_nav_click",
      store: storeName("Cycle_to_Work"),
      supplier: undefined,
      event_action: "navigation_departments",
      category: "cycle_to_work",
      event_label: obj.event_label,
    },
  }
  return dataLayer.push(gtm_event)
}

export const CycleToWorkSearchForShop = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "search_for_shop",
      search_type: "Address|Shop",
      search_keyword: obj.search_keyword,
      category: "cycle_to_work",
      store: storeName("Cycle_to_Work"),
      supplier: undefined,
    },
  }
  return dataLayer.push(gtm_event)
}
export const CycleToWorkStoreClick = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "cycle_to_work_store_click",
      store: storeName("Cycle_to_Work"),
      supplier: undefined,
      search_keyword: obj.search_keyword,
      bike_store_name: obj.bike_store_name,
    },
  }
  return dataLayer.push(gtm_event)
}

export const CycleToWorkQuotePageSteps1 = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "order_with_quote_funnel",
      store: storeName("Cycle_to_Work"),
      supplier: undefined,
      step: obj.step,
      bike_type: obj.bike_type,
      payment_term: obj.payment_term,
      value: obj.value,
      bike_store_name: obj.bike_store_name,
    },
  }
  return dataLayer.push(gtm_event)
}

export const CycleToWorkLinkClick = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "cycle_to_work_store_link_click",
      store: storeName("Cycle_to_Work"),
      supplier: undefined,
      search_keyword: obj.search_keyword,
      bike_store_name: obj.bike_store_name,
    },
  }

  return dataLayer.push(gtm_event)
}

export const portalHomepageLanding = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "onLandingPoratalHomepage",
      organisation_id: obj.organisation_id,
    },
  }

  return dataLayer.push(gtm_event)
}

export const portalHomepageHomeandElectronicButtonClick = () => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "hp_explore_all_home_electornics_click",
      section: "Home & Electronics",
      store: storeName("Home and Electronics"),
      supplier: undefined,
    },
  }

  return dataLayer.push(gtm_event)
}
export const portalHomepageLifestyleSavingsButtonClick = () => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "hp_explore_lifestyle_savings_click",
      section: "lifestyle saving",
      store: storeName("Lifestyle Savings"),
      supplier: undefined,
    },
  }

  return dataLayer.push(gtm_event)
}
export const portalHomepageHealthandWellbeingButtonClick = () => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "hp_access_support_wellbeing_click",
      section: "Health and Wellbeing",
    },
  }

  return dataLayer.push(gtm_event)
}
export const portalHomepageLifestyleSavingsPillClick = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "lifestyle_savings_cat_click",
      section: "Lifestyle Savings",
      cat: obj.cat,
      store: storeName("Lifestyle Savings"),
      supplier: undefined,
    },
  }

  return dataLayer.push(gtm_event)
}
export const portalHomepageHealthandWellbeingSelectionClick = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "hp_drop_down_selection",
      section: "Health and Wellbeing",
      event_label: obj.event_label,
    },
  }

  return dataLayer.push(gtm_event)
}

export const portalHomepageHomeandElectronicImageClick = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "hp_home_electornics_click",
      section: "Home & Electronics",
      cat: obj.cat,
    },
  }

  return dataLayer.push(gtm_event)
}
export const portalHomepageCycleToWorkImageClick = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "hp_cycle_to_work_click",
      section: "Cycle To Work",
      cat: obj.cat,
    },
  }

  return dataLayer.push(gtm_event)
}
export const portalHomepageExploreBenefitTileClick = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "hp_explore_benefits_click",
      avaliable: obj.avaliable,
      cat: obj.cat,
    },
  }

  return dataLayer.push(gtm_event)
}

export const portalHomepageExploreBenefitButtonClick = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "hp_explore_benefits_button_click",
      avaliable: "yes",
      cat: obj.cat,
    },
  }

  return dataLayer.push(gtm_event)
}
export const portalHomepageExploreBenefitRequestButtonClick = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "hp_request_to_open_button_click'",
      avaliable: "no",
      cat: obj.cat,
    },
  }

  return dataLayer.push(gtm_event)
}
export const portalHomepageNoticeboardButtonClick = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "noticeboard_click'",
      event_action: obj.event_action,
    },
  }

  return dataLayer.push(gtm_event)
}

export const portalHomepageBannerSpaceClick = () => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "lifestyle_savings_banner_click'",
      lifestyle_savings_page: "Home Page",
      banner_name: "Lifestyle savings",
      banner_id: "lifestylesSavings_banner",
      banner_description: "explore lifestyle savings banner",
      store: storeName("Lifestyle Savings"),
      supplier: undefined,
    },
  }

  return dataLayer.push(gtm_event)
}

export const portalHomepageHighFiveBannerClick = () => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "hp_download_app_banner_click'",
      banner_name: "HighFive",
      banner_id: "HighFive_banner",
      banner_description: "download app banner",
    },
  }

  return dataLayer.push(gtm_event)
}

export const portalHomepageExploreCycleToWorkButtonClick = () => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "hp_explore_cycle_to_work_click'",
      section: "Cycle To Work",
    },
  }

  return dataLayer.push(gtm_event)
}

export const portalHomepageExploreCarBenefitButtonClick = () => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "hp_explore_car_benefit_click'",
      section: "Car Benefit",
    },
  }
  return dataLayer.push(gtm_event)
}

export const CycleToWorkProductClick = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "product_click",
      store: storeName(obj["store"]),
      supplier: obj["supplier"],
      ecommerce: {
        currencyCode: "GBP",
        items: [
          {
            item_name: obj.item_name,
            item_id: obj.item_id,
            item_category3: obj.item_category3,
            price: obj.price,
            item_brand: obj.item_brand,
            item_category: obj.item_category,
            item_variant: obj.item_variant,
            item_list_name: "Cycle To Work Home Page",
            item_list_id: "cycle to work-homepage",
            index: obj.index,
            item_category2: obj.item_category2,
          },
        ],
      },
    },
  }
  return dataLayer.push(gtm_event)
}
export const CycleToWorkAddToCart = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "add_to_cart",
      store: storeName(obj["store"]),
      supplier: obj["supplier"],
      ecommerce: {
        currencyCode: "GBP",
        items: [
          {
            item_name: obj.item_name,
            item_id: obj.item_id.toString(),
            item_category3: obj.item_category3,
            price: obj.price,
            item_brand: obj.item_brand,
            item_category: obj.item_category,
            item_variant: obj.item_variant,
            item_list_name: "Cycle To Work Home Page",
            item_list_id: "cycle to work-homepage",
            index: obj.index,
            item_category2: obj.item_category2,
          },
        ],
      },
    },
  }
  return dataLayer.push(gtm_event)
}
export const CycleToWorkProductRemove = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "remove_from_cart",
      store: storeName(obj["store"]),
      supplier: obj["supplier"],
      ecommerce: {
        currencyCode: "GBP",
        items: [
          {
            item_name: obj.item_name,
            item_id: obj.item_id,
            item_category3: obj.item_category3,
            price: obj.price,
            item_brand: obj.item_brand,
            item_category: obj.item_category,
            item_variant: obj.item_variant,
            item_list_name: "Cycle To Work Home Page",
            item_list_id: "cycle to work-homepage",
            index: obj.index,
            item_category2: obj.item_category2,
          },
        ],
      },
    },
  }
  return dataLayer.push(gtm_event)
}

export const storeFilterDepartment = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "filters",
      filter_type: obj["filterType"],
      selectedOption: obj["selectedOption"],
      categoryType: obj["categoryType"], // selected option value
      store: storeName(obj["store"]),
      supplier: undefined,
    },
  }
  return dataLayer.push(gtm_event)
}

export const storeView = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "product_view",
      store: storeName(obj["store"]),
      supplier: obj["supplier"],
      ecommerce: {
        currencyCode: "GBP",
        items: [
          {
            item_name: obj.item_name,
            item_id: obj.item_id,
            item_category3: obj.item_category3,
            price: obj.price,
            item_brand: obj.item_brand,
            item_category: obj.item_category,
            item_variant: obj.item_variant,
            item_list_name: "Cycle To Work Home Page",
            item_list_id: "cycle to work-homepage",
            index: obj.index,
            item_category2: obj.item_category2,
          },
        ],
      },
    },
  }
  return dataLayer.push(gtm_event)
}

export const CycleToWorkBeginCheckout = (obj) => {
  const products = []

  obj["products"].map((product: Array, index) => {
    products.push({
      item_name: product.title ?? product.name ?? "",
      item_id: product.id,
      item_category3: product.sku,
      price: parseFloat(product.price).toFixed(2),
      item_brand: product.brand,
      item_category: product.category,
      item_variant: product.variant,
      item_list_name: "Cycle To Work Home Page",
      item_list_id: "cycle to work-homepage",
      index: index,
      item_category2: product.department,
    })
  })

  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "begin_checkout",
      store: storeName(obj["store"]),
      supplier: obj["supplier"],
      ecommerce: {
        currencyCode: "GBP",
        items: products,
      },
    },
  }
  return dataLayer.push(gtm_event)
}

export const CycleToWorkAddShippingInfo = (obj) => {
  const products = []

  obj["products"].map((product: Array, index) => {
    products.push({
      item_name: product.title ?? product.name ?? "",
      item_id: product.id,
      item_category3: product.sku,
      price: parseFloat(product.price).toFixed(2),
      item_brand: product.brand,
      item_category: product.category,
      item_variant: product.variant,
      item_list_name: "Cycle To Work Home Page",
      item_list_id: "cycle to work-homepage",
      index: index,
      item_category2: product.department,
    })
  })

  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "add_shipping_info",
      store: storeName(obj["store"]),
      supplier: obj["supplier"],
      ecommerce: {
        currencyCode: "GBP",
        items: products,
      },
    },
  }
  return dataLayer.push(gtm_event)
}

export const CycleToWorkEcommSuccess = (obj) => {
  const products = []

  obj["products"].map((product: Array, index) => {
    products.push({
      item_name: product.title ?? product.name ?? "",
      item_id: product.id,
      item_category3: product.dimension14,
      price: parseFloat(product.price).toFixed(2),
      item_brand: product.brand,
      item_category: product.category,
      item_variant: product.variant,
      item_list_name: "Cycle To Work Home Page",
      item_list_id: "cycle to work-homepage",
      index: index,
      item_category2: product.department,
    })
  })

  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "ecomm_success",
      store: storeName(obj["store"]),
      supplier: obj["supplier"],
      discount: obj["discount"],
      ecommerce: {
        id: obj["transaction-id"],
        affiliation: obj["affiliation"],
        revenue: obj["revenue"],
        tax: obj["tax"],
        shipping: obj["shipping"],
      },
      items: products,
    },
  }
  return dataLayer.push(gtm_event)
}

export const CycleToWorkProductImpressions = (obj) => {
  const products = []

  obj["products"].map((product: Array, index) => {
    products.push({
      item_name: product.title ?? product.name ?? "",
      item_id: product.id,
      item_category3: product.dimension14,
      price: parseFloat(product.price).toFixed(2),
      item_brand: product.brand,
      item_category: product.category,
      item_variant: product.variant,
      item_list_name: "Cycle To Work Home Page",
      item_list_id: "cycle to work-homepage",
      index: index,
      item_category2: product.department,
    })
  })

  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "product_impressions",
      store: storeName(obj["store"]),
      supplier: obj["supplier"],
      ecommerce: {
        currencyCode: "GBP",
        items: products,
      },
    },
  }
  return dataLayer.push(gtm_event)
}
export const placedStore = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      store: storeName(obj.store),
    },
  }

  return dataLayer.push(gtm_event)
}
export const InstantSearch = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: obj.event,
      page_location: window.location.pathname + window.location.search,
    },
  }
  return dataLayer.push(gtm_event)
}

export const portalHomepageHealthAndWellbeingLinkClick = (obj) => {
  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "health_wellbeing_link_clicks",
      click_text: obj.label,
      click_url: obj.url,
      parent_menu: "Health and Wellbeing",
      click_location: obj.clickLocation,
    },
  }

  return dataLayer.push(gtm_event)
}

export const onPageland = (obj) => {
  let storeValue, pageNameValue, pageTypeValue, categoryValue

  if (obj.store === "bike_shop") {
    storeValue = "bike_shop"
    pageNameValue = "bike shop page"
    pageTypeValue = "bike shop"
    categoryValue = "bike_shop"
  } else if (obj.store === "cycle_to_work") {
    storeValue = "Cycle_to_Work"
    pageNameValue = "cycle to work page"
    pageTypeValue = "cycle to work"
    categoryValue = "cycle_to_work"
  } else if (obj.store === "lifestyle_savings") {
    storeValue = "lifestyle_savings"
    pageNameValue = "lifestyle savings page"
    pageTypeValue = "lifestyle savings"
    categoryValue = "lifestyle savings"
  } else if (obj.store === "home_and_electronics") {
    storeValue = storeName("Home_and_Electronics")
    pageNameValue = "home and electronics page"
    pageTypeValue = "home and electronics"
    categoryValue = "home_and_electronics"
  } else {
    storeValue = "portal_homepage"
    pageNameValue = "portal homepage"
    pageTypeValue = "portal homepage"
    categoryValue = "portal_homepage"
  }

  const gtm_event = {
    ...gtmCurrentUser,
    ...{
      event: "pageview",
      store: storeValue,
      supplier: undefined,
      page_name: pageNameValue,
      page_type: pageTypeValue,
      category: categoryValue,
    },
  }
  return dataLayer.push(gtm_event)
}
