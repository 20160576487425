import ButtonIcon from "./images/Chatbutton.svg"
import Typography from "@mui/material/Typography"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import { Box, Button } from "@mui/material"
import Zendesk, { ZendeskAPI } from "react-zendesk"
import { useState, useEffect } from "react"
import theme from "../../../theme/mui-theme"
import { useTranslation } from "react-i18next"

const ZENDESK_KEY = "a36c46a6-ab01-4999-a8f8-e10a16de2367"

const getStyles = () => ({
  buttonChat: {
    minWidth: "3.375rem",
    background: `transparent linear-gradient(90deg, #77377A 0%, ${theme.palette.primary.main} 100%) 0% 0% no-repeat padding-box`,
    boxShadow: theme.boxShadow,
    borderRadius: "2.625rem",
    zIndex: "9999",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "capitalize",
    width: "3.375rem",
    height: "3.375rem",
    bottom: "1.688rem",
    right: "2.375rem",
    cursor: "none",
    [theme.breakpoints.up("md")]: {
      width: "7.813rem",
      height: "3.375rem",
      bottom: "1.813rem",
      right: "2.5rem",
      cursor: "pointer",
    },
    "&:hover": {
      background:
        "transparent linear-gradient(90deg, #96679A 0%, #6D548A 100%) 0% 0% no-repeat padding-box",
      backgrounPosition: "center",
    },
  },
  keepFloating: {
    position: "fixed",
  },
  stopFloating: {
    position: "absolute",
  },
  chatIcon: {
    width: "1.5rem",
    height: "1.5rem",
    marginRight: "0rem",
    [theme.breakpoints.up("md")]: {
      marginRight: "0.3125rem",
    },
  },
  chatText: {
    fontSize: "1rem",
    color: theme.palette.white.main,
    fontWeight: 500,
  },
})
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    zE?: any
  }
}
const ButtonChat = () => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const classes = getStyles()
  const [showWidget, setShowWidget] = useState(false)
  const [stopBeforeFooter, setStopBeforeFooter] = useState(true)
  const { t, ready } = useTranslation("checkoutPage")
  const liveChatTrigger = function liveChatTrigger() {
    if (window.zE.activate) {
      window.zE.activate()
    } else {
      window.$zopim.livechat.window.show()
      window.$zopim.livechat.window.onHide(() => {
        window.$zopim.livechat.window.hide()
      })
    }
  }
  const hendleAfterLoad = function hendleAfterLoad() {
    ZendeskAPI("webWidget", "hide")
    setShowWidget(true)
  }

  useEffect(() => {
    const handler = () => {
      setStopBeforeFooter(() => {
        if (
          document.documentElement.scrollTop <
          document.documentElement.scrollHeight -
            document.documentElement.clientHeight -
            document.getElementsByTagName("Footer")[0].offsetHeight
        ) {
          return true
        }

        return false
      })
    }

    window.addEventListener("scroll", handler)
    return () => window.removeEventListener("scroll", handler)
  }, [])

  if (!ready) {
    return null
  }
  return (
    <>
      <Zendesk
        defer
        zendeskKey={ZENDESK_KEY}
        onLoaded={() => hendleAfterLoad()}
      />
      {showWidget && (
        <Button
          sx={{
            ...classes.buttonChat,
            ...(stopBeforeFooter ? classes.keepFloating : classes.stopFloating),
          }}
          aria-label={t("checkoutPage.buttonChat.ariaLabel")}
          onClick={liveChatTrigger}
        >
          <Box
            component="img"
            src={ButtonIcon}
            alt={t("checkoutPage.buttonChat.alt")}
            sx={classes.chatIcon}
          />
          {isDesktop && (
            <Typography variant="body1" ml={1} sx={classes.chatText}>
              {t("checkoutPage.buttonChat.chat")}
            </Typography>
          )}
        </Button>
      )}
    </>
  )
}

export default ButtonChat
