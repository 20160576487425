import {
  Stack,
  useMediaQuery,
  useTheme,
  Typography,
  Box,
  styled,
  css,
} from "@mui/material"
import InfoTooltip from "../shared/InfoTooltip"
import AffordabilityCalculatorButton from "../shared/affordability-calculator/AffordabilityCalculatorButton"
import AddCardPopup from "./add-to-order/AddCardPopup"
import { ProductType } from "./product-card/ProductCard"
import VarientDropdownGrid from "./VariationDropdownGrid"
import { useState, useEffect, useContext } from "react"
import { checkProductImpressions } from "../shared/gtm-events/CustomGtmEvents"
import { StoreLayoutContext } from "../layout/store/StoreLayoutContext"
import { getDynamicInfoToolTip } from "../../utils/dynamicMessageSchemeStores"
import { useParams, useSearchParams } from "react-router-dom"
import { BrochureGroup } from "../../graphqGenaretedTypes"
import StoreDropdown from "../shared/StoreDropdown"
import updatedSearchParams from "../../utils/updatedSearchParams"
import { useTranslation } from "react-i18next"
import { viewedObjectIDs } from "../../utils/algoliaEvents"

export type ProductDetailsProps = {
  id: number
  title: string
  description: string
  price: string
  brand: string
  variant: string
  category: string
  sku: string
  storeName: string
  recommendations: ProductType[]
  brochureGroupId: number
  productId: number
  availableSizes: string
  totalAmount: string
  isNhs: boolean
  department: string
  brochureGroups: BrochureGroup[]
  supplierName?: string
  status: "preOrder" | "backOrder" | "default"
  releaseDate?: string
  reachedMaximumQuantity: boolean
  maximumQuantity: number
}

export default function ProductDetails({
  id,
  title,
  description,
  price,
  brand,
  variant,
  category,
  sku,
  storeName,
  recommendations,
  brochureGroupId,
  productId,
  availableSizes,
  totalAmount,
  isNhs,
  department,
  brochureGroups,
  supplierName,
  releaseDate,
  status,
  reachedMaximumQuantity,
  maximumQuantity,
}: ProductDetailsProps) {
  const theme = useTheme()
  const { quoteType } = useContext(StoreLayoutContext)
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const [selectedSize, setSelectedSize] = useState("")
  const selectedSizeFnc = (size: string) => {
    if (size) {
      setSelectedSize(size)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", checkProductImpressions)
    return () => window.removeEventListener("scroll", checkProductImpressions)
  })
  useEffect(() => {
    viewedObjectIDs([productId.toString()])
  }, [productId])
  const params = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const { t, ready } = useTranslation(["constants", "productDetail"])

  if (!ready) {
    return null
  }

  return (
    <>
      <Box
        sx={{
          maxWidth: { xs: "20rem", sm: "43rem", md: "30.3125rem" },
          margin: "0 auto",
          paddingLeft: { xs: "0", sm: "10px", md: "1.25rem", lg: "2.125rem" },
        }}
      >
        {(status === "preOrder" || status === "backOrder") && (
          <AvailableTag>
            {status === "preOrder"
              ? t("productDetail:productDetail.preOrder")
              : t("productDetail:productDetail.backOrder")}
          </AvailableTag>
        )}
        <MainHeading>{title}</MainHeading>
        {(status === "preOrder" || status === "backOrder") && releaseDate && (
          <PreBackOrderTextStyle sx={{ display: "flex", alignItems: "center" }}>
            {status === "preOrder"
              ? t("productDetail:productDetail.launchDate")
              : t("productDetail:productDetail.expectedDate")}
            : {releaseDate}
            <InfoTooltip
              content={
                status === "preOrder"
                  ? t("productDetail:productDetail.infoMessagePreOrder")
                  : t("productDetail:productDetail.infoMessageBackOrder")
              }
            />
          </PreBackOrderTextStyle>
        )}
        {isDesktop && (
          <Content as={"div"} sx={{ marginTop: "1.1875rem" }}>
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </Content>
        )}
        {JSON.parse(availableSizes).length > 0 && (
          <Stack
            direction="row"
            spacing={4}
            sx={{ marginTop: isDesktop ? "1.625rem" : "0.6785rem" }}
          >
            <VarientDropdownGrid
              availableSizes={availableSizes}
              selectedSizeFnc={(size) => {
                if (size) {
                  selectedSizeFnc(size)
                }
              }}
            />
          </Stack>
        )}
        <Content sx={{ marginTop: isDesktop ? "1.875rem" : "1.5625rem" }}>
          {t("productDetail:productDetail.monthlyAmount")}
        </Content>
        <Content>
          <PriceTag>
            {t("productDetail:productDetail.StickyACButton.Modal.currency")}
            {parseFloat(price).toFixed(2)}
          </PriceTag>
          {t("productDetail:productDetail.perMonth")}
          {params.schemeType !== "bike_shop" ? (
            <InfoTooltip content={getDynamicInfoToolTip(quoteType)} />
          ) : (
            ""
          )}
        </Content>
        <Stack
          direction="row"
          sx={{
            marginTop: isDesktop ? "1.375rem" : "1.5625rem",
            alignItems: "flex-start",
          }}
        >
          {brochureGroups.length > 1 && (
            <StoreDropdown
              dropdownOptions={brochureGroups.map((brochureGroup) => ({
                value: brochureGroup.id,
                title:
                  brochureGroup.term +
                  " " +
                  t("productDetail:productDetail.monthTerm"),
              }))}
              selected={brochureGroupId.toString()}
              onChange={(brochureGroupId) => {
                if (searchParams.get("bg") !== brochureGroupId) {
                  setSearchParams(
                    updatedSearchParams(searchParams, [
                      { paramName: "bg", value: brochureGroupId || "" },
                    ])
                  )
                }
              }}
            />
          )}
          <AffordabilityCalculatorButton />
        </Stack>
        <Content sx={{ margin: "1.5625rem 0" }}>
          {t("productDetail:productDetail.totalAmount")} <br />
          {t("productDetail:productDetail.StickyACButton.Modal.currency")}
          {parseFloat(totalAmount).toFixed(2)}
          {isNhs ? "*" : ""}
        </Content>
        <AddCardPopup
          id={id}
          title={title}
          totalAmount={totalAmount}
          brand={brand}
          variant={variant}
          category={category}
          sku={sku}
          storeName={storeName}
          products={recommendations}
          brochureGroupId={brochureGroupId}
          productId={productId}
          selectedSize={selectedSize}
          department={department}
          supplierName={supplierName}
          releaseDate={releaseDate}
          status={status}
          reachedMaximumQuantity={reachedMaximumQuantity}
          maximumQuantity={maximumQuantity}
        />
      </Box>
    </>
  )
}

const MainHeading = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 2rem;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      font-size: 0.875rem;
      line-height: 1.5rem;
      margin-top: 0.625rem;
    }
  `}
`

const Content = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    font-size: 1rem;
    line-height: 1.5rem;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      font-size: 0.75rem;
      line-height: 1.3rem;
    }
  `}
`

const PriceTag = styled("span")`
  ${({ theme }) => css`
    color: ${theme.palette.secondary.main};
    font-size: 1.5rem;
    font-weight: bold;
    margin-right: 0.3rem;
  `}
`

export const AvailableTag = styled("div")`
  ${({ theme }) => css`
    color: ${theme.palette.white.main};
    max-width: 10.25rem;
    width: 100%;
    height: 1.3125rem;
    background-color: ${theme.palette.secondary.main};
    letter-spacing: 0;
    text-align: center;
    border-radius: 0.25rem;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 1.0625rem;
    padding-top: 0.125rem;
    padding-bottom: 0.0625rem;
  `}
`

export const PreBackOrderTextStyle = styled("p")`
  ${({ theme }) => css`
    color: ${theme.palette.secondary.main};
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.25rem;
  `}
`
