import { Autocomplete, TextField, styled } from "@mui/material"
import {
  AddressSuggestionType,
  DeliveryAddressType,
} from "../../store/checkout-page/Delivery"
import SearchIcon from "@mui/icons-material/Search"
import { useEffect, useState } from "react"
import {
  getAddressSuggestions,
  getFullAddress,
} from "../../store/checkout-page/CheckoutContext"
import { useFormikContext } from "formik"
import FormikValueType from "../../shared/form/formikValueType"
import { useTranslation } from "react-i18next"

/**
 *
 * Copied from Delivery.tsx,
 * Difference is it plugs in a formik object, so used as a stand alone component.
 * No validation needed for this particular field
 * After calling triggerGetFullAddressCall(), full address is validated and populated in the relevant field according to the id
 *

 */

const FindYourAddress = <T extends FormikValueType>() => {
  const { t, ready } = useTranslation("bikeQuote")
  const formik = useFormikContext<T>()
  const [searchTerm, setSearchTerm] = useState("")
  const [apiResponseAddresses, setApiResponseAddresses] = useState<
    AddressSuggestionType[]
  >([])
  const { refetch } = getAddressSuggestions("")
  const triggerAddressSuggestionsCall = () => {
    refetch({
      searchTerm: searchTerm,
    }).then((data) => {
      const result = data?.data?.employeeOrganisation?.addressSuggestions?.map(
        (addressData) => {
          return {
            label: addressData.address,
            id: addressData.id,
          }
        }
      )
      setApiResponseAddresses(result || [])
    })
  }

  const { refetch: refetchFullAddress } = getFullAddress("")
  const triggerGetFullAddressCall = (addressId: string) => {
    refetchFullAddress({
      id: addressId,
    }).then((data) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const fullAddressData: any =
        data?.data?.employeeOrganisation?.getFullAddress
      // eslint-disable-next-line @typescript-eslint/no-explicit-any

      Object.keys(fullAddressData).forEach((key) => {
        if (key in formik.values) {
          formik.setFieldValue(key, fullAddressData[key])
        }
      })
    })
  }

  useEffect(() => {
    if (searchTerm.length > 0) {
      const delayDebounceFn = setTimeout(() => {
        triggerAddressSuggestionsCall()
      }, 300)

      return () => clearTimeout(delayDebounceFn)
    }
    return () => setApiResponseAddresses([])
  }, [searchTerm]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeTemplate = (
    event: React.SyntheticEvent,
    value: DeliveryAddressType
  ) => {
    triggerGetFullAddressCall(value.id || "")
  }

  if (!ready) {
    return null
  }
  return (
    <StyledAutocomplete
      disablePortal // for iOS voice over support
      filterOptions={(x) => x}
      options={apiResponseAddresses}
      renderInput={(params) => (
        <TextField
          {...params}
          label={
            <div style={{ display: "flex", justifyContent: "center" }}>
              <SearchIcon sx={{ paddingRight: "0.5rem" }} fontSize="small" />
              {t("typeYourAddress")}
            </div>
          }
        />
      )}
      onKeyUp={(e) => setSearchTerm((e.target as HTMLInputElement).value)}
      onChange={(event, value) =>
        value ? handleChangeTemplate(event, value) : ""
      }
    />
  )
}

export default FindYourAddress

const StyledAutocomplete = styled(Autocomplete)`
  margin-bottom: 1.875rem;
`
