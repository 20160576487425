// Library import
import { Box, styled, css } from "@mui/material"

// Other components
import { Header } from "./Header"
import { ViewAllButton } from "./ViewAllButton"
import { DesktopRoundel } from "./DesktopRoundel"
import HEMobileRoundel from "./HEMobileRoundel"
import { RoundelProps } from "../../shared/Roundel"
import {
  DESKTOP_CONTAINER_WIDTH,
  MobileWhiteBox,
  PaddingContainer,
} from "../shared/SharedStyles"
import useIsMobileView from "../../../utils/useIsMobileView"

const ROUNDEL_BACKGROUND = "gray"
const ROUNDEL_PER_PAGE = 4
const hEMobileRoundelId = "hEMobileRoundel"

const HomeAndElectronicsSection = ({
  roundels,
}: {
  roundels: RoundelProps[]
}) => {
  const isMobile = useIsMobileView()
  return (
    <Rectangle>
      <OuterContainer>
        <PaddingContainer>
          <Header />
          {isMobile ? (
            <MobileRoundelContainer id={hEMobileRoundelId}>
              <HEMobileRoundel
                roundelArray={roundels}
                roundelBackground={ROUNDEL_BACKGROUND}
                roundelPerPage={ROUNDEL_PER_PAGE}
                isHomeAndElectronicSection={true}
                isCycleToWork={false}
                hEMobileRoundelId={hEMobileRoundelId}
              />
            </MobileRoundelContainer>
          ) : (
            <DesktopRoundel
              roundelArray={roundels}
              roundelBackground={ROUNDEL_BACKGROUND}
              isHomeAndElectronicSection={true}
              cycleToWorkStore={false}
            />
          )}
          <ViewAllButton />
        </PaddingContainer>
      </OuterContainer>
    </Rectangle>
  )
}

export default HomeAndElectronicsSection

const OuterContainer = styled(MobileWhiteBox)`
  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      max-width: ${DESKTOP_CONTAINER_WIDTH}px;
    }
  `}
`

const Rectangle = styled(Box)`
  width: 100%;
  display: grid;
  place-items: center;
  position: relative;
  z-index: 10; // later define z-index in variable so that they can be managed in one place
`

const MobileRoundelContainer = styled("section")`
  min-height: 432px;
  display: grid;
  align-items: center;

  .slick-slider {
    display: grid;
  }

  .slick-dots {
    bottom: -10px;
  }
`
