import { useState } from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Modal from "@mui/material/Modal"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import CloseIcon from "@mui/icons-material/Close"
import { useMediaQuery, useTheme } from "@mui/material"
import classNames from "classnames"
import DrawerItem from "../drawer/DrawerItem"
import styled from "@emotion/styled"
import onEnter from "../../../utils/onEnter"

export type SupportModalProps = {
  buttonLabel: string
  children: JSX.Element
}

export default function SupportModal({
  buttonLabel,
  children,
}: SupportModalProps) {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const [open, setOpen] = useState(false)

  return (
    <div>
      {isDesktop ? (
        <Mainlinkbutton
          onClick={() => setOpen(true)}
          variant="invisible"
          sx={{
            svg: {
              marginLeft: "-0.75rem",
            },
          }}
        >
          <ChevronRightIcon />
          {buttonLabel}
        </Mainlinkbutton>
      ) : (
        <DrawerItem
          text={buttonLabel}
          divider
          onClick={() => setOpen(true)}
          onKeyDown={(event) => onEnter(event, () => setOpen(true))}
        />
      )}

      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: {
              xs: "80%",
              md: "70%",
            },
            bgcolor: (theme) => theme.palette.white.main,
            boxShadow: 24,
            borderRadius: "1rem",
            paddingTop: "1.25rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            display: "flex",
            flexDirection: "column",
            outline: "none",
          }}
          className="support-modal"
        >
          <Button onClick={() => setOpen(false)} sx={{ alignSelf: "end" }}>
            <CloseIcon />
            <div className="screen-reader-only">Close</div>
          </Button>
          <div
            className={classNames("support-modal__content", {
              "support-modal__content--wide": !isDesktop,
            })}
          >
            {children}
          </div>
          <Button
            sx={{
              fontSize: "0.875rem",
              textDecoration: "underline",
              alignSelf: "center",
              paddingY: "0.75rem",
              ":hover": {
                backgroundColor: "inherit",
                textDecoration: "underline",
              },
            }}
            onClick={() => setOpen(false)}
          >
            Close window
          </Button>
        </Box>
      </Modal>
    </div>
  )
}
export const Mainlinkbutton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 0;
  text-align: inherit;
  &:svg {
    margin-left: -0.75rem;
  }
`
