import { Button, Grid, TextField, Link } from "@mui/material"
import AddBoxIcon from "@mui/icons-material/AddBox"
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox"
import { removeBasketItem, updateBasketItem } from "./BasketContext"
import { useMediaQuery, useTheme } from "@mui/material"
import classNames from "classnames"
import { useState } from "react"
import {
  gtmCycleToWorkProductRemove,
  gtmRemoveFromCart,
} from "../../shared/gtm-events/CustomGtmEvents"
import { BasketItem } from "../../../graphqGenaretedTypes"
import Alert from "../../shared/Alert"
import { useParams } from "react-router-dom"
import { AvailableTag, PreBackOrderTextStyle } from "../ProductDetails"
import InfoTooltip from "../../shared/InfoTooltip"
import { styled, css } from "@mui/material"
import { useTranslation } from "react-i18next"
export type ItemProps = {
  product: BasketItem
  maxQuantity?: number
  editable: boolean
  currentUserId?: string
  currentOrg?: string
  status: "preOrder" | "backOrder" | "default"
  releaseDate?: string
}

export default function Item({
  product,
  maxQuantity = 20,
  editable,
  currentUserId,
  currentOrg,
  status,
  releaseDate,
}: ItemProps) {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const {
    id,
    imageUrl,
    name,
    price,
    quantity,
    link,
    brand,
    category,
    variant,
  } = product
  const [stateQuantity, setStateQuantity] = useState(quantity)
  const [removed, setRemoved] = useState(false)
  const [incrementBasketItemQty] = updateBasketItem(id, stateQuantity + 1)
  const params = useParams()
  const { t, ready } = useTranslation("basket")
  const CycletoWorkStore = params.schemeType === "cycle_to_work"
  const triggerIncrementBasketItemQty = () => {
    incrementBasketItemQty()
      .then((data) => {
        const errorMessageLength =
          data?.data?.updateBasketItem?.errors?.length || 0

        setShowAlertMessage("")
        if (errorMessageLength > 0) {
          setShowAlertMessage(data?.data?.updateBasketItem?.errors[0] || "")
          return
        }
        setStateQuantity(stateQuantity + 1)
      })
      .catch(() => {
        // TODO - handle error
      })
  }
  const [showAlertMessage, setShowAlertMessage] = useState("")
  const [decrementBasketItemQty] = updateBasketItem(id, stateQuantity - 1)
  const triggerDecrementBasketItemQty = () => {
    decrementBasketItemQty()
  }

  const [removeBasketItemMutation] = removeBasketItem(id)
  const triggerRemoveBasketItemMutation = (product: BasketItem) => {
    removeBasketItemMutation().then(({ data }) => {
      if (
        data?.removeBasketItem?.errors &&
        data.removeBasketItem?.errors.length === 0
      ) {
        setRemoved(true)
        window.localStorage.removeItem(
          product.productId + "clicked" + currentUserId + currentOrg
        )
      }
    })
    CycletoWorkStore && gtmCycleToWorkProductRemove(product)
    !CycletoWorkStore && gtmRemoveFromCart(product)
  }

  const Image = <img className="item__image" src={imageUrl} alt={name} />

  if (!ready) {
    return null
  }
  return (
    <>
      {showAlertMessage.length > 0 && (
        <Alert severity={"error"} message={showAlertMessage} />
      )}
      {!removed && (
        <div className="item border-bottom">
          <Grid container spacing={2}>
            <Grid item xs={3} sx={{ display: "flex" }}>
              <div className="content-center">
                {editable ? <Link href={link}>{Image}</Link> : <>{Image}</>}
              </div>
            </Grid>
            <Grid item xs={9} sx={{ display: "flex", flexDirection: "column" }}>
              {(status === "preOrder" || status === "backOrder") && (
                <AvailableTags>
                  {status === "preOrder"
                    ? t("basketItem.preOrder")
                    : t("basketItem.backOrder")}
                </AvailableTags>
              )}
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={8}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="item__name"
                    style={{
                      paddingTop:
                        params.schemeType === "home_and_electronics"
                          ? "0px"
                          : undefined,
                      paddingBottom:
                        params.schemeType === "home_and_electronics"
                          ? "7px"
                          : undefined,
                    }}
                  >
                    {editable ? (
                      <Link
                        href={link}
                        sx={{ textDecoration: "none", paddingTop: "0px" }}
                        data-brand={brand}
                        data-category={category}
                        data-variant={variant}
                      >
                        {name}
                      </Link>
                    ) : (
                      <>{name}</>
                    )}
                    {!editable && (
                      <div className="item__quantity">
                        {t("basketItem.quantity")} {quantity}
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item xs={4} sx={{ alignItems: "center" }}>
                  <div
                    className={classNames("item__price-container", {
                      "body-3": !isDesktop,
                    })}
                  >
                    <div
                      className={classNames(isDesktop ? "h3" : "h4")}
                      style={{ color: theme.palette.secondary.main }}
                    >
                      {t("basket.currency")}
                      {price.toFixed(2)}
                    </div>
                    {t("basketItem.perMonths")}
                  </div>
                </Grid>
                {(status === "preOrder" || status === "backOrder") &&
                  releaseDate && (
                    <LaunchDatesText>
                      {status === "preOrder"
                        ? t("basketItem.preOrderDate")
                        : t("basketItem.backOrderDate")}
                      : {releaseDate}
                      <InfoTooltip
                        content={
                          status === "preOrder"
                            ? t("basketItem.infoMessagePreOrder")
                            : t("basketItem.infoMessageBackOrder")
                        }
                      />
                    </LaunchDatesText>
                  )}
                {editable && (
                  <Grid item xs={12}>
                    <div className="item__action-container">
                      <Button
                        onClick={() => {
                          triggerRemoveBasketItemMutation(product)
                        }}
                        variant="invisible"
                        sx={{
                          fontSize: isDesktop ? 12 : 10,
                          marginRight: "0.5rem",
                          paddingLeft: 0,
                        }}
                      >
                        {t("basketItem.remove")}
                      </Button>
                      <div
                        className="content-center"
                        style={{ fontSize: isDesktop ? 12 : 10 }}
                      >
                        {t("basketItem.quantity")}
                        <Button
                          variant="invisible"
                          onClick={() => {
                            triggerDecrementBasketItemQty()
                            setStateQuantity(stateQuantity - 1)
                          }}
                          disabled={stateQuantity <= 1}
                          sx={{ paddingX: "0.5rem" }}
                          title={t("basketItem.decreaseQuantity")}
                        >
                          <IndeterminateCheckBoxIcon
                            className={classNames(
                              stateQuantity <= 1 && "disabled"
                            )}
                            style={
                              stateQuantity <= 1
                                ? {}
                                : { color: theme.palette.secondary.main }
                            }
                          />
                        </Button>
                        <label htmlFor={`quantity-${id}`}>
                          <span className="d-none">
                            {t("basketItem.quantityTitle")}
                          </span>
                        </label>
                        <TextField
                          id={`quantity-${id}`}
                          value={stateQuantity}
                          disabled
                          sx={{ width: "2rem" }}
                          inputProps={{
                            sx: {
                              padding: "0.2rem 0.5rem",
                              fontSize: "0.75rem",
                              WebkitTextFillColor:
                                theme.palette.primary.main + " !important",
                              textAlign: "center",
                            },
                          }}
                        />
                        <Button
                          variant="invisible"
                          onClick={() => {
                            triggerIncrementBasketItemQty()
                          }}
                          disabled={stateQuantity >= maxQuantity}
                          sx={{ paddingX: "0.5rem" }}
                          title={t("basketItem.increaseQuantity")}
                        >
                          <AddBoxIcon
                            className={classNames(
                              stateQuantity >= 20 && "disabled"
                            )}
                            style={
                              stateQuantity >= 20
                                ? {}
                                : { color: theme.palette.secondary.main }
                            }
                          />
                        </Button>
                      </div>
                    </div>
                    {stateQuantity >= maxQuantity && (
                      <MaxLimitWarning>
                        {t("basketItem.maxLimitWarning")} {maxQuantity}
                      </MaxLimitWarning>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  )
}
export const AvailableTags = styled(AvailableTag)`
  ${({ theme }) => css`
    &&& {
      height: 1.3125rem;
      padding-top: unset;
      padding-bottom: unset;
      @media screen and (max-width: ${theme.breakpoints.values.md}px) {
        height: 1.25rem;
        font-size: 0.75rem;
        max-width: 8.875rem;
      }
    }
  `}
`

export const LaunchDatesText = styled(PreBackOrderTextStyle)`
  ${({ theme }) => css`
    &&& {
      display: flex;
      align-items: center;
      padding-left: 0.5rem;
      margin-top: 0.0625rem;
      margin-bottom: unset;
      font-size: 1rem;
      @media screen and (max-width: ${theme.breakpoints.values.md}px) {
        font-size: 0.75rem;
      }
    }
  `}
`

export const MaxLimitWarning = styled("p")`
  ${({ theme }) => css`
    &&& {
      color: ${theme.palette.error.main};
      font-size: 0.75rem;
      line-height: 0.875rem;
      font-weight: 500;
      @media screen and (max-width: ${theme.breakpoints.values.md}px) {
        max-width: 13.75rem;
      }
    }
  `}
`
