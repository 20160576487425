import { createContext } from "react"
import { useParams } from "react-router-dom"
import {
  GetGlobalLayoutDataQueryHookResult,
  useGetGlobalLayoutDataQuery,
  Noticeboard as NoticeboardType,
  HealthAndWellbeing as HealthAndWellbeingType,
  FamilyCare as FamilyCareType,
} from "../../graphqGenaretedTypes"
import formatMegaMenuData, { PortalMegaMenuItem } from "./formatMegaMenuData"
import formatBurgerMenuData from "./formatBurgerMenuData"
import { ItemHierarchyType } from "../shared/step-menu/StepMenu"
import { useTranslation } from "react-i18next"
import {
  AvailableLanguagesType,
  DefaultLanguageType,
  fallbackLanguage,
} from "../../utils/languageUtils"

export type GlobalLayoutContextData = {
  privacyNoticeUrl?: string
  organisationPrivacyNotice: boolean
  organisationName: string
  currentUserId: number
  logo?: string
  condensedLogo?: string
  loading: boolean
  noticeboards: NoticeboardType[]
  backendUser: boolean
  reporter: boolean
  isOrganisationSettingUser: boolean
  employeeOrganisations: {
    name: string
    changePortalLink: string
  }[]
  healthAndWellbeing: HealthAndWellbeingType
  megaMenuItems: PortalMegaMenuItem[]
  burgerMenuItems: ItemHierarchyType[]
  familyCareItem: FamilyCareType
  availableLanguages: AvailableLanguagesType
  defaultLanguage: DefaultLanguageType
}

const defaultHealthAndWellbeing: HealthAndWellbeingType = {
  level: "",
  usefulLinks: [],
}

const defaultFamilyCare: FamilyCareType = {
  usefulLinks: [],
}

const defaultValue: GlobalLayoutContextData = {
  privacyNoticeUrl: undefined,
  organisationPrivacyNotice: false,
  noticeboards: [],
  megaMenuItems: [],
  currentUserId: -1,
  backendUser: false,
  reporter: false,
  isOrganisationSettingUser: false,
  employeeOrganisations: [],
  organisationName: "Vivup",
  healthAndWellbeing: defaultHealthAndWellbeing,
  loading: true,
  burgerMenuItems: [],
  familyCareItem: defaultFamilyCare,
  availableLanguages: [],
  defaultLanguage: fallbackLanguage,
}

let state = JSON.parse(JSON.stringify(defaultValue))

const valueFromQueryResult = function (
  result: GetGlobalLayoutDataQueryHookResult,
  organisationId: string,
  t: (key: string) => string,
  ready: boolean
): GlobalLayoutContextData {
  const data = result.data

  const loading = result.loading
  const healthAndWellbeingData = data?.employeeOrganisation?.healthAndWellbeing
  const familycareData = data?.employeeOrganisation?.familyCare
  const megaMenuItems = result.loading
    ? []
    : formatMegaMenuData(
        data?.employeeOrganisation?.portal?.megaMenuItems || [],
        data?.employeeOrganisation?.lifestyleSavings?.categories || [],
        data?.employeeOrganisation?.portal?.areas?.some(
          (e) => e === "lifestyle_savings"
        ),
        organisationId
      )

  const burgerMenuItems = ready
    ? formatBurgerMenuData(
        t,
        megaMenuItems,
        healthAndWellbeingData || undefined,
        familycareData || undefined
      )
    : []
  let privacyNoticeUrl = undefined
  if (organisationId) {
    privacyNoticeUrl =
      data?.employeeOrganisation?.customPrivacyNoticeUrl ??
      `/organisations/${organisationId}/privacy_notice`
  }

  if (!loading) {
    state = {
      ...defaultValue,
      privacyNoticeUrl: privacyNoticeUrl,
      organisationPrivacyNotice:
        data?.employeeOrganisation?.organisationPrivacyNotice ?? false,
      organisationName: data?.employeeOrganisation?.name || "Vivup",
      currentUserId: data?.currentUser?.id || -1,
      logo: data?.employeeOrganisation?.logo || undefined,
      condensedLogo: data?.employeeOrganisation?.condensedLogo || undefined,
      noticeboards: data?.employeeOrganisation?.portal?.noticeboards || [],
      employeeOrganisations: data?.currentUser?.employeeOrganisations || [],
      backendUser: data?.currentUser?.backendUser || false,
      reporter: data?.employeeOrganisation?.reporter || false,
      isOrganisationSettingUser:
        data?.currentUser?.isOrganisationSettingUser || false,
      megaMenuItems: megaMenuItems,
      healthAndWellbeing:
        data?.employeeOrganisation?.healthAndWellbeing ||
        defaultHealthAndWellbeing,
      loading: loading,
      burgerMenuItems,
      familyCareItem:
        data?.employeeOrganisation?.familyCare || defaultFamilyCare,
      availableLanguages: data?.employeeOrganisation?.availableLanguages || [],
      defaultLanguage:
        data?.employeeOrganisation?.defaultLanguage || fallbackLanguage,
    }
  } else {
    state = {
      ...state,
      loading: true,
    }
  }
  return state
}

export const useGlobalLayoutData = function (): GlobalLayoutContextData {
  const params = useParams()
  const { i18n } = useTranslation()

  const data = useGetGlobalLayoutDataQuery({
    variables: {
      organisationId: params.organisationId ?? "",
      locale: i18n.language,
    },
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  })

  const { t, ready } = useTranslation("layout")

  return valueFromQueryResult(data, params.organisationId ?? "", t, ready)
}

export const GlobalLayoutContext = createContext(defaultValue)
