import { useParams } from "react-router-dom"
import {
  ButtonBase,
  SpaceTwoButtonContainer,
  WhiteButtonText,
} from "../../shared/SharedStyles"
import { Button as MuiButton, css, styled } from "@mui/material"
import { useTranslation } from "react-i18next"

export function Button() {
  const params = useParams()
  const url = `/organisations/${params.organisationId}/employee/benefits/family_care`
  const { t } = useTranslation("portalHomepage")

  return (
    <SpaceTwoButtonContainer>
      <PurpleButtonContainer component={"a"} href={url} disableFocusRipple>
        <WhiteButtonText>{t("familyCare.buttonText")}</WhiteButtonText>
      </PurpleButtonContainer>
    </SpaceTwoButtonContainer>
  )
}
const PurpleButtonContainer = styled(ButtonBase)`
  ${({ theme }) => css`
    background: rgba(106, 107, 168, 1);
    display: inline-flex;

    &:hover {
      background: rgba(106, 107, 168, 1);
    }

    &:disabled {
      background-color: ${theme.palette.grey[200]};
    }

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      padding: 0;
    }
  `}
` as typeof MuiButton
