import {
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
  setNestedObjectValues,
} from "formik"
import { FormikValue, initialValues } from "./initialValues"
import { Box, Button, css, styled, Typography } from "@mui/material"
import { useState } from "react"
// import { DisplayFormikState } from "../formik-helper"
import { YourDetails } from "./YourDetails"
import Tabs from "./Tabs"
import SubmitOrder from "./SubmitOrder"
import { validationSchema } from "./validation-schema/validationSchema"
import { Disclaimer } from "./Disclaimer"
import {
  CreateCycleToWorkQuote,
  useDefaultUserData,
  useFormData,
} from "./QuotePageContext"
import OrderDetails from "./OrderDetails"
import Loader from "../../shared/Loader"
import { Footnote } from "./Footnote"
import Layout from "../Layout"
import Alert from "../../shared/Alert"
import ThankYou, { ThankYouProps } from "../../store/thank-you/ThankYou"
import parseError, { ParsedErrorsType } from "./parseErrors"
import scrollToElement from "./scrollToElement"
import { useParams, useSearchParams } from "react-router-dom"
import { gtmCycleToWorkQuotePageSteps1 } from "../../shared/gtm-events/CustomGtmEvents"
import { flushSync } from "react-dom"
import { useTranslation } from "react-i18next"

const initSuccessfulOrderDetails: ThankYouProps = {
  orderId: "",
  storeName: "",
  supplier: "",
  productsRevenue: "",
  products: [],
}

const QuotePage = () => {
  const { t, ready } = useTranslation("bikeQuote")
  const { userData } = useDefaultUserData()
  const { formData } = useFormData()
  const [activeTab, setActiveTab] = useState(0)
  const isLastStep = activeTab === 2
  const [alertMessage, setAlertMessage] = useState("")
  const [errorCount, setErrorCount] = useState(0) // for re-rendering alert snackbar
  const [successfulOrderDetails, setSuccessfulOrderDetails] =
    useState<ThankYouProps>(initSuccessfulOrderDetails)
  const [isOverQuoteLimit, setOverQuoteLimit] = useState(false)
  const [searchParams] = useSearchParams()
  const bikeShopId = searchParams.get("supplier")
  const { schemeType } = useParams()
  const isNetBikeShop = schemeType === "bike_shop"
  const [values, setValues] = useState(initialValues)

  const clickedBikeShop = formData.suppliers.filter(
    (supplier) => supplier.supplierId === bikeShopId
  )

  const [createCycleToWorkQuoteMutation, { loading: isProcessingOrder }] =
    CreateCycleToWorkQuote(values)

  // handleFormSubmit() fires only after formik.handleSubmit()
  const handleFormSubmit = (
    values: FormikValue,
    formikHelper: FormikHelpers<FormikValue>
  ) => {
    // force rendering, otherwise setValues() won't update the values right away (due to React 18 batching)
    flushSync(() => {
      setValues(values)
    })
    createCycleToWorkQuoteMutation().then(({ data }) => {
      if (
        data?.createCycleToWorkQuote?.errors &&
        data?.createCycleToWorkQuote?.errors.length > 0
      ) {
        // global errors
        const globalErrors = data.createCycleToWorkQuote.errors
          .filter(({ fieldName }) => fieldName == "global")
          .map(({ message }) => {
            return { message }
          })

        if (globalErrors.length > 0) {
          setAlertMessage(globalErrors.map(({ message }) => message).join(", "))
          setErrorCount(errorCount + 1)
        }

        // field specific errors
        const parsedErrors: ParsedErrorsType = parseError(
          data?.createCycleToWorkQuote?.errors
        )

        // deal with the first error only
        if (parsedErrors.length > 0) {
          const { id, tab } = parsedErrors[0]
          setActiveTab(tab)
          // validate all the fields on that tab so error message show
          formikHelper.validateForm()
          // scroll to that element
          scrollToElement(`#${id}`)
        }

        formikHelper.setSubmitting(false)
      }

      if (data?.createCycleToWorkQuote?.order?.id) {
        setSuccessfulOrderDetails({
          ...successfulOrderDetails,
          orderId: data?.createCycleToWorkQuote?.order?.id,
          supplier: data?.createCycleToWorkQuote?.supplier ?? "",
          productsRevenue: data?.createCycleToWorkQuote?.revenue ?? "",
          // products is of BasketType[]
          products: data?.createCycleToWorkQuote?.products ?? [],
          storeName: data?.createCycleToWorkQuote?.storeName ?? "Cycle to work",
        })
      }
    })
  }

  async function handleStepValidation({
    index,
    formik,
  }: {
    index: number
    formik: FormikProps<FormikValue>
  }) {
    formik.isValidating = true
    const validationErrors = await formik.validateForm() // validate the current page

    if (isOverQuoteLimit) {
      scrollToElement("#totalOrderValue")
    } else if (Object.keys(validationErrors).length > 0) {
      // set each error field as touch=true, so error message shows
      formik.setTouched(setNestedObjectValues(validationErrors, true))
      formik.isValidating = false
      scrollToElement(".Mui-error, [data-error]")
    } else {
      formik.setTouched({})
      setActiveTab(index + 1)
      formik.isValidating = false
      window.scrollTo(0, 0)
    }
  }

  function handleLastStep(formik: FormikProps<FormikValue>) {
    const allChecked = formik.values.checkboxes.every(
      (checkbox) => checkbox.checked
    )
    if (!allChecked) {
      setAlertMessage(t("bikeQuote:alertMessage"))
      setErrorCount(errorCount + 1)
      return
    }
    // handleSubmit() comes with formik which runs a series of actions
    formik.handleSubmit()
  }

  function handleButtonClick(formik: FormikProps<FormikValue>) {
    if (isLastStep) {
      handleLastStep(formik)
    } else {
      handleStepValidation({ index: activeTab, formik })
    }
    const BikeShop = formik.values.bikeShop.label
    const TypeofBike = formik.values.bikes[0].typeOfBike
    const PaymentTerm = formik.values.brochureGroup
    const value = formik.values.bikes[0].bikeValue

    if (activeTab === 0) {
      const step = "order_details_complete"
      gtmCycleToWorkQuotePageSteps1(
        BikeShop,
        TypeofBike,
        PaymentTerm,
        value,
        step
      )
    } else if (activeTab === 1) {
      const step = "your_details_complete"
      gtmCycleToWorkQuotePageSteps1(
        BikeShop,
        TypeofBike,
        PaymentTerm,
        value,
        step
      )
    } else {
      const step = "submit_order_complete'"
      gtmCycleToWorkQuotePageSteps1(
        BikeShop,
        TypeofBike,
        PaymentTerm,
        value,
        step
      )
    }
  }

  if (userData.loading || formData.loading || !ready) {
    return <Loader />
  }

  if (successfulOrderDetails.orderId) {
    const { orderId, storeName, supplier, productsRevenue, products } =
      successfulOrderDetails

    return (
      <ThankYou
        orderId={orderId}
        storeName={storeName}
        supplier={supplier}
        productsRevenue={productsRevenue}
        products={products}
      />
    )
  }

  // Set pre filled values
  else {
    const init = { ...initialValues }

    init.placeOfWork = userData.placeOfWork
    init.employeeNumber = userData.employeeNumber
    init.niNumber = userData.niNumber
    init.title = userData.title
    init.dateOfBirth = userData.dateOfBirth
    init.firstName = userData.firstName
    init.lastName = userData.lastName
    init.mobileNumber = userData.mobileNumber
    init.phoneNumber = userData.phoneNumber
    init.email = userData.email
    init.lineOne = userData.lineOne
    init.lineTwo = userData.lineTwo
    init.town = userData.town
    init.county = userData.county
    init.postCode = userData.postCode
    init.loading = userData.loading
    init.checkboxes = userData.checkboxes

    if (clickedBikeShop.length > 0) {
      init.bikeShop = clickedBikeShop[0]
    }

    const prefilledInitialValues = init

    return (
      <Layout
        breadcrumbsItems={formData.breadcrumbs}
        bannerTitle={t("bannerTitle")}
      >
        <Instruction variant="h2">{t("instructionText")}</Instruction>

        <Formik
          initialValues={prefilledInitialValues}
          validationSchema={validationSchema[activeTab]}
          onSubmit={(values, formikHelper) =>
            handleFormSubmit(values, formikHelper)
          }
        >
          {(formik) => {
            return (
              <Form>
                {alertMessage && (
                  <Alert
                    severity={"error"}
                    message={alertMessage}
                    key={errorCount}
                  />
                )}
                {/* className="form" is for CheckBoxes styling  */}
                <FormContainer className="form">
                  <Tabs
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    isOverQuoteLimit={isOverQuoteLimit}
                  />

                  {activeTab == 0 && (
                    <OrderDetails
                      isOverQuoteLimit={isOverQuoteLimit}
                      setOverQuoteLimit={setOverQuoteLimit}
                    />
                  )}
                  {activeTab == 1 && <YourDetails />}
                  {activeTab == 2 && <SubmitOrder />}

                  {activeTab !== 0 && <Footnote />}

                  <ContinueButton
                    variant="gradient"
                    onClick={() => handleButtonClick(formik)}
                    disabled={
                      formik.isSubmitting ||
                      formik.isValidating ||
                      isProcessingOrder
                    }
                  >
                    {activeTab === 2 ? t("submitOrder") : t("continue")}
                  </ContinueButton>

                  {activeTab === 2 && !isNetBikeShop && <Disclaimer />}

                  {/* TODO: Remove this formik state display when done */}
                  {/* <DisplayFormikState /> */}
                </FormContainer>
              </Form>
            )
          }}
        </Formik>
      </Layout>
    )
  }
}

export default QuotePage

const FormContainer = styled(Box)`
  ${({ theme }) => css`
    width: 89.4%;
    margin: 0 auto;
    margin-bottom: 3rem;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      max-width: 536px;
      padding: 60px;
      padding-bottom: 2rem;
      border: 1px solid ${theme.palette.grey[200]};
      border-radius: ${theme.boxBorderRadius};
      box-shadow: ${theme.boxShadow};
    }
  `}
`

const ContinueButton = styled(Button)`
  ${({ theme }) => css`
    background: linear-gradient(90deg, #872074, ${theme.palette.primary.main});
    display: block;
    width: 100%;
    margin-top: 2rem;

    &:hover,
    &:disabled {
      background: linear-gradient(
        90deg,
        #872074,
        ${theme.palette.primary.main}
      );
      opacity: 0.75;
    }

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      margin: 0 auto;
      max-width: 212px;
    }
  `}
`

const Instruction = styled(Typography)`
  ${({ theme }) => css`
    width: 77.8%;
    font-size: 0.875rem;
    line-height: 1rem;
    margin: 0 auto;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 3.125rem;
    max-width: 25rem;
    font-weight: 500;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 1.5rem;
      line-height: 1.875rem;
      max-width: 658px;
    }
  `}
`
