import {
  MobileWhiteBox,
  OnePropositionBox,
  OnePropositionPadding,
  TwoPropositionsBox,
} from "../../shared/SharedStyles"
import { IconsSection } from "./IconsSection"
import { Chip } from "./Chip"
import { ExploreCarBenefitButton } from "./ExploreCarBenefitButton"
import { Header } from "./Header"
import { Content } from "./Content"
import useIsMobileView from "../../../../utils/useIsMobileView"

const CarBenefit = ({ onlyCarBenefit }: { onlyCarBenefit: boolean }) => {
  const isMobile = useIsMobileView()

  if (isMobile) {
    return (
      <MobileWhiteBox>
        <Content onlyCarBenefit={onlyCarBenefit} />
      </MobileWhiteBox>
    )
  }
  if (onlyCarBenefit) {
    return (
      <OnePropositionBox>
        <Chip onlyCarBenefit={onlyCarBenefit} />

        <OnePropositionPadding>
          <Header onlyCarBenefit={onlyCarBenefit} />
        </OnePropositionPadding>

        <IconsSection onlyCarBenefit={onlyCarBenefit} />

        <ExploreCarBenefitButton />
      </OnePropositionBox>
    )
  }
  return (
    <TwoPropositionsBox>
      <Content onlyCarBenefit={onlyCarBenefit} />
    </TwoPropositionsBox>
  )
}

export default CarBenefit
