/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { Maybe } from "../../../graphqGenaretedTypes"
import {
  productClick,
  productView,
  addToCart,
  removeFromCart,
  checkout,
  ecommSuccess,
  search,
  placedStore,
  productCategorySelected,
  productImpressions,
  popularSuggestion,
  storeFilter,
  navigationClick,
  affordabilityCalculator,
  navigationRoundleClick,
  lifestylePillClick,
  lifestyleTopNavClick,
  lifestyleFeatureTileClick,
  lifestyleExploreAllDiscountsClick,
  lifestyleDiscountCodeCopyClick,
  lifestylePopupButtonClick,
  lifestyleCouroselClick,
  lifestyleSavingTileClick,
  lifestylePillsOnLanding,
  lsProductPageLoad,
  lifestyleSavingsFilterClick,
  lifestyleSavingsSearch,
  lifestyleSavingsLpVideoPlay,
  CycleToWorkBenefitNavigationClick,
  CycleToWorkBenefitWorksNavigationClick,
  CycleToWorkBenefitDepartmentNavigationClick,
  CycleToWorkSearchForShop,
  CycleToWorkStoreClick,
  CycleToWorkLinkClick,
  portalHomepageLanding,
  portalHomepageHomeandElectronicButtonClick,
  portalHomepageLifestyleSavingsButtonClick,
  portalHomepageHealthandWellbeingButtonClick,
  portalHomepageLifestyleSavingsPillClick,
  portalHomepageHealthandWellbeingSelectionClick,
  portalHomepageHomeandElectronicImageClick,
  portalHomepageCycleToWorkImageClick,
  portalHomepageExploreBenefitTileClick,
  portalHomepageExploreBenefitButtonClick,
  portalHomepageExploreBenefitRequestButtonClick,
  portalHomepageNoticeboardButtonClick,
  portalHomepageBannerSpaceClick,
  portalHomepageHighFiveBannerClick,
  portalHomepageExploreCycleToWorkButtonClick,
  portalHomepageExploreCarBenefitButtonClick,
  CycleToWorkCalculateSavings,
  CycleToWorkQuotePageSteps1,
  CycleToWorkProductClick,
  CycleToWorkAddToCart,
  storeFilterDepartment,
  storeView,
  CycleToWorkBeginCheckout,
  CycleToWorkProductImpressions,
  CycleToWorkAddShippingInfo,
  CycleToWorkEcommSuccess,
  CycleToWorkProductRemove,
  InstantSearch,
  portalHomepageHealthAndWellbeingLinkClick,
  onPageland,
} from "./GtmEvents"

const gtmProductObj = (obj: {
  id?: string | number
  brochureGroupId?: number | undefined
  title?: string
  image?: string | undefined
  lowertag?: boolean | undefined
  price?: string | number | undefined
  totalAmount?: string | number
  altnativeImgText?: string | undefined
  flashdealtag?: boolean | undefined
  link?: string | undefined
  productTagName?: string | undefined
  productTagImage?: string | undefined
  term?: number | undefined
  brand?: string
  variant?: string
  category?: string
  availableSizes?: string | undefined
  sku?: string | number
  storeName?: string
  department?: string
  imageUrl?: string | undefined
  name?: string
  productId?: string | number
  quantity?: string | number
}) => {
  return {
    name: obj.title,
    id: obj.id,
    dimension14: obj.sku,
    price: obj.totalAmount,
    brand: obj.brand,
    category: obj.category,
    variant: obj.variant,
    list: obj.storeName,
    position: 1,
    department: obj.department,
  }
}

export const gtmProductImpressions = (storeName, products) => {
  productImpressions({
    store: storeName,
    products: products,
    supplier: findUniqueSupplierName(products),
  })
}

export const gtmProductClick = (obj: {
  id?: number
  brochureGroupId?: number
  title?: string
  image?: string
  lowertag?: boolean
  price?: string
  totalAmount?: string
  altnativeImgText?: string
  flashdealtag?: boolean
  link?: string
  productTagName?: string | undefined
  productTagImage?: string | undefined
  term?: number
  brand?: string
  variant?: string
  category?: string
  availableSizes?: string
  sku?: string
  storeName: string
  supplierName?: string
  department?: string
}) => {
  const gtm_event = {
    ...gtmProductObj(obj),
    store: obj.storeName,
    supplier: obj.supplierName,
  }

  productClick(gtm_event)
}

export const gtmProductView = (obj: {
  id?: number
  title?: string
  totalAmount?: string
  brand?: string
  category?: string
  variant?: string
  sku?: string
  storeName: string
  department?: string
  supplierName?: string
}) => {
  const gtm_event = {
    ...gtmProductObj(obj),
    store: obj.storeName,
    supplier: obj.supplierName,
  }

  productView(gtm_event)
}

export const gtmAddToCart = (obj: {
  id?: number
  brochureGroupId?: number
  title?: string
  image?: string
  lowertag?: boolean
  price?: string
  totalAmount?: string
  altnativeImgText?: string
  flashdealtag?: boolean
  link?: string
  productTagName?: string | undefined
  productTagImage?: string | undefined
  term?: number
  brand?: string
  variant?: string
  category?: string
  availableSizes?: string
  sku?: string
  storeName: string
  department?: string
  supplierName?: string
}) => {
  const gtm_event = {
    ...gtmProductObj(obj),
    store: obj.storeName,
    supplier: obj.supplierName,
    quantity: 1,
  }

  addToCart(gtm_event)
}
export const gtmStoreFilter = (obj: {
  filterType: string
  selectedOption: string
  store: string | undefined
}) => {
  const gtm_event = {
    selectedOption: obj.selectedOption,
    filterType: obj.filterType,
    store: obj.store,
  }

  storeFilter(gtm_event)
}
export const gtmNavigationRoundleClick = (obj: {
  event_label: string
  event_action: string
  store: string | undefined
}) => {
  const gtm_event = {
    event_action: obj.event_action,
    event_label: obj.event_label,
    store: obj.store,
  }

  navigationRoundleClick(gtm_event)
}
export const gtmNavigationClick = (obj: {
  menu: string
  category: string
  subcategory: string
  store: string | undefined
}) => {
  const gtm_event = {
    menu: obj.menu,
    category: obj.category,
    subcategory: obj.subcategory,
    store: obj.store,
  }

  navigationClick(gtm_event)
}

export const gtmAffordabilityCalculator = (obj: {
  monthlyAffordability: number | string
  store: string | undefined
}) => {
  const gtm_event = {
    monthlyAffordability: obj.monthlyAffordability,
    monthly_affordability: obj.monthlyAffordability,
    store: obj.store,
  }

  affordabilityCalculator(gtm_event)
}

export const gtmRemoveFromCart = (obj: {
  brand?: string
  category?: string
  department?: string
  id?: string
  imageUrl?: string
  link?: string
  name: string
  price?: number
  productId: string | number
  quantity: string | number
  sku?: Maybe<string> | undefined
  storeName: string
  term?: number
  totalAmount?: string
  variant?: string
  supplierName?: string
}) => {
  const gtm_event = {
    ...gtmProductObj(obj),
    store: obj.storeName,
    supplier: obj.supplierName,
    quantity: obj.quantity,
    name: obj.name,
    id: obj.productId,
  }

  removeFromCart(gtm_event)
}

export const gtmCheckout = (storeName, products, activeStep) => {
  const gtm_products = []
  let obj = { step: 1, optionName: "Personal Detials" }

  if (activeStep == "delivery") {
    obj = { step: 2, optionName: "Submit order" }
  }

  products.forEach(
    (product: {
      name: string
      productId: string | number
      quantity: string | number
    }) => {
      gtm_products.push({
        ...gtmProductObj(product),
        name: product.name,
        id: product.productId,
        quantity: product.quantity,
      })
    }
  )

  checkout({
    store: storeName,
    supplier: findUniqueSupplierName(products),
    products: gtm_products,
    step: obj.step,
    optionName: obj.optionName,
  })
}

export const gtmEcommSuccess = (
  orderId: string,
  storeName: string,
  supplier: string,
  revenue: string,
  products
) => {
  const gtm_products = []

  products.forEach((product) => {
    gtm_products.push({
      ...gtmProductObj(product),
      name: product.name,
      price: product.price,
      quantity: product.quantity,
    })
  })

  ecommSuccess({
    store: storeName,
    "transaction-id": orderId,
    revenue: revenue,
    affiliation: "Vivup Online",
    tax: "0.00",
    shipping: "0.00",
    products: gtm_products,
    supplier: supplier,
    discount: "0",
  })
}

export const gtmSearch = (value: string, store: string | undefined) => {
  search({ "search-term": value, store: store })
}

export const gtmPopularSuggestion = (
  value: string,
  store: string | undefined
) => {
  popularSuggestion({ "search-term": value, store: store })
}

export const gtmProductCategorySelected = (value: string) => {
  productCategorySelected({ "product-category": value })
}

window.productImpressionsData = []
window.productGroupImpressions = []
let impressionTimeout
let productElements

const isElementInView = (element) => {
  const elementBoundingBox = element.getBoundingClientRect()
  const elementTopY = elementBoundingBox.top
  const elementBottomY = elementBoundingBox.top + elementBoundingBox.height
  return (
    elementTopY >= 0 &&
    elementBottomY <=
      Math.min(document.documentElement.clientHeight, window.innerHeight || 0)
  )
}

const triggerProductImpression = (obj) => {
  if (productGroupImpressions.length > 0) {
    clearTimeout(impressionTimeout)
    impressionTimeout = setTimeout(forceTriggerProductImpression, 2000)
  }

  if (productGroupImpressions.length == 10 || obj["force"] == true) {
    clearTimeout(impressionTimeout)
    const products = productGroupImpressions.map((element, index) => {
      return {
        ...products,
        ...gtmProductObjForImpression(productElements[element], index),
      }
    })

    if (
      ["cycle_to_work", "Cycle to Work"].indexOf(
        productElements[0]?.getAttribute("data-store")
      ) !== -1
    ) {
      gtmCycleToWorkProductImpressions(
        productElements[0]?.getAttribute("data-store"),
        products
      )
    } else {
      gtmProductImpressions(
        productElements[0]?.getAttribute("data-store"),
        products
      )
    }
    productGroupImpressions = []
  }
}

export const checkProductImpressions = () => {
  productElements = document.querySelectorAll(".gtm-product-box")
  for (let i = 0; i < productElements.length; i++) {
    if (
      isElementInView(productElements[i]) &&
      productImpressionsData.indexOf(i) == -1
    ) {
      productImpressionsData.push(i)
      productGroupImpressions.push(i)
      triggerProductImpression(productElements[i])
    }
  }
}

const gtmProductObjForImpression = (element, elementIndex) => {
  return {
    name: element.getAttribute("data-name"),
    id: element.getAttribute("data-id"),
    dimension14: element.getAttribute("data-sku"),
    price: element.getAttribute("data-total-amount"),
    brand: element.getAttribute("data-brand"),
    category: element.getAttribute("data-category"),
    variant: element.getAttribute("data-variant"),
    list: element.getAttribute("data-store"),
    position: elementIndex + 1,
    department: element.getAttribute("data-department"),
    supplierName: element.getAttribute("data-supplier-name"),
  }
}

const forceTriggerProductImpression = () => {
  triggerProductImpression({ force: true })
}

//Lifestyle-Saving gtm events
export const gtmLifestylePillClick = (name: string, index: number) => {
  const gtm_event = {
    lifestyle_savings_cat: name,
    position: index,
  }

  lifestylePillClick(gtm_event)
}
export const gtmLifestyleTopNavClick = (
  navTypeSelected: string | undefined,
  name: string,
  subcategories: string
) => {
  const gtm_event = {
    lifestyle_savings_cat: subcategories,
    lifestyle_savings_page: navTypeSelected,
    top_nav_type_selected: name,
    top_nav_option_selected: name,
  }

  lifestyleTopNavClick(gtm_event)
}
export const gtmLifestyleFeatureTileClick = (
  title: string | undefined,
  index: number
) => {
  const gtm_event = {
    lifestyle_savings_cat: title,
    position: index,
  }

  lifestyleFeatureTileClick(gtm_event)
}
export const gtmLifestyleExploreAllDiscountsClick = () => {
  const gtm_event = {}

  lifestyleExploreAllDiscountsClick(gtm_event)
}
export const gtmLifestyleDiscountCodeCopyClick = (
  discountCode: string | undefined,
  id: string | undefined,
  saving_type: string | undefined,
  parseAmount: string | number,
  name: string | undefined,
  categories: string | undefined,
  index: number
) => {
  const gtm_event = {
    lifestyle_savings_cat:
      lifestylePageType() === "Landing Page"
        ? categories
        : document.getElementById("current-label")?.textContent,
    lifestyle_savings_page: lifestylePageType() || "",
    position: index,
    brand_name: name,
    brand_id: id,
    discount_amount: parseAmount ? parseAmount : "",
    saving_type: saving_type ? saving_type : "",
    discount_code: discountCode,
  }

  lifestyleDiscountCodeCopyClick(gtm_event)
}

export const gtmLifestylePopupButtonClick = (
  categories: string | undefined,
  index: number,
  name: string | undefined,
  id: string | undefined,
  parseAmount: string | number,
  savingType: string | undefined
) => {
  const gtm_event = {
    lifestyle_savings_cat:
      lifestylePageType() === "Landing Page"
        ? categories
        : document.getElementById("current-label")?.textContent,
    lifestyle_savings_page: lifestylePageType() || "",
    position: index,
    brand_name: name,
    brand_id: id,
    discount_amount: parseAmount ? parseAmount : "",
    saving_type: savingType,
    option_selected: name,
  }

  lifestylePopupButtonClick(gtm_event)
}
export const gtmLifestyleCouroselClick = (
  eventName: string,
  categories: string | undefined,
  indexCarousel: number,
  name: string | undefined,
  id: string,
  parseAmount: string | number,
  savingType: string | undefined
) => {
  const gtm_event = {
    event: eventName,
    lifestyle_savings_cat: categories,
    position: indexCarousel,
    brand_name: name,
    brand_id: id,
    discount_amount: parseAmount,
    saving_type: savingType ? savingType : "",
    carousel_type: categories,
  }

  lifestyleCouroselClick(gtm_event)
}

export const gtmLifestyleSavingTileClick = (
  id: string | undefined,
  voucher: string | undefined,
  name: string | undefined,
  index: number,
  indexCarousel: number | undefined,
  parseAmount: string | number | undefined,
  categories: string | undefined
) => {
  const gtm_event = {
    lifestyle_savings_cat: categories
      ? categories
      : document.getElementById("current-label")?.textContent,
    lifestyle_savings_page: lifestylePageType() || "",
    position: index ? index : indexCarousel,
    brand_name: name,
    brand_id: id,
    saving_type: voucher ? voucher : "",
    discount_amount: parseAmount ? parseAmount : "",
  }

  lifestyleSavingTileClick(gtm_event)
}
export const gtmLifestylePillsOnLanding = (name: string, index: number) => {
  const gtm_event = {
    lifestyle_savings_cat: name,
    position: index,
  }

  lifestylePillsOnLanding(gtm_event)
}
const lifestylePageType = () => {
  const pathname = window.location.pathname
  if (pathname.includes("/landing")) {
    return "Landing Page"
  } else if (pathname.includes("/products")) {
    return "Products Page"
  }
  if (pathname.includes("/local_benefits")) {
    return "Local Benefits"
  } else {
    return "Landing Page"
  }
}

const selectedFiltersData = (elementClass: string) => {
  const labelData = []
  const inputElements = document.querySelectorAll(
    elementClass + " input:checked"
  )
  if (inputElements.length == 1) {
    return inputElements[0]?.parentElement?.getAttribute("data-original-text")
  }
  for (let i = 0; i < inputElements.length; i++) {
    labelData.push(
      "'" +
        inputElements[i]?.parentElement?.getAttribute("data-original-text") +
        "'"
    )
  }
  return labelData.join(", ")
}

export const gtmLsProductPageLoad = () => {
  const gtm_event = {
    pageType: lifestylePageType() || "", //Landing Page, Products Page, Local Benefits, etc
    categoryFilters: selectedFiltersData(".gtm-categories-filters") || "",
    trendingFilters: selectedFiltersData(".gtm-trending-filters") || "",
    savingTypeFilters: selectedFiltersData(".gtm-saving-types-filters") || "",
  }

  lsProductPageLoad(gtm_event)
}

export const gtmLifestyleSavingsFilterClick = () => {
  const gtm_event = {
    pageType: lifestylePageType() || "", //Landing Page, Products Page, Local Benefits, etc
    categoryFilters: selectedFiltersData(".gtm-categories-filters") || "",
    trendingFilters: selectedFiltersData(".gtm-trending-filters") || "",
    savingTypeFilters: selectedFiltersData(".gtm-saving-types-filters") || "",
  }

  lifestyleSavingsFilterClick(gtm_event)
}

export const triggerGtmLifestyleSavingsSearch = (searchResults: number) => {
  window.searchResults = searchResults
  window.triggerGtmSearchEvent = true
}
const updateTime = (gtm_event) => {
  gtm_event.searchResults = window.searchResults
  if (window.triggerGtmSearchEvent) {
    window.triggerGtmSearchEvent = false
    lifestyleSavingsSearch(gtm_event)
    clearInterval(window.clearIntervalCount)
  }
}
export const gtmLifestyleSavingsSearch = (searchTerm: string) => {
  window.searchResults = 0
  window.triggerGtmSearchEvent = false
  const gtm_event = {
    pageType: lifestylePageType() || "", //Landing Page, Products Page, Local Benefits, etc
    categoryFilters: "",
    trendingFilters: "",
    savingTypeFilters: "",
    searchTerm: searchTerm,
    searchResults: "",
  }

  window.clearIntervalCount = setInterval(() => {
    updateTime(gtm_event)
  }, 50)
}

export const triggerIframeGtmEvent = () => {
  const iframe = document.querySelector("iframe")

  if (iframe) {
    const player = new Vimeo.Player(iframe)
    player.on("play", function () {
      lifestyleSavingsLpVideoPlay()
    })
  }
}

export const gtmCycleToWorkBenefitWorksNavigationClick = (
  event_action: string
) => {
  const gtm_event = { event_action: event_action }

  CycleToWorkBenefitWorksNavigationClick(gtm_event)
}

export const gtmCycleToWorkBenefitNavigationClick = (label: string) => {
  const gtm_event = {
    event_label: label,
  }

  CycleToWorkBenefitNavigationClick(gtm_event)
}

export const gtmCycleToWorkBenefitDepartmentNavigationClick = (
  label: string
) => {
  const gtm_event = {
    event_label: label,
  }

  CycleToWorkBenefitDepartmentNavigationClick(gtm_event)
}

export const gtmCycleToWorkCycleToWorkSearchForShop = (searchTerm: string) => {
  const gtm_event = {
    search_keyword: searchTerm,
  }

  CycleToWorkSearchForShop(gtm_event)
}
export const gtmCycleToWorkStoreClick = (
  searchTerm: string,
  supplierName: string
) => {
  const gtm_event = {
    search_keyword: searchTerm,
    bike_store_name: supplierName,
  }

  CycleToWorkStoreClick(gtm_event)
}
export const gtmCycleToWorkLinkClick = (
  searchTerm: string,
  StoreName: string
) => {
  const gtm_event = {
    search_keyword: searchTerm,
    bike_store_name: StoreName,
  }

  CycleToWorkLinkClick(gtm_event)
}

export const gtmPortalHomepageLanding = (
  organisationId: string | undefined | null
) => {
  const gtm_event = {
    organisation_id: organisationId,
  }

  portalHomepageLanding(gtm_event)
}

export const gtmPortalHomepageHomeandElectronicButtonClick = () => {
  const gtm_event = {}

  portalHomepageHomeandElectronicButtonClick(gtm_event)
}
export const gtmPortalHomepageLifestyleSavingsButtonClick = () => {
  const gtm_event = {}

  portalHomepageLifestyleSavingsButtonClick(gtm_event)
}

export const gtmPortalHomepageHealthandWellbeingButtonClick = () => {
  const gtm_event = {}

  portalHomepageHealthandWellbeingButtonClick(gtm_event)
}

export const gtmPortalHomepageLifestyleSavingsPillClick = (label: string) => {
  const gtm_event = {
    cat: label,
  }

  portalHomepageLifestyleSavingsPillClick(gtm_event)
}

export const gtmPortalHomepageHealthandWellbeingSelectionClick = (
  label: string
) => {
  const gtm_event = {
    event_label: label,
  }

  portalHomepageHealthandWellbeingSelectionClick(gtm_event)
}

export const gtmPortalHomepageHomeandElectronicImageClick = (label: string) => {
  const gtm_event = {
    cat: label,
  }

  portalHomepageHomeandElectronicImageClick(gtm_event)
}

export const gtmPortalHomepageCycleToWorkImageClick = (label: string) => {
  const gtm_event = {
    cat: label,
  }

  portalHomepageCycleToWorkImageClick(gtm_event)
}
portalHomepageExploreBenefitTileClick
export const gtmPortalHomepageExploreBenefitTileClick = (
  truncatedLabel?: string | null,
  GtmEventAvailable: string
) => {
  const gtm_event = {
    cat: truncatedLabel,
    avaliable: GtmEventAvailable,
  }

  portalHomepageExploreBenefitTileClick(gtm_event)
}

export const gtmPortalHomepageExploreBenefitButtonClick = (
  label?: string | null
) => {
  const gtm_event = {
    cat: label,
  }

  portalHomepageExploreBenefitButtonClick(gtm_event)
}
export const gtmPortalHomepageBannerSpaceClick = () => {
  const gtm_event = {}

  portalHomepageBannerSpaceClick(gtm_event)
}

export const gtmPortalHomepageHighFiveBannerClick = () => {
  const gtm_event = {}

  portalHomepageHighFiveBannerClick(gtm_event)
}

export const gtmPortalHomepageExploreCycleToWorkButtonClick = () => {
  const gtm_event = {}

  portalHomepageExploreCycleToWorkButtonClick(gtm_event)
}
export const gtmPortalHomepageExploreCarBenefitButtonClick = () => {
  const gtm_event = {}

  portalHomepageExploreCarBenefitButtonClick(gtm_event)
}

export const gtmPortalHomepageExploreBenefitRequestButtonClick = (
  label?: string | null
) => {
  const gtm_event = {
    cat: label,
  }

  portalHomepageExploreBenefitRequestButtonClick(gtm_event)
}

export const gtmPortalHomepageNoticeboardButtonClick = (
  Title?: string | null
) => {
  const gtm_event = {
    event_action: Title,
  }

  portalHomepageNoticeboardButtonClick(gtm_event)
}

export const gtmCycleToWorkCalculateSavings = (
  bikeAmount,
  dropDownValue,
  annualSalary
) => {
  const gtm_event = {
    spend_amount: bikeAmount,
    annual_salary: annualSalary,
    payment_term: dropDownValue == 1 ? "12 months" : "18 months",
  }

  CycleToWorkCalculateSavings(gtm_event)
}

export const gtmCycleToWorkQuotePageSteps1 = (
  BikeShop,
  TypeofBike,
  PaymentTerm,
  value,
  step
) => {
  const gtm_event = {
    bike_type: TypeofBike,
    description: descriptionOfBike,
    payment_term: PaymentTerm == 1 ? "12 months" : "18 months",
    value: value,
    bike_store_name: BikeShop,
    step: step,
  }

  CycleToWorkQuotePageSteps1(gtm_event)
}

export const gtmCycleToWorkProductClick = (productDetails) => {
  const gtm_event = {
    item_name: productDetails.title,
    item_id: productDetails.id,
    item_category3: productDetails.sku,
    price: productDetails.totalAmount,
    item_brand: productDetails.brand,
    item_category: productDetails.category,
    item_variant: productDetails.variant,
    item_category2: productDetails.department,
    store: productDetails.storeName,
    supplier: productDetails.supplierName,
    index: 0,
  }

  CycleToWorkProductClick(gtm_event)
}

export const gtmCycleToWorkAddToCart = (productDetails) => {
  const gtm_event = {
    item_name: productDetails.name || productDetails.title,
    item_id: productDetails.id,
    item_category3: productDetails.sku,
    price: productDetails.totalAmount,
    item_brand: productDetails.brand,
    item_category: productDetails.category,
    item_variant: productDetails.variant,
    item_category2: productDetails.department,
    index: 0,
    store: productDetails.storeName,
    supplier: productDetails.supplierName,
  }

  CycleToWorkAddToCart(gtm_event)
}
export const gtmCycleToWorkProductRemove = (product) => {
  const gtm_event = {
    item_name: product.name,
    item_id: product.productId,
    item_category3: product.sku,
    price: product.totalAmount,
    item_brand: product.brand,
    item_category: product.category,
    item_variant: product.variant,
    item_category2: product.department,
    store: product.storeName,
    supplier: product.supplierName,
    index: 0,
  }

  CycleToWorkProductRemove(gtm_event)
}
export const gtmstoreFilterDepartment = (obj: {
  filterType: string
  selectedOption: string
  activeCategory: string
  store: string | undefined
}) => {
  const gtm_event = {
    selectedOption: obj.selectedOption,
    filterType: obj.filterType,
    categoryType: obj.activeCategory,
    store: obj.store,
  }

  storeFilterDepartment(gtm_event)
}

export const gtmProductStoreView = (obj: {
  id
  title
  totalAmount
  brand
  category
  variant
  sku
  storeName
  department
  supplierName
}) => {
  const gtm_event = {
    item_name: obj.title,
    item_id: obj.id,
    item_category3: obj.sku,
    price: obj.totalAmount,
    item_brand: obj.brand,
    item_category: obj.category,
    item_variant: obj.variant,
    item_category2: obj.department,
    store: obj.storeName,
    supplier: obj.supplierName,
    index: 0,
  }

  storeView(gtm_event)
}

export const gtmCycleToWorkBeginCheckout = (storeName, products) => {
  CycleToWorkBeginCheckout({
    products: products,
    store: storeName,
    supplier: findUniqueSupplierName(products),
  })
}

export const gtmCycleToWorkAddShippingInfo = (
  storeName,
  products,
  supplierName
) => {
  CycleToWorkAddShippingInfo({
    products: products,
    store: storeName,
    supplier: supplierName,
  })
}

export const gtmCycleToWorkEcommSuccess = (
  orderId: string,
  storeName: string,
  supplier: string,
  revenue: string,
  products
) => {
  const gtm_products = []

  products.forEach((product) => {
    gtm_products.push({
      ...gtmProductObj(product),
      name: product.name,
      price: product.price,
      quantity: product.quantity,
    })
  })

  CycleToWorkEcommSuccess({
    store: storeName,
    "transaction-id": orderId,
    revenue: revenue,
    affiliation: "Vivup Online",
    tax: "0.00",
    shipping: "0.00",
    products: gtm_products,
    supplier: supplier,
    discount: "0",
  })
}

export const gtmCycleToWorkProductImpressions = (storeName, products) => {
  CycleToWorkProductImpressions({
    store: storeName,
    products: products,
    supplier: findUniqueSupplierName(products),
  })
}

function findUniqueSupplierName(products) {
  const uniqueSupplier = products[0].supplierName

  for (const product of products) {
    if (product.supplierName !== uniqueSupplier) {
      return undefined
    }
  }

  return uniqueSupplier
}
export const gtmPlacedStore = (store: string | undefined) => {
  const gtm_event = {
    store: store,
  }

  placedStore(gtm_event)
}

export const gtmInstantSearch = (event: string | undefined) => {
  const gtm_event = {
    event: event,
  }
  InstantSearch(gtm_event)
}
export const gtmPortalHomepageHealthAndWellbeingLinkClick = (
  label?: string | null,
  url: string | undefined | null,
  clickLocation: string | undefined
) => {
  const gtm_event = {
    label: label,
    url: url,
    clickLocation: clickLocation,
  }

  portalHomepageHealthAndWellbeingLinkClick(gtm_event)
}

export const gtmOnPageland = (store?: string) => {
  const gtm_event = {
    store: store,
  }

  onPageland(gtm_event)
}
