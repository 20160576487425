import React, { useEffect, useState } from "react"
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  styled,
  css,
} from "@mui/material"
import { Close } from "@mui/icons-material"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import { CloseWindowButton } from "../../shared/affordability-calculator/Modal"
import SecondaryPopUp from "./SecondaryPopUp"
import { InputAdornment } from "@mui/material"
import { useStoreHomeData } from "../../store/StoreHomeContext"
import DropDownField from "./DropDownField"
import { BoxProps } from "@mui/material"
import { BrochureGroup as BrochureGroupType } from "../../../graphqGenaretedTypes"
import { toNumber } from "lodash"
import { gtmCycleToWorkCalculateSavings } from "../../shared/gtm-events/CustomGtmEvents"
import useTriggerSavingCalculationsCall from "../../cycle-to-work/quote-page/useTriggerSavingCalculationsCall"
import { useTranslation } from "react-i18next"

const currencyInputProps = {
  startAdornment: (
    <InputAdornment
      position="start"
      sx={{
        marginRight: "0.25rem",
        color: "#666666",
      }}
      disableTypography
    >
      £
    </InputAdornment>
  ),
}
export type BenefitCardModalProps = {
  openModal: boolean
  handleClose(): void
}
type MainContainerProps = BoxProps & {
  data: {
    brochureGroups: BrochureGroupType[]
  }
}
const CalculateSavingsModal = ({
  openModal,
  handleClose,
}: BenefitCardModalProps) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const [openSecondModal, setOpenSecondModal] = useState(false)
  const { data } = useStoreHomeData()

  const [, setIsDisabled] = useState(true)
  const [bikeAmount, setBikeAmount] = useState("")
  const [validBikeAmount, setValidBikeAmount] = useState("")
  const [annualSalary, setAnnualSalary] = useState("")
  const { t, ready } = useTranslation("cycleToWork")
  const defaultDropDownValue =
    data.brochureGroups.length === 1 ? data.brochureGroups[0].id : ""

  const [dropDownValue, setDropDownValue] = useState(defaultDropDownValue)

  useEffect(() => {
    // to make sure dropDownValue is updated according to the brochureGroupId in the store page
    setDropDownValue(defaultDropDownValue)
  }, [defaultDropDownValue])

  const [validannualSalary, setValidAnnualSalary] = useState("")
  const [dropDownError, setDropDownError] = useState("")
  const isDisable = !bikeAmount || !annualSalary || !dropDownValue
  const [triggerCalculation, setTriggerCalculation] = useState(false)

  const { quoteType, calculatedResult } = useTriggerSavingCalculationsCall({
    costOfQuote: bikeAmount,
    brochureGroupId: dropDownValue,
    employeeSalary: annualSalary,
    triggerCalculation,
  })

  useEffect(() => {
    if (calculatedResult) {
      setOpenSecondModal(true)
    }
  }, [calculatedResult])

  const handleBikeAmountInput = (event: React.SyntheticEvent) => {
    const value = (event.target as HTMLInputElement).value
    if (/^\d*\.?\d*$/.test(value)) {
      setBikeAmount(value)
    }
  }

  const handleAnnualSalaryInput = (event: React.SyntheticEvent) => {
    const value = (event.target as HTMLInputElement).value
    if (/^\d*\.?\d*$/.test(value)) {
      setAnnualSalary(value)
    }
  }

  const dropDownHandler = (event: React.MouseEvent) => {
    const value = (event.target as HTMLInputElement).value
    setDropDownValue(value)
  }

  const handleOpenModal = (event: { preventDefault: () => void }) => {
    let isValid = true
    const bikeAmountNum = toNumber(bikeAmount)
    const annualSalaryNum = toNumber(annualSalary)
    if (bikeAmountNum <= 0 || bikeAmountNum < 1) {
      setValidBikeAmount(t("calculator.errors.validValue"))
      isValid = false
    } else {
      setValidBikeAmount("")
    }
    if (bikeAmountNum > data.quoteLimit) {
      isValid = false
      //Need to fix this
      setValidBikeAmount(t("landing.maxOrderLimit") + data.quoteLimit)
    }

    if (annualSalaryNum <= 0 || annualSalaryNum < 1) {
      setValidAnnualSalary(t("calculator.errors.validValue"))
      isValid = false
    } else {
      setValidAnnualSalary("")
    }

    if (
      !/^[0-9]\d*(\.\d{1,2})?$/.test(bikeAmount) ||
      bikeAmountNum > 99999.99
    ) {
      isValid = false
      event.preventDefault()
      if (!/^[0-9]/.test(bikeAmount)) {
        setValidBikeAmount(t("calculator.errors.enterValueWithDigit"))
      } else if (bikeAmountNum > 99999.99) {
        setValidBikeAmount(t("calculator.errors.maxAmount"))
      } else {
        setValidBikeAmount(t("calculator.errors.twoDecimalPlace"))
      }
    }

    if (
      !/^[0-9]\d*(\.\d{1,2})?$/.test(annualSalary) ||
      annualSalaryNum > 9999999.99
    ) {
      isValid = false
      event.preventDefault()
      if (!/^[0-9]/.test(annualSalary)) {
        setValidAnnualSalary(t("calculator.errors.enterValueWithDigit"))
      } else if (annualSalaryNum > 9999999.99) {
        setValidAnnualSalary(t("calculator.errors.maxAmount"))
      } else {
        setValidAnnualSalary(t("calculator.errors.twoDecimalPlace"))
      }
    }

    if (!dropDownValue) {
      setDropDownError(t("calculator.errors.selectValue"))
      return
    }

    if (bikeAmountNum >= annualSalaryNum) {
      setValidBikeAmount(t("calculator.errors.spendLessThanSalary"))
      return
    }

    if (isValid === true) {
      setValidBikeAmount("")
      setValidAnnualSalary("")
      event.preventDefault()
      setIsDisabled(true)
      setTriggerCalculation(true)
      setDropDownError("")
      gtmCycleToWorkCalculateSavings(bikeAmount, dropDownValue, annualSalary)
    }
  }

  const handleCloseModal = (recalculate = true) => {
    setOpenSecondModal(false)
    setTriggerCalculation(false)

    if (recalculate) {
      setAnnualSalary("")
      setBikeAmount("")
      setDropDownValue(defaultDropDownValue)
    }

    setValidBikeAmount("")
    setValidAnnualSalary("")
    setDropDownError("")
  }

  const handleModalClose = () => {
    handleClose()
    setIsDisabled(true)
  }

  if (!ready) {
    return null
  }
  return (
    <>
      <Modal
        open={openModal}
        onClose={handleModalClose}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "0px !important",
        }}
      >
        <MainContainer
          sx={{ maxHeight: "97vh" }}
          data={{
            brochureGroups: data.brochureGroups,
          }}
        >
          <IconContainer>
            <Button
              aria-label="Close"
              sx={{
                marginRight: isDesktop ? "1rem" : "",
                marginTop: "0.5rem",
              }}
              onClick={handleModalClose}
            >
              <Close
                style={{
                  fontSize: "1.515rem",
                  color: "#33235A",
                }}
              />
            </Button>
          </IconContainer>

          <TextContainerBox>
            <MainText>{t("calculator.designText.mainHeader")}</MainText>
          </TextContainerBox>
          <TextContainerBox>
            <SecondaryText>
              {t("calculator.designText.subHeader")}
            </SecondaryText>
          </TextContainerBox>
          <ParagraphStyle as={"div"}>
            <>
              <div
                style={{
                  paddingBottom: isDesktop ? "15px" : "8px",
                  color: `${theme.palette.primary.main}`,
                  fontSize: isDesktop ? "1rem" : "0.875rem",
                  fontWeight: "bold",
                }}
              >
                {t("calculator.designText.amountToSpend")}
              </div>
              <div>
                <label htmlFor="amount-spend" style={{ display: "none" }}>
                  {t("calculator.designText.bikeAmountLabel")}
                </label>
                <InputField
                  id="amount-spend"
                  InputProps={currencyInputProps}
                  placeholder={t("calculator.designText.bikeAmountPlaceholder")}
                  onChange={handleBikeAmountInput}
                  value={bikeAmount}
                />
              </div>

              <div style={{ color: "#c5291c" }}>
                {validBikeAmount && validBikeAmount}
              </div>

              <div
                style={{
                  paddingBottom: isDesktop ? "15px" : "8px",
                  color: `${theme.palette.primary.main}`,
                  fontSize: isDesktop ? "1rem" : "0.875rem",
                  fontWeight: "bold",
                  marginTop: isDesktop ? "1.875rem" : "1.3125rem",
                }}
              >
                {t("calculator.designText.amountYouEarn")}
              </div>
              <div>
                <label htmlFor="amount-earn" style={{ display: "none" }}>
                  {t("calculator.designText.annualSalaryLabel")}
                </label>
                <InputField
                  id="amount-earn"
                  InputProps={currencyInputProps}
                  placeholder={t("calculator.designText.salaryPlaceholder")}
                  onChange={handleAnnualSalaryInput}
                  value={annualSalary}
                />
              </div>

              <div style={{ color: "#c5291c" }}>
                {validannualSalary && validannualSalary}
              </div>

              {data.brochureGroups.length > 1 ? (
                <>
                  <div
                    style={{
                      paddingBottom: "3px",
                      color: `${theme.palette.primary.main}`,
                      fontSize: isDesktop ? "1rem" : "0.875rem",
                      fontWeight: "bold",
                      marginTop: isDesktop ? "1.875rem" : "1.3125rem",
                    }}
                  >
                    {t("calculator.designText.spreadTheCost")}
                  </div>
                  <div>
                    <DropDownField
                      dropDownValue={dropDownValue}
                      dropDownHandler={dropDownHandler}
                      data={data.brochureGroups}
                    />
                  </div>

                  <div style={{ color: "#c5291c" }}>{dropDownError}</div>
                </>
              ) : (
                ""
              )}
            </>
          </ParagraphStyle>

          <ExploreButton
            onClick={handleOpenModal}
            style={{ marginBottom: isDesktop ? "0.5rem" : "" }}
            disabled={isDisable}
            data={{
              brochureGroups: data.brochureGroups,
            }}
          >
            {t("calculator.designText.calculateButton")}
          </ExploreButton>

          <CloseButtonContainer>
            <CloseButton variant="invisible" onClick={handleClose}>
              {t("calculator.designText.closeWindow")}
            </CloseButton>
          </CloseButtonContainer>
        </MainContainer>
      </Modal>
      <SecondaryPopUp
        handleModalClose={handleModalClose}
        open={openSecondModal}
        handleCloseModal={handleCloseModal}
        data={calculatedResult}
        quoteType={quoteType}
      />
    </>
  )
}
export default CalculateSavingsModal

export const InputField = styled(TextField)`
  ${({ theme }) => css`
    && {
      width: 482px;
      height: 56px;
      border: 1px solid ${theme.palette.grey[200]};
      appearance: none;
      input {
        ::placeholder {
          color: #666666;
          opacity: 1;
          font-size: 17px;
        }
      }
      input[type="text"] {
        inputmode: "numeric";
        pattern: "[0-9]*";
      }
      .MuiInputLabel-root {
        display: none;
        shrink: false;
      }
      input[type="number"] {
        -moz-appearance: textfield;
      }

      /* Add this */
      &:hover .MuiInputBase-input,
      &:focus-within .MuiInputBase-input {
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        &:-moz-focusring {
          color: transparent;
          text-shadow: 0 0 0 #000;
        }
        -moz-appearance: textfield;
      }

      @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
        width: 287px;
        height: 56px;
      }
    }
  `}
`
export const ExploreButton = styled(Button)<MainContainerProps>(
  ({ theme, ...props }) => css`
    && {
      display: block;
      width: 13.5rem;
      height: 3.125rem;
      margin: 2rem auto;
      margin-top: 1rem;
      margin-bottom: 2.188rem;
      color: ${theme.palette.white.main};
      font-size: 17px;
      line-height: ${theme.typography.body2.lineHeight};
      text-align: center;
      border-radius: 2.625rem;
      box-shadow: ${theme.boxShadow};
      opacity: ${props.disabled ? "0.5" : "1"};
      background: transparent
        linear-gradient(90deg, #92237f 0%, ${theme.palette.primary.main} 100%)
        0% 0% no-repeat padding-box;
      margin-bottom: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
        width: 287px;
        height: 50px;
        font-size: 17px;
        margin-top: ${props.data.brochureGroups.length > 1 ? "35px" : ""};
        margin-bottom: 0rem;
      }

      &:hover {
        opacity: 75%;
      }
    }
  `
)

export const CloseButton = styled(CloseWindowButton)`
  ${({ theme }) => css`
    && {
      display: flex;
      justify-content: center;
      text-align: "center";
      margin-bottom: 1rem;
      font-size: 0.75rem;
      color: #666666;
      @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
        padding-top: 0rem;
        margin-bottom: 1rem;
      }
    }
  `}
`
export const SecondaryText = styled(Box)`
  ${({ theme }) => css`
    font-size: 1rem;
    text-align: center;
    color: #35185f;
    padding-top: 1rem;
    padding-bottom: 0rem;
    padding-left: 5rem;
    padding-right: 5rem;
    @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
      padding-left: 0rem;
      padding-right: 0rem;
      font-size: 14px;
      text-align: center;
      padding-top: 14px;
    }
  `}
`
export const MainContainer = styled(Box)<MainContainerProps>(
  ({ theme, ...props }) => css`
    && {
      border-radius: 0.938rem;
      background-color: white;
      width: 34.188rem;
      max-height: ${props.data.brochureGroups.length > 1
        ? "47.063rem"
        : "39.125rem"};

      @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
        width: 21.875rem;
        max-height: ${props.data.brochureGroups.length > 1
          ? "44.375rem"
          : "36.75rem"};
      }
    }
  `
)

export const IconContainer = styled(Box)`
  && {
    display: flex;
    justify-content: right;
  }
`
export const TextContainerBox = styled(Box)`
  && {
    text-align: center;
  }
`
export const MainText = styled(Box)`
  ${({ theme }) => css`
    font-size: 1.5rem;
    color: ${theme.palette.primary.main};
    font-family: ${theme.typography.fontFamily};
    text-align: center;
    font-weight: bold;
    @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
      padding-bottom: 0rem;
      font-size: 16px;
    }
  `}
`
export const CloseButtonContainer = styled(Box)`
  ${({ theme }) => css`
    && {
      display: flex;
      justify-content: center;
      align-content: center;
      padding-top: 1rem;
      @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
        padding-top: 15px;
      }
    }
  `}
`
const ParagraphStyle = styled(Typography)`
  ${({ theme }) => css`
    color: #2f1754;
    padding: 1.3rem;
    padding-top: 1.6875rem;
    &::-webkit-scrollbar {
      display: none;
    }
    @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
      padding-top: 2.4375rem;
      margin-bottom: 0rem;
      padding-bottom: 0rem;
      padding-right: 2rem;
      padding-left: 2rem;
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  `}
`
