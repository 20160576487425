/* eslint class-methods-use-this: 0 */
import {
  Routes,
  Route,
  BrowserRouter,
  useLocation,
  useSearchParams,
} from "react-router-dom"
import { Store } from "./store/Store"
import { StoreHome } from "./store/StoreHome"
import Department from "./store/department-landing/Department"
import Basket from "./store/basket-page/Basket"
import Checkout from "./store/checkout-page/Checkout"
import { Product } from "./store/product-page/Product"
import { StoreLayout } from "./layout/store/StoreLayout"
import { LSStoreLayout } from "./layout/lifestyle-savings/LSStoreLayout"
import { CheckoutStoreLayout } from "./layout/store/CheckoutStoreLayout"
import { GlobalLayout } from "./layout/GlobalLayout"
import NotFound from "./NotFound"
import { useLayoutEffect, useState } from "react"
import { Store as LSStore } from "./lifestyle-savings/Store"
import { LocalBenefitStore } from "./lifestyle-savings/local-benefit-store/LocalBenefitStore"
import PortalHomePage from "./portal-homepage/PortalHomepage"
import LifestyleHomepage from "./lifestyle-savings/homepage/homepage"
import QuotePage from "./cycle-to-work/quote-page/QuotePage"
import FullListOfBikeShops from "./cycle-to-work/bike-shop-page/FullListOfBikeShops"
import FindABikeShop from "./cycle-to-work/bike-shop-page/find-a-bike-shop/FindABikeShop"
import { useGetAlertDataQuery } from "../graphqGenaretedTypes"
import { Alert as AlertType } from "../graphqGenaretedTypes"
import Alert from "./shared/Alert"
import Cookie from "./cookie/Cookie"
import CookieList from "./pages/cookie-list/CookieList"
import CookiePolicy from "./pages/CookiePolicy"
import { StaticLayout } from "./layout/StaticLayout"
import AccessibleNavigationAnnouncer from "./AccessibleNavigationAnnouncer"
import FamilyPay from "./benefit-landing-pages/FamilyPay"
import Analytics from "./analytics/Analytics"
import FamilyCareLanding from "./benefit-landing-pages/FamilyCareLanding"
import PrivacyNotice from "./pages/privacy-notice/PrivacyNotice"
import SignUp from "./registration/sign-up/SignUp"
import SignIn from "./registration/sign-in/SignIn"
import RegConfirm from "./registration/reg-confirm/RegConfirm"
import RegValidation from "./registration/reg-validation/RegValidation"
import RegQuestions from "./registration/reg-validation/RegQuestions"
import RegThankYou from "./registration/sign-up/RegThankYou"
import ThankYouForSubmission from "./registration/ThankYouForSubmission"
import { useTranslation } from "react-i18next"
import isTranslatedPath from "../utils/isTranslatedPath"
import ChangeYourPassword from "./registration/ChangeYourPassword"
import RenewPassword from "./registration/RenewPassword"
import { RegistrationLayout } from "./registration/RegistrationLayout"

const Wrapper = ({ children }: { children: JSX.Element }) => {
  const { i18n } = useTranslation()
  const { refetch } = useGetAlertDataQuery({
    variables: { locale: i18n.language },
    errorPolicy: "all",
  })
  const [alerts, setAlerts] = useState<AlertType[]>([])
  const location = useLocation()
  const [searchParams] = useSearchParams()

  useLayoutEffect(() => {
    document.documentElement.scrollTop = 0

    if (isTranslatedPath(location.pathname)) {
      i18n.changeLanguage(localStorage.getItem("prefLng") || "en-GB")
    } else {
      i18n.changeLanguage("en-GB")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  useLayoutEffect(() => {
    refetch().then((queryData) => {
      const freshAlerts = queryData.data?.alerts

      if (freshAlerts) {
        setAlerts(freshAlerts)
      }
    })
  }, [location.pathname, refetch, searchParams])

  useLayoutEffect(() => {
    document.documentElement.focus()
  }, [location.pathname, searchParams])

  return (
    <>
      {alerts.map((alert) => (
        <Alert
          severity={alert.severity}
          message={alert.message || ""}
          key={location.pathname + alert.message + alert.severity}
        />
      ))}
      {children}
    </>
  )
}

export default function Main() {
  const [shouldShowCookiePopup, setShouldShowCookiePopup] = useState(false)
  return (
    <>
      <a id="skip-link" href="#main-content">
        Skip to content
      </a>
      <BrowserRouter>
        <AccessibleNavigationAnnouncer />
        <Wrapper>
          <>
            <Cookie
              shouldShowCookiePopup={shouldShowCookiePopup}
              setShouldShowCookiePopup={setShouldShowCookiePopup}
            />
            <Routes>
              <Route path="/organisations">
                <Route path=":organisationId">
                  <Route path="employee">
                    <Route
                      path="lifestyle_savings"
                      element={
                        <LSStoreLayout
                          setShouldShowCookiePopup={setShouldShowCookiePopup}
                        />
                      }
                    >
                      <Route path="landing" element={<LifestyleHomepage />} />
                      <Route path="products" element={<LSStore />} />
                      <Route
                        path="local_benefits"
                        element={<LocalBenefitStore />}
                      />
                    </Route>
                    <Route
                      path="benefits"
                      element={
                        <StoreLayout
                          setShouldShowCookiePopup={setShouldShowCookiePopup}
                        />
                      }
                    >
                      <Route path=":schemeType">
                        <Route path="landing" element={<StoreHome />} />
                        <Route
                          path="enter_bike_quote"
                          element={<QuotePage />}
                        />
                        <Route
                          path="find_a_bike_shop/full_list_of_bike_shops"
                          element={<FullListOfBikeShops />}
                        />
                        <Route
                          path="find_a_bike_shop"
                          element={<FindABikeShop />}
                        />

                        <Route path="departments">
                          <Route path=":id" element={<Department />} />
                        </Route>
                        <Route path="products" element={<Store />} />
                        <Route path="products">
                          <Route path=":id" element={<Product />} />
                        </Route>
                        <Route path="basket" element={<Basket />} />
                      </Route>
                    </Route>
                    <Route
                      path="benefits"
                      element={
                        <CheckoutStoreLayout
                          setShouldShowCookiePopup={setShouldShowCookiePopup}
                        />
                      }
                    >
                      <Route path=":schemeType">
                        <Route path="checkout" element={<Checkout />} />
                      </Route>
                    </Route>
                  </Route>
                  <Route
                    path="employee"
                    element={
                      <GlobalLayout
                        setShouldShowCookiePopup={setShouldShowCookiePopup}
                      />
                    }
                  >
                    <Route path="dashboard" element={<PortalHomePage />} />
                    <Route path="benefits">
                      <Route path="family_pay" element={<FamilyPay />} />
                      <Route
                        path="family_care"
                        element={<FamilyCareLanding />}
                      />
                    </Route>
                  </Route>
                  <Route
                    path=""
                    element={
                      <StaticLayout
                        setShouldShowCookiePopup={setShouldShowCookiePopup}
                      />
                    }
                  >
                    <Route path="privacy_notice" element={<PrivacyNotice />} />
                    <Route path="analytics" element={<Analytics />} />
                  </Route>
                </Route>
              </Route>
              <Route
                path=""
                element={
                  <StaticLayout
                    setShouldShowCookiePopup={setShouldShowCookiePopup}
                  />
                }
              >
                <Route path="cookie_list" element={<CookieList />} />
                <Route path="cookie_policy" element={<CookiePolicy />} />
              </Route>
              <Route
                path="users"
                element={
                  <RegistrationLayout
                    setShouldShowCookiePopup={setShouldShowCookiePopup}
                  />
                }
              >
                <Route path="sign_up" element={<SignUp />} />
                <Route path="sign_in" element={<SignIn />} />
                <Route path="invitation/accept" element={<RegConfirm />} />
                <Route path=":user_id/validation_numbers">
                  <Route path="new" element={<RegValidation />} />
                  <Route
                    path=":validation_number_id/request_review"
                    element={<RegQuestions />}
                  />
                  <Route
                    path="approval_required"
                    element={<ThankYouForSubmission />}
                  />
                </Route>
                <Route path="confirmation_required" element={<RegThankYou />} />
                <Route path="password/edit" element={<ChangeYourPassword />} />
                <Route path="password_expired" element={<RenewPassword />} />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </>
        </Wrapper>
      </BrowserRouter>
    </>
  )
}
