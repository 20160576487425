import { Box, Chip, Typography, css, styled } from "@mui/material"
import { CategoriesType } from "./getTop5Category"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"

export const Categories = ({
  categories,
  setIsWidgetOpen,
  query,
  hitsLength,
  handleKeyDown,
  listRef,
}: {
  categories: CategoriesType[] | undefined
  setIsWidgetOpen: React.Dispatch<React.SetStateAction<boolean>>
  query: string | undefined
  hitsLength: number
  handleKeyDown: (e: React.KeyboardEvent<EventTarget>) => void
  listRef: React.MutableRefObject<HTMLElement[]>
}) => {
  const { organisationId, schemeType } = useParams()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const brochureGroup = searchParams.get("bg")

  const handleClick = (link: string) => {
    navigate(link)
    setIsWidgetOpen(false)
  }

  function escapeRegExp(string: string) {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&")
  }

  function highlightMatch(category: string, inputStr: string): string {
    const words = inputStr
      .split(" ")
      .map((word) => word.trim())
      .filter((word) => word.length > 0)

    const escapedWords = words.map(escapeRegExp).join("|")
    const regex = new RegExp(`(${escapedWords})`, "gi")

    return category.replace(regex, "<mark>$1</mark>")
  }

  if (categories) {
    return (
      <CategoryContainer>
        <CategoryHeader variant="h3">Related Categories</CategoryHeader>
        {categories.map(({ category, category_id, department_id }, i) => {
          const link = `/organisations/${organisationId}/employee/benefits/${schemeType}/products?categories=${category_id}&departments=${department_id}${
            brochureGroup ? `&bg=${brochureGroup}` : ""
          }`

          const highlightedCategory = query
            ? highlightMatch(category, query)
            : category

          const refIndex = i + 1 + hitsLength

          return (
            <StyledChip
              data-element="search"
              key={`recommended-category-${category}`}
              label={
                <div
                  dangerouslySetInnerHTML={{ __html: highlightedCategory }}
                />
              }
              variant="outlined"
              clickable
              onClick={() => handleClick(link)}
              tabIndex={-1}
              ref={(el) => {
                if (el) {
                  listRef.current[refIndex] = el
                }
              }}
              onKeyDown={handleKeyDown}
            />
          )
        })}
      </CategoryContainer>
    )
  }
  return null
}
const CategoryContainer = styled(Box)`
  padding-left: 1.3125rem;

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      margin-top: 43px;
      padding-left: 34px;
    }
  `}
`
const CategoryHeader = styled(Typography)`
  font-size: 0.875rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.palette.grey[700]};
  margin-bottom: 1rem;
`
const StyledChip = styled(Chip)`
  margin: 10px;
  margin-left: 0;

  .MuiChip-label {
    line-height: 1rem;
  }

  mark {
    color: ${({ theme }) => theme.palette.primary.main};
    background-color: rgba(39, 146, 191, 0.34);
  }
`
