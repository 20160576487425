import { FamilyCare as FamilyCareType } from "../../../../graphqGenaretedTypes"
import {
  MobileWhiteBox,
  MobileGridContainer,
  OneProposition,
} from "../../shared/SharedStyles"
import useIsMobileView from "../../../../utils/useIsMobileView"
import { styled } from "@mui/material"
import { Content } from "./Content"
import ExpandableList from "../ExpandableList"

const FamilyCare = ({ data }: { data: FamilyCareType | undefined }) => {
  const isMobile = useIsMobileView()

  if (isMobile) {
    return (
      <MobileWhiteBox>
        <MobileGrid>
          <Content data={data} />
        </MobileGrid>
      </MobileWhiteBox>
    )
  }
  return (
    <div>
      <OneProposition>
        <Content data={data} />
      </OneProposition>
      {data?.usefulLinks && (
        <ExpandableList links={data.usefulLinks} moreButtonColor="#6A6BA8" />
      )}
    </div>
  )
}

export default FamilyCare

const MobileGrid = styled(MobileGridContainer)`
  grid-template-rows: auto 264px auto;
`
