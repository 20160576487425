import {
  Button,
  Box,
  Modal,
  Stack,
  styled,
  Tooltip,
  IconButton,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded"
import CloseIcon from "@mui/icons-material/Close"
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded"
import { useState } from "react"
import { useIncrementClickCountMutation } from "../../../graphqGenaretedTypes"
import { gtmLifestyleDiscountCodeCopyClick } from "../../shared/gtm-events/CustomGtmEvents"
import { gtmLifestylePopupButtonClick } from "../../shared/gtm-events/CustomGtmEvents"
import { useTranslation } from "react-i18next"

export type CardPopUpProps = {
  id?: string
  title?: string
  description?: string
  savingText?: string | boolean
  discountCode?: string
  link?: string
  open: boolean
  setOpen: (state: boolean) => void
  disableClickCount?: boolean
  index: number
  savingType?: string
  value?: number | string
  name?: string
  categories?: string
  indexCarousel: number
  parseAmount: number | string
}

export default function CardPopUp({
  id,
  title,
  description,
  savingText,
  savingType,
  discountCode,
  link,
  open,
  name,
  setOpen,
  index,
  disableClickCount = false,
  categories,
  parseAmount,
}: CardPopUpProps) {
  const { t, ready } = useTranslation("lifestyleSavings")

  const [copiedToClipBoard, setCopiedToClipboard] = useState(false)

  const handleClose = () => {
    setOpen(false)

    gtmLifestylePopupButtonClick(
      categories,
      index + 1,
      name || title,
      id,
      parseAmount,
      savingType
    )
  }

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))

  const copyToClipboard = async (discountCode: string) => {
    await navigator.clipboard.writeText(discountCode)
  }

  const copyToClipboardOldBrowsers = (discountCode: string) => {
    // need to create a temp element in DOM in order to copy the text to clipboard
    const tempElement = document.createElement("textarea")
    tempElement.value = discountCode
    tempElement.setAttribute("readonly", "")
    tempElement.style.position = "absolute"
    tempElement.style.left = "-9999px"
    document.body.appendChild(tempElement)
    tempElement.select()
    tempElement.setSelectionRange(0, 99999)
    document.execCommand("copy")
    document.body.removeChild(tempElement)
  }

  const handleCopy = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    if ("clipboard" in navigator && discountCode) {
      // for chrome, macOS safari and firefox
      copyToClipboard(discountCode)
    } else if (discountCode) {
      // for iOS Sarafi and IE11
      copyToClipboardOldBrowsers(discountCode)
    }
    setCopiedToClipboard(true)
    // discount code tooltip just shows 5 sec
    setTimeout(() => setCopiedToClipboard(false), 5000)

    gtmLifestyleDiscountCodeCopyClick(
      discountCode,
      id,
      savingType,
      parseAmount,
      name || title,
      categories,
      index + 1
    )
  }

  const handleClick = () => {
    if (!disableClickCount) {
      incrementClickCountMutation()
    }
    window.open(link, "_blank", "noopener,noreferrer")
    gtmLifestylePopupButtonClick(
      categories,
      index + 1,
      name || title,
      id,
      parseAmount,
      savingType
    )
  }

  const [incrementClickCountMutation] = useIncrementClickCountMutation({
    variables: {
      discountId: parseInt(id),
    },
  })

  if (!ready) {
    return null
  }
  return (
    <Modal
      open={open}
      sx={{ borderRadius: "0.9375rem" }}
      onClose={() => setOpen(false)}
      role="dialog"
      aria-labelledby="dialog_label"
      aria-modal="true"
    >
      <PopupBox
        sx={{
          width: isDesktop ? "53rem" : "80%",
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        <Box>
          <h3 id="dialog_label">
            <span className="screen-reader-only">
              {t("landingPage.popUp.screenReader.opened")}
            </span>
            {title}
            <span className="screen-reader-only">
              {t("landingPage.popUp.screenReader.popUp")}
            </span>
          </h3>
          <DescriptionBox>
            <p className="color-primary">{description}</p>
          </DescriptionBox>

          {discountCode ? (
            <DiscountCodeBox className="content-center">
              <h3 className="color-secondary">{discountCode}</h3>
              <CopyIcon
                onClick={handleCopy}
                title={t("landingPage.popUp.buttons.discountCode")}
              >
                <div title={t("landingPage.popUp.buttons.copy")}>
                  {copiedToClipBoard ? (
                    <>
                      <Tooltip
                        title={t("landingPage.popUp.tooltip")}
                        open={copiedToClipBoard}
                      >
                        <CheckCircleOutlineRoundedIcon />
                      </Tooltip>
                    </>
                  ) : (
                    <ContentCopyRoundedIcon />
                  )}
                </div>
              </CopyIcon>
            </DiscountCodeBox>
          ) : (
            savingText && <h3 className="color-secondary">{savingText}</h3>
          )}

          <Stack
            direction={isDesktop ? "row-reverse" : "column-reverse"}
            justifyContent="center"
            alignItems="center"
            spacing={3}
            sx={{
              marginTop: "2.75rem",
            }}
          >
            <Button
              onClick={handleClick}
              sx={{ width: isDesktop ? "17.5rem" : "100%" }}
              variant="gradient"
            >
              {t("landingPage.popUp.buttons.continue")}
              <span className="screen-reader-only">
                {t("landingPage.popUp.screenReader.newTab")}
              </span>
            </Button>
            <Button
              variant="gray"
              sx={{ width: isDesktop ? "17.5rem" : "100%" }}
              onClick={handleClose}
            >
              {t("landingPage.popUp.buttons.keepShopping")}
            </Button>
          </Stack>
        </Box>
        <Button
          onClick={() => setOpen(false)}
          sx={{ alignSelf: "end" }}
          title={t("landingPage.popUp.buttons.closePopUp")}
        >
          <CloseIcon />
          <div className="screen-reader-only">Close</div>
        </Button>
      </PopupBox>
    </Modal>
  )
}

const PopupBox = styled(Box)`
  position: absolute;
  outline: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.palette.white.main};
  z-index: 9999;
  overflow: auto;
  border-radius: ${({ theme }) => theme.boxBorderRadius};
  padding: 1.25rem;
  text-align: center;
  display: flex;
  flex-direction: column;
`

const DescriptionBox = styled(Box)`
  max-width: 30rem;
  margin: auto;
`

const DiscountCodeBox = styled(Box)`
  display: flex;
`

const CopyIcon = styled(IconButton)`
  color: ${({ theme }) => theme.palette.secondary.main};
  padding-left: 0.5rem;
  cursor: pointer;
`
