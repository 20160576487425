import { Box, css, styled } from "@mui/material"
import { CustomHits } from "./CustomHits"
import { useEffect } from "react"
import useIsMobileView from "../../../utils/useIsMobileView"
import algoliasearchHelper from "algoliasearch-helper"
import NoResults from "../../store/NoResults"

interface HighlightResult {
  "product_templates.online_product_name"?: {
    value: string
  }
  "product_templates.name"?: {
    value: string
  }
}

export interface HitType {
  "product_templates.image_cdn_url"?: string
  _highlightResult?: HighlightResult
  objectID: string
}

type AlgoliaResultsType = {
  setIsWidgetOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleKeyDown: (e: React.KeyboardEvent<EventTarget>) => void
  listRef: React.MutableRefObject<HTMLElement[]>
  setCombinedLength: React.Dispatch<React.SetStateAction<number>>
  hits: HitType[]
  results: algoliasearchHelper.SearchResults | undefined
}

const AlgoliaResults = ({
  setIsWidgetOpen,
  handleKeyDown,
  listRef,
  setCombinedLength,
  hits,
  results,
}: AlgoliaResultsType) => {
  const isMobile = useIsMobileView()

  // disable background scroll when mobile overlay presents
  useEffect(() => {
    if (isMobile) {
      document.body.style.overflow = "hidden"
    }

    return () => {
      if (isMobile) {
        document.body.style.overflow = ""
      }
    }
  }, [isMobile])

  return (
    <Container>
      {hits.length === 0 && results?.query ? (
        <NoResultsPadding>
          <NoResults searchTerm={results?.query} />
        </NoResultsPadding>
      ) : (
        <CustomHits
          setIsWidgetOpen={setIsWidgetOpen}
          handleKeyDown={handleKeyDown}
          listRef={listRef}
          setCombinedLength={setCombinedLength}
          hits={hits}
          results={results}
        />
      )}
    </Container>
  )
}

export default AlgoliaResults

const Container = styled(Box)`
  margin: 0 auto;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  touch-action: auto;
`

const NoResultsPadding = styled(Box)`
  padding: 1.5rem 1rem;
  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      padding: 2.625rem 2.5rem;
    }
  `}
`
