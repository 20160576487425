import Footer from "../footer/Footer"
import Header from "../header/Header"
import { StoreLayoutContext, useStoreLayoutData } from "./StoreLayoutContext"
import {
  StoreDepartmentContext,
  useDepartmentLayoutData,
} from "./StoreDepartmentContext"
import MegaMenu from "../../shared/MegaMenu"
import { Outlet, useParams } from "react-router"
import { StepMenuProps } from "../../shared/step-menu/StepMenu"
import BurgerMenu from "../../shared/nav/BurgerMenu"
import { ThemeProvider } from "@mui/material"
import DefaultTheme from "../../../theme/mui-theme"
import C2WTheme from "../../../theme/mui-c2w-theme"
import { useEffect, useState } from "react"
import useIsMobileView from "../../../utils/useIsMobileView"
import { useTranslation } from "react-i18next"

export type globalHeaderData = {
  supplierBanner?: {
    mobile?: string
    desktop?: string
  }
  loading?: boolean
  setShouldShowCookiePopup: React.Dispatch<React.SetStateAction<boolean>>
}

export const StoreLayout = function ({
  supplierBanner,
  setShouldShowCookiePopup,
}: globalHeaderData) {
  const contextValue = useStoreLayoutData(supplierBanner)
  const contextDepartmentValue = useDepartmentLayoutData()
  const params = useParams()

  const {
    currentUserId,
    logo,
    condensedLogo,
    privacyNoticeUrl,
    organisationPrivacyNotice,
    supportLinks,
    algoliaFilters,
  } = contextValue

  const { departments, departmentHierarchy, customNavigations } =
    contextDepartmentValue

  const { t, ready } = useTranslation("layout")

  const stepMenuProps: StepMenuProps = {
    title: t("menu.browseByDepartment"),
    itemHierarchy: departmentHierarchy ? departmentHierarchy : [],
    levels: 3,
  }

  // state for algolia widget on H&E and C2W
  const [isWidgetOpen, setIsWidgetOpen] = useState(false)

  useEffect(() => {
    const url = location.href
    let subdomain = url.slice(url.indexOf("//") + 2, url.indexOf("."))
    if (["vivup", "www"].includes(subdomain) || url.indexOf(".") === -1) {
      subdomain = "Vivup"
    }

    let sanitisedSchemeType = params.schemeType?.replaceAll("_", " ") || ""
    sanitisedSchemeType =
      sanitisedSchemeType.charAt(0).toUpperCase() + sanitisedSchemeType.slice(1)
    document.title = `${subdomain}: ${sanitisedSchemeType}`
  }, [params.schemeType])

  const isMobile = useIsMobileView()

  // disable scroll when desktop algolia search widget is shown, mobile view is scrollable
  useEffect(() => {
    const preventScroll = (e: Event) => {
      if (isWidgetOpen && !isMobile) {
        e.preventDefault()
      }
    }
    document.addEventListener("wheel", preventScroll, { passive: false })
    document.addEventListener("touchmove", preventScroll, { passive: false })

    return () => {
      document.removeEventListener("wheel", preventScroll)
      document.removeEventListener("touchmove", preventScroll)
    }
  }, [isWidgetOpen, isMobile])

  if (!ready) {
    return null
  }
  return (
    <ThemeProvider
      theme={
        ["cycle_to_work", "bike_shop"].includes(params.schemeType || "")
          ? C2WTheme
          : DefaultTheme
      }
    >
      <StoreDepartmentContext.Provider value={contextDepartmentValue}>
        <StoreLayoutContext.Provider value={contextValue}>
          {currentUserId && (
            <>
              <Header
                logo={logo}
                condensedLogo={condensedLogo}
                currentUserId={currentUserId}
                showBasket={true}
                MegaMenu={
                  <MegaMenu
                    departments={departments}
                    customNavigations={customNavigations}
                    isWidgetOpen={isWidgetOpen}
                  />
                }
                BurgerMenu={
                  <BurgerMenu
                    stepMenuProps={stepMenuProps}
                    supportLinks={supportLinks}
                  />
                }
                showNoticeboard={[]}
                backendUser={false}
                isOrganisationSettingUser={false}
                algoliaFilters={algoliaFilters}
                isWidgetOpen={isWidgetOpen}
                setIsWidgetOpen={setIsWidgetOpen}
                availableLanguages={contextValue.availableLanguages}
                defaultLanguage={contextValue.defaultLanguage}
              />
              <div
                className="store-main-container"
                style={isWidgetOpen ? { overflow: "hidden" } : undefined}
              >
                <Outlet />
              </div>
              <Footer
                organisationPrivacyNotice={organisationPrivacyNotice}
                privacyNoticeUrl={privacyNoticeUrl}
                setShouldShowCookiePopup={setShouldShowCookiePopup}
              />
            </>
          )}
        </StoreLayoutContext.Provider>
      </StoreDepartmentContext.Provider>
    </ThemeProvider>
  )
}
