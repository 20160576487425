import { useNavigate, useParams } from "react-router"
import { Container, Grid, useMediaQuery, useTheme } from "@mui/material"
import classNames from "classnames"
import { Button } from "@mui/material"
import RoundelGrid from "../../shared/RoundelGrid"
import { useContext } from "react"
import { BasketContext } from "./BasketContext"
import { useTranslation } from "react-i18next"

export default function EmptyBasket() {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const params = useParams()
  const { featuredCategories } = useContext(BasketContext)
  const { t, ready } = useTranslation("basket")
  const navigate = useNavigate()

  if (!ready) {
    return null
  }
  return (
    <>
      <Container>
        <Grid
          item
          container
          xs={12}
          lg={9.665}
          sx={{
            marginX: "auto",
          }}
        >
          <Grid item xs={12}>
            <div className="basket-container" style={{ paddingTop: 0 }}>
              <div
                className={classNames(
                  "basket-container__message",
                  isDesktop ? "body-1" : "body-2"
                )}
              >
                {t("emptyBasket.mainTitle")}
              </div>
              <Button
                variant="gradient"
                onClick={() =>
                  navigate(
                    `/organisations/${params.organisationId}/employee/benefits/${params.schemeType}/landing`
                  )
                }
                sx={{
                  width: isDesktop ? "15.25rem" : "100%",
                  marginTop: isDesktop ? "2.5rem" : "3.5rem",
                }}
              >
                {t("emptyBasket.button")}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
      {featuredCategories.length > 0 && (
        <RoundelGrid
          roundels={featuredCategories}
          background="white"
          title={t("emptyBasket.roundelTitle")}
          gtmActionName="navigation_featured_categoreis"
        />
      )}
    </>
  )
}
