import {
  Typography,
  Box,
  Button,
  Modal,
  TextField,
  Grid,
  GridProps,
  InputAdornment,
  styled,
  css,
} from "@mui/material"
import { Close } from "@mui/icons-material"
import { useState } from "react"
import InfoTooltip from "../InfoTooltip"
import { CloseWindowButton } from "../affordability-calculator/Modal"
import { MainBox } from "../affordability-calculator/Modal"
import { StoreLayoutContext } from "../../layout/store/StoreLayoutContext"
import { useContext } from "react"
import {
  PensionCalculatorFirstText,
  PensionCalculatorSecondText,
} from "./PensionCalculatorMainText"
import getQuoteText from "../../../utils/quoteTypeDymanicTextCalculator"
import {
  getNhsContributionTiers,
  getTeachersPensionContributionTiers,
  getLGPSPensionContributionTiers,
} from "../../../utils/contributionTierCalculatorHelper"
import {
  MONTHS_PER_YEAR,
  TAX_SAVING,
  NI_SAVING,
  CPI,
  NHS,
  TEACHERS_PENSION,
  PG_ABOVE_CPI,
} from "../../../data/constants"

const styledInputProps = {
  sx: {
    paddingLeft: "0.75rem",
  },
  className: "hidden-arrows",
  inputProps: {
    className: "hidden-arrows",
    sx: {
      paddingRight: "0.75rem",
      paddingY: "0.532rem",
    },
  },
}

const currencyInputProps = {
  ...styledInputProps,
  startAdornment: (
    <InputAdornment
      position="start"
      sx={{
        marginRight: "0.25rem",
        color: (theme) => theme.palette.grey[200],
      }}
      disableTypography
    >
      £
    </InputAdornment>
  ),
}
const LabelGrid = (props: GridProps) => (
  <Grid
    item
    xs={12}
    md={8}
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: {
        xs: "center",
        md: "start",
      },
      margin: "unset",
    }}
    {...props}
  />
)

export type PensionCalculatorModalProps = {
  open: boolean
  setOpen: (state: boolean) => void
  buttonPresent?: boolean
  quoteType: string
}

export default function PensionCalculatorModal({
  open,
  setOpen,
  quoteType,
}: PensionCalculatorModalProps) {
  const { weeksPerYear } = useContext(StoreLayoutContext)
  const [annualSalary, setAnnualSalary] = useState(0)
  const [salarySacrifice, setSalarySacrifice] = useState(0)
  const [totalYears, setTotalYears] = useState(0)
  let totalAnnualSalarySacrificed = 0
  let decreasedRetirementPension = 0
  let decreasedTodayPension = 0
  const TOOLTIP_CONTENT =
    "This refers to any reductions taken from your gross pay before tax, which could include your pension, Cycle to Work or previous Home & Electronics orders with Vivup."
  const TOOLTIP_CONTENT_TOTALYEARS =
    "Years until retire should not be more than 99 "

  if (totalYears <= 99) {
    calculate()
  }

  const TotalYearsHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTotalYears(Number(event.target.value))
  }
  const salarySacrificeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSalarySacrifice(Number(event.target.value))
  }

  const annualSalaryHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnnualSalary(Number(event.target.value))
  }

  function APSS(
    numYears: number,
    weeksPerYear: number,
    salary: number,
    salarySacrifice: number,
    quoteType: string
  ) {
    if (numYears > 0) {
      const pgAboveCPI = PG_ABOVE_CPI(quoteType)
      const AP_without_SS =
        (salary / weeksPerYear) * Math.pow(1 + pgAboveCPI + CPI, numYears - 1)
      const AP_with_SS =
        (salary / weeksPerYear + salarySacrifice / weeksPerYear) *
        Math.pow(1 + pgAboveCPI + CPI, numYears - 1)

      return [AP_without_SS, AP_with_SS]
    } else {
      return [0, 0]
    }
  }

  function savingsFirstYear(
    quotetype: string,
    salarySacrifice: number,
    annualSalary: number
  ) {
    let savings_percent
    if (quotetype === NHS) {
      savings_percent = getNhsContributionTiers(annualSalary)
    } else if (quotetype === TEACHERS_PENSION) {
      savings_percent = getTeachersPensionContributionTiers(annualSalary)
    } else {
      savings_percent = getLGPSPensionContributionTiers(annualSalary)
    }
    const ni_savings = salarySacrifice * NI_SAVING
    const pension_savings = salarySacrifice * savings_percent
    return ni_savings + pension_savings
  }

  function calculate() {
    if (totalYears && annualSalary && salarySacrifice) {
      const pension_saved_first_year = savingsFirstYear(
        quoteType,
        salarySacrifice,
        annualSalary
      )

      const apss_val = APSS(
        totalYears,
        weeksPerYear,
        annualSalary,
        salarySacrifice || 0,
        quoteType
      )
      const apss_1 = apss_val[0] || 0
      const apss_2 = apss_val[1] || 0

      let decrease = (apss_1 - apss_2) * (1 - TAX_SAVING) * -1
      let decrease_now_1 = decrease / Math.pow(1 + CPI, totalYears - 1)
      decrease = decrease / MONTHS_PER_YEAR
      decrease_now_1 = decrease_now_1 / MONTHS_PER_YEAR
      decreasedRetirementPension = decrease
      decreasedTodayPension = decrease_now_1
      totalAnnualSalarySacrificed =
        pension_saved_first_year * Math.pow(1 + CPI, totalYears)
    }
  }
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <MainBox
        sx={{
          width: {
            xs: "80%",
            md: "46.8rem",
          },
          maxHeight: "49.7rem",
        }}
      >
        <Button
          onClick={() => setOpen(false)}
          sx={{ alignSelf: "flex-end" }}
          title="Close"
        >
          <Close />
        </Button>
        <div className="affordability-calculator">
          <Title className="affordability-calculator__title">
            {getQuoteText(quoteType)}
          </Title>

          <div className="affordability-calculator__pension">
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                textAlign: {
                  xs: "center",
                  md: "left",
                },
              }}
            >
              <LabelGrid>
                <label htmlFor="Number-of-years">
                  <SubTitle>Numbers of years until you retire:</SubTitle>
                </label>
              </LabelGrid>
              <Grid item xs={12} md={3}>
                <div className="d-flex content-center">
                  <TextField
                    id="Number-of-years"
                    type="number"
                    value={totalYears == 0 ? " " : totalYears}
                    InputProps={styledInputProps}
                    onChange={TotalYearsHandler}
                    sx={{ marginLeft: { xs: 0, md: "1rem" } }}
                  />

                  {totalYears <= 99 ? null : (
                    <div style={{ width: 0 }}>
                      <div style={{ marginLeft: "0.625rem", fontSize: "50px" }}>
                        <InfoTooltip
                          fontSize="13px"
                          content={TOOLTIP_CONTENT_TOTALYEARS}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </Grid>
              <LabelGrid>
                <label htmlFor="annual-salary">
                  <SubTitle
                    style={{
                      paddingTop: "2rem",
                    }}
                  >
                    Current annual salary (Gross):
                  </SubTitle>
                </label>
              </LabelGrid>
              <Grid item xs={12} md={3}>
                <TextField
                  id="annual-salary"
                  type="number"
                  value={annualSalary == 0 ? " " : annualSalary}
                  InputProps={currencyInputProps}
                  onChange={annualSalaryHandler}
                  sx={{ marginLeft: { xs: 0, md: "1rem" } }}
                />
              </Grid>
              <LabelGrid>
                <label htmlFor="salary-salarySacrifice">
                  <SubTitle
                    style={{
                      paddingTop: "2rem",
                    }}
                  >
                    Amount of salary sacrifice:
                  </SubTitle>
                </label>
              </LabelGrid>
              <Grid item xs={12} md={3}>
                <div className="d-flex content-center">
                  <TextField
                    id="salary-salarySacrifice"
                    type="number"
                    value={salarySacrifice == 0 ? " " : salarySacrifice}
                    InputProps={currencyInputProps}
                    onChange={salarySacrificeHandler}
                    sx={{ marginLeft: { xs: 0, md: "1rem" } }}
                  />

                  <div style={{ width: 0 }}>
                    <div style={{ marginLeft: "0.625rem" }}>
                      <InfoTooltip
                        fontSize="0.813rem"
                        content={TOOLTIP_CONTENT}
                      />
                    </div>
                  </div>
                </div>
              </Grid>
              <LabelGrid>
                <label>
                  <SubTitle
                    style={{
                      paddingTop: "2rem",
                    }}
                  >
                    Total annual amount saved through salary sacrifice today
                  </SubTitle>
                </label>
              </LabelGrid>
              <Grid item xs={12} md={4}>
                <TextContainer>
                  <AmountText className="color-secondary">
                    £{Number(totalAnnualSalarySacrificed).toFixed(2)}
                  </AmountText>
                </TextContainer>
              </Grid>
              <LabelGrid>
                <label>
                  <SubTitle
                    style={{
                      paddingTop: "2rem",
                    }}
                  >
                    Decrease in monthly pension at retirement
                  </SubTitle>
                </label>
              </LabelGrid>
              <Grid item xs={12} md={4}>
                <TextContainer>
                  <AmountText className="color-secondary">
                    £{Number(decreasedRetirementPension).toFixed(2)}
                  </AmountText>
                </TextContainer>
              </Grid>
              <LabelGrid>
                <label>
                  <SubTitle
                    style={{
                      paddingTop: "2rem",
                    }}
                  >
                    Decrease in monthly pension in today’s money
                  </SubTitle>
                </label>
              </LabelGrid>
              <Grid item xs={12} md={4}>
                <TextContainer>
                  <AmountText className="color-secondary">
                    £{Number(decreasedTodayPension).toFixed(2)}
                  </AmountText>
                </TextContainer>
              </Grid>
            </Grid>
          </div>
          <MainParagraph
            sx={{
              lineHeight: "0.9rem",
              margin: { sm: "1rem" },
              paddingBottom: "0.8rem",
            }}
          >
            {PensionCalculatorFirstText}
          </MainParagraph>
          <MainParagraph
            sx={{
              lineHeight: "0.9rem",
              margin: { sm: "1rem" },
            }}
          >
            {PensionCalculatorSecondText}
          </MainParagraph>
        </div>
        <ClearButton variant="invisible" onClick={() => setOpen(false)}>
          Close window
        </ClearButton>
      </MainBox>
    </Modal>
  )
}

export const MainParagraph = styled(Typography)`
  &&&&&&&& {
    margin: 0px;
    width: 590px;
    color: #35185f;

    font-size: 12px;
    @media (max-width: 600px) {
      width: 101%;
      margin-bottom: 1rem;
      text-align: left;
      padding-bottom: 0rem;
    }
  }
`
export const ClearButton = styled(CloseWindowButton)`
  margin-bottom: 1rem;
  color: "#666666";
  @media (max-width: 600px) {
    margin-top: 1rem;
  }
`

export const TextContainer = styled(Box)`
  ${({ theme }) => css`
    margin-left: 0;
    text-align: center;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      margin-left: 2rem;
    }
  `}
`

const Title = styled("div")`
  ${({ theme }) => css`
    font-size: 1rem;

    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      font-size: 1.5rem;
    }

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      /* many of the following css attributes are not necessary */
      top: 250px;
      left: 831px;
      height: 58px;
      width: 308px;
      line-height: 1.5rem;
      padding-bottom: 0px;
    }
  `}
`

const SubTitle = styled("span")`
  ${({ theme }) => css`
    color: ${theme.palette.grey[700]};
    font-size: ${theme.typography.body2.fontSize};

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 1.063rem;
    }
  `}
`

const AmountText = styled("h2")`
  ${({ theme }) => css`
    font-size: 2rem;
    width: fit-content;
    margin: 0 auto;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      text-align: left;
      font-size: 1.5rem;

      width: unset;
      margin: unset;
    }
  `}
`
