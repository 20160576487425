import React from "react"
import { ExploreButton } from "../../lifestyle-savings/shared-styles"
import { styled, Typography, css, Box } from "@mui/material"
import { ContentGrid } from "../../portal-homepage/dynamic-propositions/health-and-wellbeing/Content"
import FamilyCareIconImage from "./assets/FamilyCareIconImage.png"
import Childcare from "./assets/Childcare.png"
import Eldercare from "./assets/Eldercare.png"
import Petcare from "./assets/Petcare.png"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
export type items = {
  label: string
  link?: string
  __typename: string
}
export type MegaProps = {
  link?: string
  items: items[]
  setActive: (state: boolean) => void
}
const FamilyCareCard = ({ items, setActive }: MegaProps) => {
  const params = useParams()
  const { t } = useTranslation("portalHomepage")
  const Label = items.map((item) => {
    return item.label
  })

  return (
    <>
      <div
        className="mega-menu-tab__health-and-wellbeing-image"
        style={{ textAlign: "center", width: "40%" }}
      >
        <img
          src={FamilyCareIconImage}
          alt="FamilyCareIconImage"
          className="mega-menu-tab__health-and-wellbeing-image"
          style={{ paddingBottom: "1rem" }}
        />
        <Cardlayout style={{ textAlign: "center" }}>
          {Label.includes("Childcare Support") && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ImageConatiner>
                <FamilyCareIcon src={Childcare} alt="ChildcareImage" />
              </ImageConatiner>
              <TextContainer>
                {t("portalHomepage:familyCare.services.childcare")}
              </TextContainer>
            </div>
          )}

          {Label.includes("Eldercare Support") && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingLeft: "0.5rem",
                paddingRight: "0.5rem",
              }}
            >
              <ImageConatiner>
                <FamilyCareIcon
                  src={Eldercare}
                  alt="EldercareImage"
                  sx={{ width: "21.79px", height: "28.46px" }}
                />
              </ImageConatiner>
              <TextContainer>
                {t("portalHomepage:familyCare.services.eldercare")}
              </TextContainer>
            </div>
          )}

          {Label.includes("Petcare Support") && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ImageConatiner>
                <FamilyCareIcon src={Petcare} alt="PetCareImage" />
              </ImageConatiner>
              <TextContainer>
                {t("portalHomepage:familyCare.services.petcare")}
              </TextContainer>
            </div>
          )}
        </Cardlayout>

        <FamilyCareButton
          style={{ display: "flex", alignItems: "center" }}
          onClick={() => {
            setActive(false)
          }}
          href={`/organisations/${params.organisationId}/employee/benefits/family_care`}
          component={"a"}
        >
          {t("familyCare.buttonText")}
        </FamilyCareButton>
      </div>
    </>
  )
}
export default FamilyCareCard
export const FamilyCareButton = styled(ExploreButton)`
  ${({ theme }) => css`
    &&& {
      max-width: 100%;
      width: 19.7881rem;
      background-color: #6a6ba8;
      height: 3.125rem;
      z-index: 10;
      @media screen and (min-width: ${theme.breakpoints.values.md}px) {
        font-size: 0.6rem;
        height: 2.125rem;
      }
      @media screen and (min-width: ${theme.breakpoints.values.lg}px) {
        font-size: 1rem;
        height: 3.125rem;
      }
      &:hover {
        opacity: 75%;
        background-color: #6a6ba8;
      }
    }
  `}
`
export const ContentContainer = styled(ContentGrid)`
  && {
    width: 100%;
  }
`
export const ContentContainerHeader = styled(Typography)`
  ${({ theme }) => css`
    text-align: center;

    font-size: 0.9375rem;
    line-height: 1.375rem;
    margin-bottom: 0.25rem;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 0.875rem;
    }

    @media screen and (min-width: ${theme.breakpoints.values.lg}px) {
      font-size: 1.3125rem;
    }
  `}
`

export const ImageConatiner = styled(Box)`
  ${({ theme }) => css`
    max-width: 3.3912rem;
    width: 100%;
    height: 3.3912rem;
    background-color: white;
    opacity: 1;
    border-radius: 3.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      height: 3.3912rem;
      max-width: 3.3912rem;
      width: 100%;
    }
  `}
`
export const FamilyCareIcon = styled("img")`
  ${({ theme }) => css`
    width: 1.9456rem;
    height: 1.5844rem;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      width: 1.9456rem;
      height: 1.5844rem;
    }
  `}
`
export const Cardlayout = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0.4rem;
    padding-bottom: 1.5rem;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      flex-direction: row;
      max-width: 20.875rem;
      width: 100%;
      padding: 0rem;
    }
  `}
`

export const TextContainer = styled(Typography)`
  ${({ theme }) => css`
    text-align: center;
    font-size: 0.75rem;
    margin-top: 0.5rem;
    font-weight: 700;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      margin-top: 4px;
    }
  `}
`
