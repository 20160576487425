import ButtonChat from "../shared/button-chat/ButtonChat"
import background from "./assets/background.png"

const Layout = ({
  billboardImage,
  children,
}: {
  billboardImage?: string
  children: JSX.Element
}) => {
  return (
    <div>
      <div style={getContainerStyle(billboardImage)} id="main-content">
        {children}
      </div>
      <ButtonChat />
    </div>
  )
}

export default Layout

function getContainerStyle(billboardImage?: string): React.CSSProperties {
  return {
    backgroundImage: `url(${billboardImage || background})`,
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
    minHeight: "100vh",
    overflow: "hidden",
    color: "white",
  }
}
