import { Box, styled, css } from "@mui/material"
import BannerCarousel from "../../shared/banner-carousel/BannerCarousel"

type TopBannerProps = {
  desktopImageUrl?: string | null
  mobileImageUrl?: string | null
  link?: string | null
  text?: string | null
  welcomeText?: string | null
  logos?: string[] | null
}

export const TopBanner = ({
  desktopImageUrl,
  mobileImageUrl,
  link,
  text,
  logos,
  welcomeText,
}: TopBannerProps) => {
  return (
    <BannerContainer>
      <BannerCarousel
        desktopImageUrl={desktopImageUrl}
        mobileImageUrl={mobileImageUrl}
        link={link}
        text={text}
        logos={logos}
        welcomeText={welcomeText}
        showWelcomeText={false}
        cover={true}
      />
    </BannerContainer>
  )
}

const BannerContainer = styled(Box)`
  ${({ theme }) => css`
    width: 100%;
    height: 9.6875rem;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      height: 13.0625rem;
    }
  `}
`
