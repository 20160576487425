import { SxProps, TextField, TextFieldProps, styled } from "@mui/material"
import { CSSProperties } from "@mui/styles"
import { useFormikContext } from "formik"
import FormikValueType from "./formikValueType"

type GenericInputProps = {
  textFieldProps: TextFieldProps
  style?: CSSProperties // inline style props passed to TextField
  sx?: SxProps
  inlineTextFieldProps?: TextFieldProps
}

const GenericField = <T extends FormikValueType>({
  textFieldProps,
  style,
  sx,
  inlineTextFieldProps,
}: GenericInputProps) => {
  const { id } = textFieldProps

  const formik = useFormikContext<T>()

  if (id) {
    // if a field is touched, its key will appear in the formik.touched object
    const touched = Object.keys(formik.touched).includes(id)
    const error = Object.keys(formik.errors).includes(id)
    const invalid = touched && error ? true : false

    return (
      // there has to be a wrapper for the flex container
      // otherwise row-gap exist between textfield and row-gap
      <div>
        <StyledTextField
          {...textFieldProps}
          style={style && style}
          sx={sx && sx}
          error={invalid}
          value={formik.values[id]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          helperText={invalid && formik.errors[id]}
          // inlineTextFieldProps is to override everything similar to inline styling manner
          {...inlineTextFieldProps}
        />
      </div>
    )
  }
  return <></>
}

export default GenericField

const StyledTextField = styled(TextField)`
  margin-bottom: 1.875rem;
`
