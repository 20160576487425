import { Snackbar, Alert as MuiAlert, Box, styled } from "@mui/material"
import React, { useState } from "react"

export type AlertProps = {
  severity: "success" | "error" | "warning" | "info"
  message: string
  setShowAlert?: (state: boolean) => void
}

export default function Alert({ message, severity, setShowAlert }: AlertProps) {
  const [open, setOpen] = useState(true)

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return
    }

    setOpen(false)
    if (setShowAlert) {
      setShowAlert(false)
    }
  }

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <MuiAlert onClose={handleClose} severity={severity} variant="filled">
        {message.split("\n").map((line, i) => {
          return (
            <StyledBox
              key={`alert-${i}`}
              dangerouslySetInnerHTML={{ __html: line }}
            />
          )
        })}
      </MuiAlert>
    </Snackbar>
  )
}

const StyledBox = styled(Box)`
  .contact-us {
    font-weight: 700;
    text-decoration: underline;
  }
`
