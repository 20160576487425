import { RoundelProps } from "../../../shared/Roundel"
import { MobileWhiteBox, TwoPropositionsBox } from "../../shared/SharedStyles"
import { Content } from "./Content"
import { OnePropositionContent } from "./OnePropositionContent"
import useIsMobileView from "../../../../utils/useIsMobileView"

export const MOBILE_ROUNDEL_PER_PAGE = 2
export const ROUNDEL_BACKGROUND = "gray"

const C2W = ({
  roundels,
  onlyC2W,
  cycleToWorkStore,
}: {
  roundels: RoundelProps[]
  onlyC2W: boolean
  cycleToWorkStore?: boolean
}) => {
  const isMobile = useIsMobileView()

  if (isMobile) {
    return (
      <MobileWhiteBox>
        <Content
          roundels={roundels}
          onlyC2W={onlyC2W}
          cycleToWorkStore={cycleToWorkStore}
        />
      </MobileWhiteBox>
    )
  }
  if (onlyC2W) {
    return (
      <OnePropositionContent
        roundels={roundels}
        onlyC2W={onlyC2W}
        cycleToWorkStore={cycleToWorkStore}
      />
    )
  }
  return (
    <TwoPropositionsBox>
      <Content
        roundels={roundels}
        onlyC2W={onlyC2W}
        cycleToWorkStore={cycleToWorkStore}
      />
    </TwoPropositionsBox>
  )
}

export default C2W
